// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'

const baseUrl = 'TransAbsensis'

const fetchAllTransAbsensis = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneTransAbsensis = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addTransAbsensis = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editTransAbsensis = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteTransAbsensis = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllTransAbsensis,
  fetchOneTransAbsensis,
  addTransAbsensis,
  editTransAbsensis,
  deleteTransAbsensis
}
