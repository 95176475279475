import discount from './discount'
import customer from './customer'
import company from './company'
import accountVerification from './account-verification'
import depositWithdrawal from './deposit-withdrawal'

const prefix = 'pelanggan'

export default () => {
  const getCustomer = customer(prefix)
  const getAccountVerification = accountVerification(prefix)
  const getCompany = company(prefix)
  const getDiscount = discount(prefix)
  const getDepositWithdrawal = depositWithdrawal(prefix)
  return [
    ...getDiscount,
    ...getCustomer,
    ...getCompany,
    ...getAccountVerification,
    ...getDepositWithdrawal
  ]
}
