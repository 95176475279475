import { environment } from '@/utils'
import axios from 'axios'
// import { cacheAdapterEnhancer } from 'axios-extensions'
import { setupCache } from 'axios-cache-adapter'
import https from 'https'
import setup from './setupInterceptors'

// const cacheConfig = {
//   enabledByDefault: false,
//   cacheFlag: 'useCache'
// }

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// })
const httpsAgent = new https.Agent({ rejectUnauthorized: false })
const API_URL = environment.getHydraApi()
const axl = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: '*/*',
    // 'Access-Control-Allow-Origin': '*'
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  httpsAgent
})
axl.interceptors.request.use((config: any) => config, (error: any) => Promise.reject(error))

setup(axl)

export default axl
