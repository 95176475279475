import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/dashboard'

const fetchSummary = () => new Remote(remoteEnum.get, `${baseUrl}/summary`)
const fetchLabelStock = () => new Remote(remoteEnum.get, `${baseUrl}/labelstock`)
const fetchStickerStock = () => new Remote(remoteEnum.get, `${baseUrl}/stickerstock`)
const fetchAgentInfo = () => new Remote(remoteEnum.get, `${baseUrl}/agentinfo`)

export {
  fetchSummary,
  fetchLabelStock,
  fetchStickerStock,
  fetchAgentInfo
}
