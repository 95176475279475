import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'LogStockLabel'

const fetchAllLogStockLabel = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneLogStockLabel = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addLogStockLabel = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editLogStockLabel = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteLogStockLabel = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllLogStockLabel,
  fetchOneLogStockLabel,
  addLogStockLabel,
  editLogStockLabel,
  deleteLogStockLabel
}
