import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  if (permission.read) {
    listRoute.push({
      path: `${val}/comission`,
      name: 'courier-comission',
      component: () => import('@/views/master/courier/courier-comission/CourierComission.vue'),
      meta: {
        group: 'Kurir',
        permission
      }
    })
  }
  if (permission.update) {
    listRoute.push(
      {
        path: `${val}/comission-detail/:id`,
        name: 'courier-comission-detail',
        component: () => import('@/views/master/courier/courier-comission/detail/CourierComissionDetail.vue'),
        meta: {
          breadcrumb: [
            // { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
            { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
            { title: 'Komisi Kurir', icon: icon.building, routeName: 'courier-comission' },
            { title: 'Detail Komisi Kurir', icon: null, routeName: 'courier-comission-detail' },
          ],
          group: 'Kurir',
          permission
        }
      }
    )
  }
  return listRoute
}
