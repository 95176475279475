import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const permission = {
    read: checkPermission(Permision.ViewDashboardCourier),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    // read: true,
    write: true,
    update: true,
    delete: true
  }

  const listDashboard = []
  if (permission.read) {
    listDashboard.push({
      path: `${val}`,
      name: 'courier-dashboard',
      component: () => import('@/views/dashboard-courier/DashboardCourier.vue'),
      meta: {
        group: 'DASHBOARD',
        permission
      }
    })
  }
  return listDashboard
}
