import {
  ref, onMounted, getCurrentInstance, reactive, computed
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { deliveryManifestUseCase } from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  props: {
    isShowDialog: {
      default: false
    },
    jadwalHdId: {
      default: null
    },
    deliveryHdId: {
      default: null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast } = app!.appContext.config.globalProperties
    const store = useStore()
    const router = useRouter()
    const submitted = ref(false)
    const propertiesProps = reactive(props)
    const deliveryHdId = computed(() => propertiesProps.deliveryHdId) as any
    const jadwalHdId = computed(() => propertiesProps.jadwalHdId) as any
    const dialogLoadingManifest = computed(() => propertiesProps.isShowDialog) as any
    const dataAdd = reactive({
      noPol: '',
      driverName: '',
      stewardName: '',
      kruName: null,
      description: ''
    })
    const detailAdd = ref({}) as any
    const rules = {
      noPol: {
        required: helpers.withMessage('Nomor polisi Bus harus diisi', required)
      },
      stewardName: {
        required: helpers.withMessage('Nama pramugara harus diisi', required)
      },
      driverName: {
        required: helpers.withMessage('Nama supir harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataAdd)

    const hideDialog = () => {
      emit('hideDialog')
    }

    const getDetailLoadingManifest = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        PengirimanHdId: deliveryHdId.value,
        AgenId: store.getters['appActiveUser/getAgent']?.id
      }
      const response = await deliveryManifestUseCase.submitLoadingManifest(dataForm)
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        detailAdd.value = response.result
      }
    }

    const proccessLoadingManifest = async () => {
      store.dispatch('showLoading')
      const dataSubmit = {
        TransJadwalHdId: jadwalHdId.value,
        Nopol: dataAdd.noPol,
        NamaPramugara: dataAdd.stewardName,
        NamaSupir: dataAdd.driverName,
        Keterangan: dataAdd.description,
        NamaKru: dataAdd.kruName,
        PengirimanHdId: deliveryHdId.value
      }
      const response = await deliveryManifestUseCase.submitData(null, dataSubmit)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 1500
        })
        emit('hideDialog')
        emit('reloadData')
        const routeData = router.resolve({ name: 'report', params: { Id: response.result.Id, jenisReport: 'manifest' } })
        window.open(routeData.href, '_blank')
      }
      store.dispatch('hideLoading')
    }

    const submitLoadingManifest = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      $confirm.require({
        header: 'Berangkatkan Loading Manifest',
        message: 'Pastikan resi yang disimpan sudah sesuai dengan barang yang sudah dimuat oleh kru dan supir bis. Resi yang sudah diinput tidak dapat diubah lagi.',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Simpan & Print',
        rejectLabel: 'Kembali',
        accept: () => {
          proccessLoadingManifest()
        }
      })
    }

    onMounted(() => {
      getDetailLoadingManifest()
    })

    return {
      v$,
      detailAdd,
      dialogLoadingManifest,
      submitLoadingManifest,
      hideDialog
    }
  }
}
