import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import type {
  TPayloadCancelDeliveryDraft
} from './msAgens-model'

const baseUrl = '/backend/v2/msagens'

interface IPayloadAddDeliveryDraft {
  Reference: string
  Source: string
}
const fetchAgentAll = (filters = '') => new Remote(remoteEnum.get, `${baseUrl}${filters}`)
const fetchAgentSingle = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchAgentDetailByUsername = (username: string) => new Remote(remoteEnum.get, `${baseUrl}/byuserid/${username}`)
const fetchLabelStocks = (id: any, filter: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}/labelstocks${filter}`)
const fetchDeliveryDrafts = (id: number, filter: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/deliverydrafts${filter}`)
const addDeliveryDraft = (id: number, payload: IPayloadAddDeliveryDraft) => new Remote(remoteEnum.post, `${baseUrl}/${id}/deliverydrafts`, payload)
const cancelDeliveryDraft = (agenId: string, deliveryId: string, payload: TPayloadCancelDeliveryDraft) => new Remote(remoteEnum.put, `${baseUrl}/${agenId}/deliverydrafts/${deliveryId}/cancel`, payload)

export {
  fetchLabelStocks,
  fetchDeliveryDrafts,
  addDeliveryDraft,
  fetchAgentSingle,
  fetchAgentAll,
  IPayloadAddDeliveryDraft,
  fetchAgentDetailByUsername,
  cancelDeliveryDraft
}
