/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchPdfResi, fetchPdfManifest, fetchPdfResiByte
} from '@/data/source/remote/api'

class ReportUseCase implements ICrud {
  changeIsActive(): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  async getDataForm(): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getPdfResi(id: any, userId: any): Promise<Response> {
    return new Repository(fetchPdfResi(id, userId), null).getResult(false)
  }

  getPdfManifest(id: any): Promise<Response> {
    return new Repository(fetchPdfManifest(id), null).getResult(false)
  }

  getPdfResiByte(id: any): Promise<Response> {
    return new Repository(fetchPdfResiByte(id), null).getResult(false)
  }
}

const reportUseCase = new ReportUseCase()

export {
  reportUseCase
}
