import {
  Capacitor, Plugins, PermissionType, CameraResultType
} from '@capacitor/core'
import { decode } from 'base64-arraybuffer'

const { Permissions, Camera } = Plugins

const checkCamera = () => Capacitor.isPluginAvailable('Camera')
const checkPermission = async () => {
  const permission = await Permissions.query({
    name: PermissionType.Camera
  })
  console.log('PermissionType', permission)
}
const takePhoto = async () => {
  const image = await Camera.getPhoto({
    quality: 60,
    allowEditing: false,
    resultType: CameraResultType.Base64
  }) as any
  // const imageUrl = image.dataUrl
  return {
    image: new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,
    }),
    name: Math.random().toString(36).substring(2, 10),
    ext: image.format,
    from: 'camera'
  }
}
const requestPermission = async () => {
  // const permission = await Camera.requestPermissions()
  // console.log(permission)
}

export {
  checkCamera,
  checkPermission,
  requestPermission,
  takePhoto
}
