import { Remote, remoteEnum } from '@/data/source/remote/remoteVoucher'

const baseUrl = 'MsVoucherHd'

const addVoucherHd = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editVoucherHd = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteVoucherHd = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchVoucherHd = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneVoucherHd = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const changeIsActiveVoucherHd = (id: number, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)
const uploadFileVoucherHd = (data: any) => new Remote(remoteEnum.post, 'UploadImg', data)

export {
  fetchVoucherHd,
  changeIsActiveVoucherHd,
  uploadFileVoucherHd,
  addVoucherHd,
  fetchOneVoucherHd,
  editVoucherHd,
  deleteVoucherHd
}
