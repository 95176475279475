import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  if (permission.read) {
    listRoute.push(
      {
        path: `${val}/departure-process`,
        name: 'departure-package',
        component: () => import('@/views/package-order/departure-process/index.vue'),
        children: [
          {
            path: 'scheduling/step-1/:id',
            name: 'scheduling-departure-package-step-1',
            component: () => import('@/views/package-order/departure-process/ModalSchedulingResi.vue'),
            meta: {
              group: 'ORDERAN PAKET',
              permission
            }
          },
          {
            path: 'scheduling/step-2/:id',
            name: 'scheduling-departure-package-step-2',
            component: () => import('@/views/package-order/departure-process/ModalScheduleDetail.vue'),
            meta: {
              group: 'ORDERAN PAKET',
              permission
            }
          }
        ],
        meta: {
          group: 'ORDERAN PAKET',
          permission
        },
      }
    )
  }
  return listRoute
}
