// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'

const baseUrl = 'TransHistoryKomisis'

const fetchAllHistoryKomisi = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addAdjustmentKomisi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Adjustment`, data)

export {
  fetchAllHistoryKomisi,
  addAdjustmentKomisi
}
