import { App } from 'vue'
import * as mqtt from 'mqtt'
import mitt from 'mitt'
import { environment } from '@/utils'
// import store from '@/store'

export default {
  install: (app: App<Element>) => {
    let client = null as any

    const socketHub = mitt()
    app.config.globalProperties.$socketHub = socketHub
    const option = {
      username: environment.getUsernameMqtt(),
      password: environment.getPasswordMqtt(),
      // clientId: store.state.appActiveUser.guid,
      // connectTimeout: 5000,
      reconnectPeriod: 10000,
      // protocol: environment.getProtocolBaseMqtt(),
      // hostname: environment.getUrlBaseMqtt(),
      // port: environment.getPortBaseMqtt(),
      // path: '/mqtt',
      ssl: false,
      clean: true,
      keepalive: 60
    } as any
    console.log('optionmqt', option)
    const url = `${environment.getProtocolBaseMqtt()}://${environment.getUrlBaseMqtt()}:${environment.getPortBaseMqtt()}/ws`
    // console.log(mqtt.connect(option))
    // client = mqtt.connect(option)
    // console.log('udah connect', client)
    try {
      // client = mqtt.connect(option)
      client = mqtt.connect(url, option)
      // client = mqtt.connect(option)
      console.log('udah connect', client)
      if (client.connected) {
        console.log('tidak bisa connect')
      } else if (!client.connected) {
        console.log('koneksi berhasil')
      }
    } catch (error: any) {
      console.log('mqtt.connect error', error)
    }

    client.on('connect', (connack: any) => {
      console.log('Connection MQTT succeeded!', connack)
      client.subscribe('lokasikurir/#')

      client.on('message', (topic: any, message: any) => {
        if (topic.includes('lokasikurir') === true) {
          socketHub.emit('lokasikurir', JSON.parse(message.toString()))
        }
        // client.publish('RekanKerja/9pzez/12', 'Bariq kirim publish after aziz send')
        // client.end()
      })
    })

    client.on('error', (params: any) => {
      console.log('ini error', params)
    })

    // client.subscribe('lokasikurir/#')
    // client.on('message', (topic: any, message: any) => {
    //   // socketHub.emit('RekanKerja', message.toString())
    //   switch (topic) {
    //     case 'lokasikurir':
    //       console.log(`masuk diluar => ${message} topic ${topic}`)
    //       // code block
    //       break
    //     default:
    //     // code block
    //   }
    //   // console.log(`Bariq => ${message} from topic ${topic}`)
    //   // client.publish('RekanKerja/9pzez/12', 'Bariq kirim publish after aziz send')
    // })

    // // Tambahkan Topic Yang Perlu Di Subcribe
    // client.subscribe('karepmu')
    // client.subscribe('topiic')
  }
}
