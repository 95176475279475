// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsJadwalKurir'

const fetchAllAbsensiCourier = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneAbsensiCourier = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addAbsensiCourier = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editAbsensiCourier = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteAbsensiCourier = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveAbsensiCourier = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
const createAbsensiKey = (data: any) => new Remote(remoteEnum.post, 'TransKurirs/CreateAbsensiKey', data)

export {
  fetchAllAbsensiCourier,
  fetchOneAbsensiCourier,
  addAbsensiCourier,
  editAbsensiCourier,
  deleteAbsensiCourier,
  changeIsActiveAbsensiCourier,
  createAbsensiKey
}
