import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  listRoute.push(
    {
      path: `${val}/refund`,
      name: 'transaksi-pengiriman-refund',
      component: () => import('@/views/transactions/delivery-item/refund/Refund.vue'),
      meta: {
        // breadcrumb: [
        //   // { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
        //   { title: 'Master Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
        //   { title: 'Pengaturan Jadwal', icon: null, routeName: 'courier-schedule' },
        // ],
        group: 'PENGIRIMAN BARANG',
        permission
      }
    }
  )

  return listRoute
}
