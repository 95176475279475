import city from './city'
import agent from './agent'
import typeAgent from './typeAgent'
import labelAgent from './labelAgent'
import stickerAgent from './stickerAgent'

const prefix = 'perjalanan'

export default () => {
  const getCity = city(prefix)
  const getAgent = agent(prefix)
  const getTypeAgent = typeAgent(prefix)
  const getLabelAgent = labelAgent(prefix)
  const getStickerAgent = stickerAgent(prefix)

  return [
    ...getCity,
    ...getAgent,
    ...getTypeAgent,
    ...getLabelAgent,
    ...getStickerAgent
  ]
}
