import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKomisiKurirHd'

const fetchAllKomisiKurirHd = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKomisiKurirHd = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/GetWithDetail/${id}${filter}`)
const addKomisiKurirHd = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKomisiKurirHd = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKomisiKurirHd = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const addKomisiKurirHdArray = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Detail`, data)
const editKomisiKurirHdArray = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/Detail/${id}`, data)

export {
  fetchAllKomisiKurirHd,
  fetchOneKomisiKurirHd,
  addKomisiKurirHd,
  editKomisiKurirHd,
  deleteKomisiKurirHd,
  addKomisiKurirHdArray,
  editKomisiKurirHdArray
}
