import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  const routes = [
    {
      path: `${val}`,
      name: 'manifest-revenue',
      component: () => import('@/views/cashier/ManifestRevenue.vue'),
      meta: {
        group: 'Kasir/Pendapatan',
        permission
      }
    },
    {
      path: `${val}/:id`,
      name: 'finished-processing-detail',
      component: () => import('@/views/cashier/detail/FinishedProcessingDetail.vue'),
      meta: {
        group: 'Kasir/Pendapatan',
        breadcrumb: [
          { title: 'Kasir/Pendapatan', icon: icon.fileText, routeName: 'manifest-revenue' },
          { title: 'Kasir/Pendapatan Detail', icon: null, routeName: 'finished-processing-detail' },
        ],
        permission
      }
    }
  ]

  return routes
}
