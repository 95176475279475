import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'VwPengecekanResi'

const fetchAllChekingResi = (filter : any = '') => new Remote(remoteEnum.get, `${baseUrl}?${filter}`)
const fetchOneChekingResi = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addChekingResi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editChekingResi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteChekingResi = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllChekingResi,
  fetchOneChekingResi,
  addChekingResi,
  editChekingResi,
  deleteChekingResi
}
