/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import {
  fetchAllUser,
  fetchOneUser,
  deleteUser,
  changeIsActiveUser,
  changePassword,
  forgotPassword,
  resetPassword,
  fetchOneUserV2,
  editOneUserV2,
  addOneUserV2
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import { TPayloadSaveUser, TResultMsUsers } from '@/data/source/remote/api/identity/V2/msUsers-model'
import RepositoryV2 from '@/data/repositoryV2'

const getAllUserFlow = (filter: any = null): Promise<Response> => {
  let params = '?$count=true'
  if (filter) {
    if (filter.expand) {
      params = `${params}${filter.expand}`
    }
    if (filter.select) {
      params = `${params}${filter.select}`
    }

    const arrFilter = []
    if (filter.filter) {
      arrFilter.push(filter.filter)
    }
    if (filter.search) {
      arrFilter.push(`(Contains(tolower(NamaLengkap),'${filter.search.toLowerCase()}') or Contains(tolower(Email),'${filter.search.toLowerCase()}') or Contains(tolower(Username),'${filter.search.toLowerCase()}'))`)
    }
    if (arrFilter.length > 0) {
      params = `${params}&$filter=${arrFilter.join(' and ')}`
    }
    params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
  }
  return new Repository(fetchAllUser(params), null).getResult(false)
}

const deleteDataUserFlow = (id: any = null) => new Repository(deleteUser(id), null).getResult(false)

const dataEditUser = (id: any) => new Repository(fetchOneUser(id), null).getResult(false)

const editIsActiveUser = (data: any) => new Repository(changeIsActiveUser(data), null).getResult(false)

const gantiPassword = (data: any) => new Repository(changePassword(data), null).getResult(false)

const lupaPassword = (data: any) => new Repository(forgotPassword(data), null).getResult(false)

const aturUlangPassword = (data: any) => new Repository(resetPassword(data), null).getResult(false)

class UserUseCase {
  getDetailUser(id: number, filter: any): Promise<Response<TResultMsUsers>> {
    console.log('filter', filter)
    return new RepositoryV2<TResultMsUsers>(fetchOneUserV2(id, filter), null).getResult(false)
  }

  saveUser(id: any, payload: TPayloadSaveUser): Promise<any> {
    if (id) {
      const data = {
        ...payload,
        Id: Number(id)
      }
      delete data?.Password
      return new RepositoryV2(editOneUserV2(id, data), null).getResult(false)
    } return new RepositoryV2(addOneUserV2(payload), null).getResult(false)
  }
}

const userUseCase = new UserUseCase()

export {
  getAllUserFlow,
  deleteDataUserFlow,
  dataEditUser,
  editIsActiveUser,
  gantiPassword,
  lupaPassword,
  aturUlangPassword,
  userUseCase
}
