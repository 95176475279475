import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val: any = '') => {
  const verify = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = []
  const listChildren = []
  if (verify.read) {
    if (verify.update) {
      listChildren.push({
        path: 'detail/:id',
        name: 'master-account-verification-detail',
        component: () => import('@/views/master/customers/company/CompanyDetail.vue'),
        meta: {
          group: 'PELANGGAN',
          permission: verify
        }
      })
    }
    listRoute.push({
      path: `${val}/account-verification`,
      name: 'master-account-verification',
      component: () => import('@/views/master/customers/account-verification/AccountVerification.vue'),
      children: listChildren,
      meta: {
        group: 'PELANGGAN',
        permission: verify
      },
    })
  }
  return listRoute
}
