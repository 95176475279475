import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsDynamicReportPram'

const fetchAllDynamicReportPrams = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneDynamiReportPrams = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)

export {
  fetchAllDynamicReportPrams,
  fetchOneDynamiReportPrams
}