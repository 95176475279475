import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = 'penalty/v1/summaries'

const fetchAllPenaltiesSummary = (agentId: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/totalPenaltiesByAgent/${agentId}${filter}`)
const fetchAllUnpaidPenaltiesSummary = (agentId: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/totalUnpaidPenaltiesByAgent/${agentId}${filter}`)

export {
  fetchAllPenaltiesSummary,
  fetchAllUnpaidPenaltiesSummary
}
