import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "flex items-center items-stretch w-100 input-icon-right-grey w-full",
  id: "search-bar"
}
const _hoisted_2 = {
  key: 1,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.$enumBase.input.text == _ctx.type)
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 0,
          type: "text",
          class: "none-right-radius left-radius w-full",
          id: "input-search",
          style: _ctx.inputBackground?{backgroundColor: _ctx.inputBackground }:{},
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.input = $event)),
          placeholder: _ctx.placeholder,
          autofocus: _ctx.autofocus
        }, null, 8, ["style", "modelValue", "placeholder", "autofocus"]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: (_ctx.canClick) ? 'background-grey-lighten-4 px-2 items-center flex right-radius cursor-pointer' : 'background-grey-lighten-4 px-2 items-center flex right-radius',
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick()))
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            innerHTML: _ctx.icon
          }, null, 8, ["innerHTML"]))
        : (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("p", null, _toDisplayString(_ctx.label), 1)
          ]))
    ], 2)
  ]))
}