/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchOnePengirimanHd, fetchAllPengirimanHd, addPenerimaan, dropAgent, fetchStockLabelSticker, addLogRegUid,
  fetchSingleTransPengirimanHd,
  fetchTransPengirimanHdPackageLogs,
  fetchTransPengirmanHdLabelUsed,
  fetchTransPengirmanHdRecipient
} from '@/data/source/remote/api'
import moment from 'moment'
import RepositoryV2 from '@/data/repositoryV2'
import {
  TDeliveryStatusLogDelivery,
  TLabelUsedDelivery,
  TRecipientDelivery,
  TTransPengirimanHdDetail
} from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'

class ReceiveItemUseCase implements ICrud {
  getAll(filter: any = null): Promise<Response> {
    let params = '?$count=true&$orderBy=StatusPengirimanId asc'
    if (filter) {
      const query = []
      if (filter.select) {
        query.push(`$select=${filter.select}`)
      }

      if (filter.expand) {
        query.push(`$expand=${filter.expand}`)
      }

      if (query.length > 0) {
        params = `${params}&${query.join('&')}`
      }

      const filters = []
      if (filter.filter) {
        filters.push(`${filter.filter}`)
      }

      if (filter.customFilter) {
        filters.push(`${filter.customFilter}`)
      }

      if (filter.search) {
        // Contains(tolower(Sticker/Nama),'${filter.search.toLowerCase()}')
        filters.push(`(Contains(tolower(Resi),'${filter.search.toLowerCase()}') or Contains(tolower(NamaPenerima), '${filter.search.toLowerCase()}') or Contains(tolower(NamaPengirim), '${filter.search.toLowerCase()}'))`)
      }

      if (filter.fromAgentId) {
        if (filter.fromAgentId.value !== null) {
          filters.push(`AgenAsalId eq ${filter.fromAgentId.value}`)
        }
      }

      if (filter.dateFilter) {
        filters.push(`TransJadwalHd/TanggalAktif eq ${moment(filter.dateFilter).format('YYYY-MM-DD')}`)
      }

      if (filters.length > 0) {
        params = `${params}&$filter=${filters.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    }
    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }

  getOne(id: any, filter: any = ''): Promise<Response> {
    let params = ''
    if (filter) {
      const query = []
      if (filter.expand) {
        query.push(`$expand=${filter.expand}`)
      }
      if (filter.select) {
        query.push(`$select=${filter.select}`)
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (id.length === 36) {
        arrFilter.push(`guid eq ${id}`)
      }

      if (arrFilter.length > 0) {
        query.push(`$filter=${arrFilter.join(' and ')}`)
      }

      if (query.length > 0) {
        params = `${params}?${query.join('&')}`
      }
    }
    if (id.length === 36) {
      return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
    }
    return new Repository(fetchOnePengirimanHd(id, params), null).getResult(false)
  }

  getDetail(id: string): Promise<Response<TTransPengirimanHdDetail>> {
    return new RepositoryV2(fetchSingleTransPengirimanHd(id), null).getResult(false)
  }

  getDeliveryStatusLogs(id: string): Promise<Response<Array<TDeliveryStatusLogDelivery>>> {
    return new RepositoryV2<Array<TDeliveryStatusLogDelivery>>(fetchTransPengirimanHdPackageLogs(id), null).getResult(false)
  }

  getLabelUsed(id: string): Promise<Response<Array<TLabelUsedDelivery>>> {
    return new RepositoryV2<Array<TLabelUsedDelivery>>(fetchTransPengirmanHdLabelUsed(id), null).getResult(false)
  }

  getRecipientDelivery(id: string): Promise<Response<TRecipientDelivery>> {
    return new RepositoryV2<TRecipientDelivery>(fetchTransPengirmanHdRecipient(id), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    data.PengirimanHdId = id
    return new Repository(addPenerimaan(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  submitDropAgent(data: any): Promise<Response> {
    return new Repository(dropAgent(data), null).getResult(false)
  }

  getStockLabelSticker(id: any): Promise<Response> {
    return new Repository(fetchStockLabelSticker(id), null).getResult(false)
  }

  submitLogReqUid(id: any): Promise<Response> {
    return new Repository(addLogRegUid(id), null).getResult(false)
  }
}

const receiveItemUseCase = new ReceiveItemUseCase()
export {
  receiveItemUseCase
}
