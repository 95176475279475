/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllTanggalPenerimaan, addTanggalPenerimaan, editTanggalPenerimaan, deleteTanggalPenerimaan, changeIsActiveTanggalPenerimaan
} from '@/data/source/remote/api/master'

class DateReceiptUsecase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    const params = (filter.search) ? `?$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : ''
    const response = new Repository(fetchAllTanggalPenerimaan(params), null).getResult(false)
    return response
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      return new Repository(editTanggalPenerimaan(id, data), null).getResult(false)
    }
    return new Repository(addTanggalPenerimaan(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteTanggalPenerimaan(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveTanggalPenerimaan(data), null).getResult(false)
  }
}

const dateReceipteUseCase = new DateReceiptUsecase()

export {
  dateReceipteUseCase
}
