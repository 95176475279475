import iconDependency from '@/dependencies/iconDependency'
import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(Permision.ListUsers),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  if (permission.read) {
    listRoute.push(
      {
        path: `${val}`,
        name: 'master-courier',
        component: () => import('@/views/master/courier/master/CourierIndex.vue'),
        meta: {
          group: 'Kurir',
          permission
        },
      }
    )
  }
  if (permission.write) {
    listRoute.push(
      {
        path: `${val}/tambah`,
        name: 'master-courier-tambah',
        component: () => import('@/views/master/courier/master/CourierForm.vue'),
        meta: {
          breadcrumb: [
            // { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
            { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
            { title: 'Data Kurir', icon: icon.building, routeName: 'master-courier' },
            { title: 'Tambah Kurir', icon: null, routeName: 'master-courier-tambah' },
          ],
          group: 'Kurir',
          permission
        }
      }
    )
  }
  if (permission.update) {
    listRoute.push({
      path: `${val}/edit/:courierId`,
      name: 'master-courier-edit',
      component: () => import('@/views/master/courier/master/CourierForm.vue'),
      meta: {
        breadcrumb: [
          // { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
          { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
          { title: 'Data Kurir', icon: icon.building, routeName: 'master-courier' },
          { title: 'Edit Kurir', icon: null, routeName: 'master-courier-edit' },
        ],
        group: 'Kurir',
        permission
      }
    })
  }
  if (permission.read) {
    listRoute.push({
      path: `${val}/detail/:courierId`,
      name: 'master-courier-detail',
      component: () => import('@/views/master/courier/detail/CourierDetail.vue'),
      meta: {
        breadcrumb: [
          { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
          { title: 'Data Kurir', icon: icon.building, routeName: 'master-courier' },
          { title: 'Profil', icon: null, routeName: 'master-courier-detail' },
        ],
        group: 'Kurir',
        permission
      }
    })
  }
  return listRoute
}
