import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => [
  {
    path: `${val}/bagasi`,
    name: 'master-tipe-bus',
    component: () => import('@/views/master/transactions/type-bus/TypeBus.vue'),
    meta: {
      group: 'TRANSAKSI',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  }
]
