import courierComissionManualPayment from './comissionManualPayment'
import courierComissionPeriodPayment from './comissionPeriodPayment'

export default (prefix: string) => {
  const getCourierComissionManualPayment = courierComissionManualPayment(prefix)
  const getCourierComissionPeriodPayment = courierComissionPeriodPayment(prefix)

  return [
    ...getCourierComissionManualPayment,
    ...getCourierComissionPeriodPayment
  ]
}
