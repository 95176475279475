import {
  fetchAllTipeBus, addTipeBus, editTipeBus, fetchOneTipeBus, deleteTipeBus, changeIsActiveTipeBus
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

const getAllTypeBusFlow = (filter: any = {}) => {
  let params = (filter.search) ? `?$count=true&$expand=KomisiHd&$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : '?$count=true&$expand=KomisiHd'
  params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
  params = (filter.order) ? `${params}&$orderby=${filter.order}` : params
  return new Repository(fetchAllTipeBus(params), null).getResult(false)
}

const submitTypeBusFlow = async (data: any) => {
  let response
  if (data.Id) {
    response = await new Repository(editTipeBus(data.Id, data), null).getResult(false)
  } else {
    data = {
      Nama: data.Nama,
      Kode: data.Kode,
      KomisiHdId: data.KomisiHdId
    }
    response = await new Repository(addTipeBus(data), null).getResult(false)
  }

  return response
}

const dataEditTypeBus = (id: any) => new Repository(fetchOneTipeBus(id), null).getResult(false)

const deleteTypeBus = (id: any) => new Repository(deleteTipeBus(id), null).getResult(false)

const dataEditIsActive = (data: any) => new Repository(changeIsActiveTipeBus(data), null).getResult(false)

export {
  getAllTypeBusFlow,
  submitTypeBusFlow,
  dataEditTypeBus,
  deleteTypeBus,
  dataEditIsActive
}
