import { RootState } from './types'

const authen = {
  isAuthen: false,
  dateAuthen: ''
}

const openTicket = {
  host: '',
  email: process.env.VUE_APP_API_OPENTICKET_EMAIL ?? '',
  password: process.env.VUE_APP_API_OPENTICKET_PASSWORD ?? '',
  isAuthenticated: false,
  token: null,
  expiredAt: '',
  isExpired: false,
}

const loader = {
  show: false
}

const networking = {
  connection: false,
  connectionType: '',
  noInternetModal: false
}

const deviceType = {
  platform: 'web',
  isIos: false,
  isAndroid: false,
  isWeb: true,
  isMobile: false,
  width: 0,
  height: 0
}

const condMaxMinInput = {
  maxKet: 250
}

const dataToken = {
  accessToken: '',
  expiresIn: null,
  idToken: '',
  refreshToken: '',
  scope: '',
  tokenType: '',
  dateToken: ''
}

const state: RootState = {
  token: dataToken,
  authenticated: authen,
  network: networking,
  device: deviceType,
  openTicket,
  loading: loader,
  toggleMenu: true,
  switchMenu: 0,
  showAdvertise: false,
  condMaxMinInput,
}

export default state
