import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}`,
      name: 'report-setting',
      // component: () => import('@/views/home/reporting/ReportingIndex.vue'),
      component: () => import('@/views/home/report-setting/ReportSetting.vue'),
      meta: {
        group: 'REPORTING & DATA',
        permission
      }
    },
    {
      path: `${val}/:id`,
      name: 'report-setting-data-detail',
      component: () => import('@/views/home/report-setting/ReportSettingDetail.vue'),
      meta: {
        group: 'REPORTING & DATA',
        breadcrumb: [
          { title: 'Report Setting Data', icon: icon.fileExcelRi, routeName: 'report-setting' },
          { title: 'Report Setting Detail', icon: null, routeName: 'report-setting-data-detail' },
        ],
        permission
      }
    }
  ]

  return listRoute
}
