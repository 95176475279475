import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.ListLabelDistributions),
    // write: checkPermission(PermissionEnum.CreateLabelDistribution),
    // update: checkPermission(PermissionEnum.TransferLabelDistribution),
    // delete: checkPermission(PermissionEnum.DeleteLabelDistribution)
    read: true,
    write: true,
    update: true,
    delete: true
  }
  // if (checkPermissionObj(permission)) {
  listRoute.push({
    path: `${val}/label-agent`,
    name: 'master-ag-label',
    component: () => import('@/views/master/items/label-agent/LabelAgent.vue'),
    meta: {
      group: 'KOTA & AGENT',
      permission
    }
  })
  // }
  return listRoute
}
