/* eslint-disable */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import { fetchAllTransPengirimanHd,
  fetchSingleTransPengirimanHd,
  fetchSingleTransPengirimanHdCancelationReq,
  acknowledgeCancelRequestedPackage,
  approveCancelRequestedPackage,
  declineCancelRequestedPackage,
  fetchTransPengirimanHdPackageLogs
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

class CancelPackageUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?'
    if (filter) {

      const arrFilter = []
      if (filter.custom) {
        arrFilter.push(filter.custom)
      }
      if (filter.search) {
        arrFilter.push(`${filter.search}`)
      }
      if (arrFilter.length > 0) {
        params = `${params}${arrFilter.join('&')}`
      }
    }
    return new Repository(fetchAllTransPengirimanHd(params), null).getResult(false)
  }

  getOne(id: any): Promise<Response> {
    // throw new Error('Method not implemented.')
    return new Repository(fetchSingleTransPengirimanHdCancelationReq(id), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getOneAcknowledgeCancelPackage(id: any): Promise<Response> {
    return new Repository(fetchSingleTransPengirimanHd(id), null).getResult(false)
  }

  acknowledgeCancelPackage(id: any): Promise<Response> {
    return new Repository(acknowledgeCancelRequestedPackage(id), null).getResult(false)
  }

  approveCancelPackage(id: any): Promise<Response> {
    return new Repository(approveCancelRequestedPackage(id), null).getResult(false)
  }

  declineCancelPackage(id: any): Promise<Response> {
    return new Repository(declineCancelRequestedPackage(id), null).getResult(false)
  }

  getCancelPackageLogs(id: any): Promise<Response> {
    return new Repository(fetchTransPengirimanHdPackageLogs(id), null).getResult(false)
  }
}

const cancelPackageUseCase = new CancelPackageUseCase()

export {
  cancelPackageUseCase
}
