/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import Repository from '@/data/repository'
import {
  fetchAllListManifest,
  fetchOneJadwalHd,
  fetchAllPengirimanHd,
  addManifestList,
  fetchAllLoadingManifest,
  addPendingManifest,
  addManifest,
  fetchAllManifestOneReceipt,
  fetchAllListManifestV2,
  fetchAllManifest
} from '@/data/source/remote/api'

class DeliveryManifestUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.date) {
        arrFilter.push(`WaktuKeberangkatan eq ${filter.date}`)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Jadwal), '${filter.search.toLowerCase()}') or Contains(tolower(KodeTipeBus), '${filter.search.toLowerCase()}')) `)
      }
      if (filter.rit.length > 0) {
        filter.rit = filter.rit.map((item: any) => `'${item}'`)
        arrFilter.push(`Rit in (${filter.rit.join(',')})`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllListManifest(params), null).getResult(false)
  }

  getOneDetail(jadwalId: any, filter: any = {}): Promise<any> {
    let params = '?'
    if (filter) {
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
    }
    return new Repository(fetchOneJadwalHd(jadwalId, params), null).getResult(false)
  }

  getDepatureManifest(jadwalId: any, agentId: any): Promise<Response> {
    const params = `?$Filter=TransJadwalHdId eq ${jadwalId} and AgenAsalId eq ${agentId} and statusPengirimanId eq 1&$expand=TransPengirimanDetails($count=true;$select=id;$expand=KategoriBarang($select=isRefund))&$Select=Id,Resi`
    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }

  getLoadingManifest(jadwalId: any, agentId: any, access: any): Promise<Response> {
    const params = `?ShowAll=${access}&JadwalHdId=${jadwalId}&AgenId=${agentId}&manifested=true`
    return new Repository(fetchAllLoadingManifest(params), null).getResult(false)
  }

  getLoadingManifestDetail(jadwalId: any, agentId: any): Promise<Response> {
    const params = `?$Filter=TransJadwalHdId eq ${jadwalId} and AgenAsalId eq ${agentId} and ManifestId ne null&$expand=TransPengirimanDetails($count=true;$select=id),Manifest($select=Nopol,NamaKru,NamaPramugara,NoManifest)&$Select=Id,Resi,ManifestId`
    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }

  getListManifestV2(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      if (filter.filter) {
        params = `${params}${filter.filter}`
      }
      if (filter.orderby) {
        params = `${params}${filter.orderby}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.date) {
        arrFilter.push(`TanggalAktif eq ${filter.date}`)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(KodeJadwal), '${filter.search.toLowerCase()}')) `)
      }
      if (filter.rit.length > 0) {
        // filter.rit = filter.rit.map((item: any) => `'${item}'`)
        filter.rit = filter.rit.map((item: any) => {
          if (typeof item === 'string') {
            return `${item}`
          }
          return `'${item}'`
        })
        arrFilter.push(`Rit in (${filter.rit.join(',')})`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllListManifestV2(params), null).getResult(false)
  }

  getLastManifest(filter: any = ''): Promise<any> {
    let params = '?$count=true'
    if (filter) {
      if (filter.orderby) {
        params = `${params}${filter.orderby}`
      }
      if (filter.filter) {
        params = `${params}${filter.filter}`
      }
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
    }
    return new Repository(fetchAllManifest(params), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitLoadingManifest(data: any): Promise<Response> {
    return new Repository(addManifestList(data), null).getResult(false)
  }

  submitPendingManifest(data: any): Promise<Response> {
    return new Repository(addPendingManifest(data), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(addManifest(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const deliveryManifestUseCase = new DeliveryManifestUseCase()

export {
  deliveryManifestUseCase
}
