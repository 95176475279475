
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ListCopyEditDel',
  props: {
    copy: {
      default: () => null
    },
    edit: {
      default: () => null
    },
    editTitle: {
      default: () => 'Edit'
    },
    del: {
      default: () => null
    },
    delTitle: {
      default: () => 'Hapus'
    },
    addHour: {
      default: () => null
    },
    clone: {
      default: () => null
    },
    detail: {
      default: () => null
    },
    toManifest: {
      default: () => null
    },
    pending: {
      default: () => null
    },
    refund: {
      default: () => null
    },
    payKlaim: {
      default: () => null
    },
    addKlaim: {
      default: () => null
    },
    add: {
      default: () => null
    },
    verif: {
      default: () => null
    },
    packageReturn: {
      default: () => null
    },
    changeCourier: {
      default: () => null
    },
    assignCourier: {
      default: () => null
    },
    activateTop: {
      default: () => null
    },
    discountLevel: {
      default: () => null
    },
    transLimit: {
      default: () => null
    },
    nonActivateTop: {
      default: () => null
    },
    changeDeposit: {
      default: () => null
    },
    changeCutoffDate: {
      default: () => null
    },
    manualCutOff: {
      default: () => null
    },
    resetKurir: {
      default: () => null
    },
    withdrawDeposit: {
      default: () => null
    },
    editPelanggan: {
      default: () => null
    },
    levelupPelanggan: {
      default: () => null
    },
    editPermission: {
      default: () => null
    }
  }
})
