
//  fetchAllStatusResi,
//  fetchOneStatusResi,
//  addStatusResi,
//  editStatusResi,
//  deleteStatusResi,
//  changeIsActiveStatusResi
import {
  fetchAllStatusResi, addStatusResi, editStatusResi, fetchOneStatusResi, deleteStatusResi, changeIsActiveStatusResi
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

const getAllMsStatusResi = (filter: any = {}) => {
  // let params = (filter.search) ? `?$count=true&$expand=KomisiHd&$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : '?$count=true&$expand=KomisiHd'
  let params = (filter.search) ? `?$count=true&$filter=(Contains(tolower(Status),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : '?$count=true'
  params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
  return new Repository(fetchAllStatusResi(params), null).getResult(false)
}

const submitMsStatusResi = async (data: any) => {
  let response
  if (data.Id) {
    response = await new Repository(editStatusResi(data.Id, data), null).getResult(false)
  } else {
    data = {
      Status: data.Status,
      Kode: data.Kode,
      Warna: data.Warna,
      Icon: data.Icon,
      CanChange: data.CanChange,
      IsActive: data.IsActive,
    }
    response = await new Repository(addStatusResi(data), null).getResult(false)
  }

  return response
}

const dataEditMsStatusResi = (id: any) => new Repository(fetchOneStatusResi(id), null).getResult(false)

const deleteMsStatusResi = (id: any) => new Repository(deleteStatusResi(id), null).getResult(false)

const dataEditStatusResiIsActive = (data: any) => new Repository(changeIsActiveStatusResi(data), null).getResult(false)

export {
  getAllMsStatusResi,
  submitMsStatusResi,
  dataEditMsStatusResi,
  deleteMsStatusResi,
  dataEditStatusResiIsActive
}
