import itemCategory from './itemCategory'
import itemUnit from './itemUnit'
import label from './label'
import sticker from './sticker'

export default () => {
  const getItemsCategory = itemCategory('barang')
  const getItemUnit = itemUnit('barang')
  const getLabel = label('barang')
  const getSticker = sticker('barang')

  return [
    ...getItemsCategory,
    ...getItemUnit,
    ...getLabel,
    ...getSticker
  ]
}
