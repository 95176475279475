import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransKlaim'

const fetchAllTransKlaim = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneTransKlaim = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addTransKlaim = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editTransKlaim = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteTransKlaim = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchTransLastKlaim = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/LastTransaction${filter}`)

export {
  fetchAllTransKlaim,
  fetchOneTransKlaim,
  addTransKlaim,
  editTransKlaim,
  deleteTransKlaim,
  fetchTransLastKlaim
}