import cashier from './cashier'

const prefix = 'manifest-revenue'

export default () => {
  const getCashier = cashier(prefix)

  return [
    ...getCashier,
  ]
}
