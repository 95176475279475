import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/pricegoodcategories'

const fetchPriceGoodCategory = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const duplicatePriceGoodCategory = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/duplicate`, data)
const modifyPriceGoodCategory = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/details`, data)

export {
  fetchPriceGoodCategory,
  duplicatePriceGoodCategory,
  modifyPriceGoodCategory
}
