import { App } from 'vue'
import icons from './ui/icons'
import mitt from './ui/mitt'
import toast from './ui/toast'
import primeComponent from './ui/primeComponent'
import enumBase from './enum'
import stringLanguage from './stringLanguage'
import numbers from './numbers'
import methods from './methods'
import arrays from './arrays'
import inputComponent from './ui/inputComponents'
import listCopyEditDel from './ui/listCopyEditDel'
import confirmationService from './ui/confirmationService'
import multiselect from './ui/selectComponent'
import multiselect2 from './ui/selectComponentv2'
import paginatorPage from './ui/paginatorPage'
import Maps from './ui/Maps'
import dialogDetailResi from './ui/dialogDetailResi'
import dialogHandoverItem from './ui/dialogHandoverItem'
import dialogAddLoadingManifest from './ui/dialogAddLoadingManifest'
import dialogAddPendingManifest from './ui/dialogAddPendingManifest'
import itemReceiptCard from './ui/itemReceiptCard'
import googleAutocomplete from './ui/googleAutocomplete'
import tinycolor2 from './ui/tinycolor2'
import vueCopyClipboard from './ui/vueCopyClipboard'
import vueSweetAlert2 from './ui/vueSweetAlert2'
import mqtt from './service/mqtt'
import vueQuery from './vueQuery'
// import infiniteLoading from './ui/infiniteLoading'
// import VueCryptojs from './ui/vueCryptojs'

const definePlugin = (app: App<Element>) => {
  const vue = app
  vue.use(icons)
  vue.use(mitt)
  vue.use(primeComponent)
  vue.use(toast)
  vue.use(inputComponent)
  vue.use(enumBase)
  vue.use(confirmationService)
  vue.use(stringLanguage)
  vue.use(numbers)
  vue.use(methods)
  vue.use(arrays)
  vue.use(listCopyEditDel)
  vue.use(multiselect)
  vue.use(multiselect2)
  vue.use(paginatorPage)
  vue.use(Maps)
  vue.use(dialogDetailResi)
  vue.use(dialogHandoverItem)
  vue.use(dialogAddLoadingManifest)
  vue.use(dialogAddPendingManifest)
  vue.use(itemReceiptCard)
  vue.use(googleAutocomplete)
  vue.use(tinycolor2)
  vue.use(vueCopyClipboard)
  vue.use(vueSweetAlert2)
  vue.use(mqtt)
  // vue.use(vueQuery)
  // vue.use(infiniteLoading)
  vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    mounted(el) {
      // Focus the element
      el.focus()
    }
  })
  vue.directive('scroll', {
    mounted(el: any, binding: any) {
      const f = (evt: any) => {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f)
        }
      }
      window.addEventListener('scroll', f)
    }
  })
}

export default definePlugin
