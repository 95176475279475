import axios, { AxiosResponse, AxiosError } from 'axios'
import network from '@/plugins/system/network'
import { infoDevice } from '@/plugins/system/device'
import {
  environment
} from '@/utils'
// eslint-disable-next-line import/no-cycle
import { ActionTree } from 'vuex'
import { RootState } from './types'

const actions: ActionTree<RootState, RootState> = {
  setIsAuthenAuthenticated: ({ commit }, isAuthen: boolean) => {
    commit('SET_IS_AUTHEN', isAuthen)
  },
  setDateAuthenAuthenticated: ({ commit }, dateAuthen: string) => {
    commit('SET_DATE_AUTHEN', dateAuthen)
  },
  setAppActiveUser: ({ commit }, payload: any) => {
    commit('SET_APP_ACTIVE_USER', payload)
  },
  setAppOpenticketAccount: ({ commit }, payload: any) => {
    commit('SET_APP_OPENTICKET_ACCOUNT', payload)
  },
  setAppOpenticketAuth: ({ commit }, payload: any) => {
    commit('SET_APP_OPENTICKET_AUTH', payload)
  },
  setAppOpenticketAuthDefault: ({ commit }, payload: any) => {
    commit('SET_APP_OPENTICKET_AUTH_DEFAULT', payload)
  },
  setRoute: ({ commit }, payload: any) => {
    commit('SET_ROUTE', payload)
  },
  setMenu: ({ commit }, payload: any) => {
    commit('SET_MENU', payload)
  },
  setMenuKurir: ({ commit }, payload: any) => {
    commit('SET_MENU_KURIR', payload)
  },
  setNoInternet: ({ commit }, noInternet: boolean) => {
    commit('SET_NO_INTERNET_MODAL', noInternet)

    if (noInternet) {
      commit('SET_SHOW_LOADER', false)
    }
  },
  logoutAccount: ({ commit }) => {
    commit('SET_IS_AUTHEN', false)
    commit('SET_DATE_AUTHEN', null)
    commit('SET_DEFAULT_DEVICE')
    commit('SET_APP_OPENTICKET_AUTH_DEFAULT')
    commit('SET_DATA_TOKEN_DEFAULT')
    commit('SET_SWITCH_MENU', 0)
    commit('SET_SHOW_ADVERTISE', false)
    commit('voucher/SET_DEFAULT_VOUCHER')
    commit('dashboard/SET_DEFAULT_STATE')
    commit('deliveryItem/SET_LIST_AGENT_DEFAULT')
    commit('deliveryItem/SET_DEFAULT_STATE')
    commit('appActiveUser/SET_DEFAULT_APP_ACTIVE_USER')
    commit('globalSetting/SET_GLOBAL_SETTING_DEFAULT')
    localStorage.clear()
  },
  watchConnection: ({ commit }) => {
    network.addListener('networkStatusChange', (status) => {
      commit('SET_CONNECTION', status.connected)
      commit('SET_CONNECTION_TYPE', status.connectionType)

      if (!status.connected) {
        commit('SET_NO_INTERNET_MODAL', true)
        commit('SET_SHOW_LOADER', false)
      } else {
        commit('SET_NO_INTERNET_MODAL', false)
      }
    })
  },
  initNetwork: async ({ commit }) => {
    network.getStatus()
      .then((status) => {
        commit('SET_CONNECTION', status.connected)
        commit('SET_CONNECTION_TYPE', status.connectionType)
      })
  },
  initDevice: async ({ commit }) => {
    infoDevice().then((device) => {
      commit('SET_PLATFORM', device.platform)
      if (device.platform === 'web') {
        commit('SET_IS_WEB', true)
      }

      if (device.platform === 'ios' || device.platform === 'android') {
        commit('SET_IS_MOBILE', true)
        commit('SET_IS_ANDROID', (device.platform === 'android'))
        commit('SET_IS_IOS', (device.platform === 'ios'))
      }
    })
  },
  initResize: ({ commit }) => {
    window.addEventListener('resize', () => {
      commit('SET_WIDTH', window.innerWidth)
      commit('SET_HEIGHT', window.innerHeight)
    })
  },
  setWidth: ({ commit }) => {
    commit('SET_WIDTH', window.innerWidth)
  },
  setHeight: ({ commit }) => {
    commit('SET_HEIGHT', window.innerHeight)
  },
  destroyResize: ({ commit }) => {
    window.removeEventListener('resize', () => ({}))
  },
  showLoading: ({ commit }) => {
    commit('SET_SHOW_LOADER', true)
  },
  hideLoading: ({ commit }) => {
    commit('SET_SHOW_LOADER', false)
  },
  updateRute: () => true,
  setToggleMenu: ({ commit }, toggle: boolean) => {
    commit('SET_TOGGLE_MENU', toggle)
  },
  setSwitchMenu: ({ commit }, toggle: number) => {
    commit('SET_SWITCH_MENU', toggle)
  },
  setUrlApi: ({ commit }, val: any) => {
    commit('SET_URL_API', val)
  },
  setUrlDefault: ({ commit }) => {
    commit('SET_URL_API_DEFAULT')
  },
  setDataToken: ({ commit }, payload: any) => {
    commit('SET_DATA_TOKEN', payload)
  },
  updateDataToken: ({ commit, state, dispatch }) => {
    dispatch('showLoading')
    const payload = new URLSearchParams() as any
    payload.append('client_id', environment.getHydraClientId())
    payload.append('client_secret', environment.getHydraClientSecret())
    payload.append('grant_type', 'refresh_token')
    payload.append('refresh_token', state.token.refreshToken)

    axios.post(`${environment.getHydraApi()}oauth2/token`, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${state.token.accessToken}`,
        Accept: '*/*'
      },
    }).then((res: AxiosResponse) => {
      // console.log('response update', res)
      commit('SET_DATA_TOKEN', res.data)
      setTimeout(() => {
        window.location.reload()
      }, 500)
      // Kondisinya saya command dulu karena logic kondisinya ga sesuai dengan response
      // if (!res?.error) {
      // } else {
      //   window.location.href = `${window.location.origin}`
      //   dispatch('logoutAccount')
      // }
      dispatch('hideLoading')
    }).catch((error: AxiosError) => {
      console.log('error refresh token', error.response)
      // window.location.href = `${window.location.origin}`
      // dispatch('logoutAccount')
      dispatch('hideLoading')
    })
  },
  showAdvertise: ({ commit }) => {
    commit('SET_SHOW_ADVERTISE', true)
  },
  hideAdvertise: ({ commit }) => {
    commit('SET_SHOW_ADVERTISE', false)
  }
}

export default actions
