import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsBiayaTambahan'

const fetchAllAdditionalFee = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneAdditionalFee = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addAdditionalFee = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editAdditionalFee = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteAdditionalFee = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveAdditionalFee = (id: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive/${id}`)

export {
  fetchAllAdditionalFee,
  fetchOneAdditionalFee,
  addAdditionalFee,
  editAdditionalFee,
  deleteAdditionalFee,
  changeIsActiveAdditionalFee
}
