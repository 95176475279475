/* eslint-disable import/no-unresolved */
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { TDeliveryItem } from './types'
import deliveryItemActions from './deliveryItemActions'
import deliveryItemGetters from './deliveryItemGetters'
import deliveryItemMutations from './deliveryItemMutations'
import deliveryItemState from './deliveryItemState'

const deliveryItem: Module<TDeliveryItem, RootState> = {
  namespaced: true,
  state: deliveryItemState,
  mutations: deliveryItemMutations,
  actions: deliveryItemActions,
  getters: deliveryItemGetters
}

export default deliveryItem
