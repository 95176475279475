/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllCourierAccount, fetchOneCourierAccount, addCourierAccount, editCourierAccount, deleteCourierAccount, changeIsActiveCourierAccount, changeIsVerifiedCourierAccount, fetchMinimalInfoCourierAccount, fetchProfileCourier, fetchAssignCourier, changeIsBlockedCourierAccount, resetDeviceProfileMsUser
} from '@/data/source/remote/api'

class CourierUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveCourierAccount(data, data.Id), null).getResult(false)
  }

  changeIsVerified(data: any): Promise<Response> {
    return new Repository(changeIsVerifiedCourierAccount(data, data.Id), null).getResult(false)
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        // arrayFilter.push(`Contains(tolower(Nama),'${filter.search}')`)
        arrayFilter.push(`(Contains(tolower(Username),'${filter.search}') or Contains(tolower(Handphone), '${filter.search}') or Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Attribute2), '${filter.search}') or Contains(tolower(Attribute3), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllCourierAccount(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneCourierAccount(id), null).getResult(false)
      return {
        title: 'Edit Stiker',
        data: response
      }
    }

    return {
      title: 'Tambah Stiker',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  submitDataForm(id: any, username: any, data: any): Promise<Response> {
    if (username) {
      data.Username = username
    }
    if (id) {
      data.Id = id
      return new Repository(editCourierAccount(id, data), null).getResult(false)
    }
    return new Repository(addCourierAccount(data), null).getResult(false)
  }

  getMinimalInfo(filter: any = ''): Promise<Response> {
    let params = ''
    if (filter) {
      if (filter.PageNumber) {
        params = `${params}${filter.PageNumber}`
      }
      if (filter.PageSize) {
        params = `${params}${filter.PageSize}`
      }
      if (filter.AgenId) {
        params = `${params}${filter.AgenId}`
      }
      if (filter.GroupCityId) {
        params = `${params}${filter.GroupCityId}`
      }
      if (filter.KurirName) {
        params = `${params}${filter.KurirName}`
      }
    }
    return new Repository(fetchMinimalInfoCourierAccount(params), null).getResult(false)
  }

  getListAssignCourier(filter: any = ''): Promise<Response> {
    let params = ''
    if (filter) {
      if (filter.PageNumber) {
        params = `${params}${filter.PageNumber}`
      }
      if (filter.PageSize) {
        params = `${params}${filter.PageSize}`
      }
      if (filter.AgenId) {
        params = `${params}${filter.AgenId}`
      }
    }
    return new Repository(fetchAssignCourier(params), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteCourierAccount(id), null).getResult(false)
  }

  getProfileCourier(id: any): Promise<Response> {
    return new Repository(fetchProfileCourier(id), null).getResult(false)
  }

  changeIsBlocked(data: any): Promise<Response> {
    return new Repository(changeIsBlockedCourierAccount(data, data.Id), null).getResult(false)
  }

  resetDevice(username: any): Promise<Response> {
    return new Repository(resetDeviceProfileMsUser(username), null).getResult(false)
  }
}

const courierUseCase = new CourierUseCase()

export {
  courierUseCase
}
