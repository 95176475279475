import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsRute'

const fetchRute = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addRute = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editRute = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteRute = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchRute,
  addRute,
  editRute,
  deleteRute
}
