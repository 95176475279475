import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'
import iconDependency from '@/dependencies/iconDependency'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  const listRoute = [
    {
      path: `${val}`,
      name: 'reporting-data',
      component: () => import('@/views/home/reporting/ReportingIndex.vue'),
      meta: {
        group: 'REPORTING',
        permission
      }
    },
    {
      path: `${val}/print`,
      name: 'reporting-print',
      component: () => import('@/views/home/reporting/Reporting.vue'),
      meta: {
        group: 'Advanced Report',
        permission
      }
    }, {
      path: `${val}/excel`,
      name: 'reporting-excel',
      component: () => import('@/views/home/reporting/Reporting.vue'),
      meta: {
        group: 'Excel Report',
        permission
      }
    }, {
      path: `${val}/:id/excel`,
      name: 'reporting-data-detail',
      component: () => import('@/views/home/reporting/ReportingDetail.vue'),
      meta: {
        group: 'REPORTING & DATA',
        breadcrumb: [
          { title: 'Reporting Data', icon: icon.fileExcelRi, routeName: 'reporting-excel' },
          { title: 'Reporting Detail', icon: null, routeName: 'reporting-data-detail' },
        ],
        permission
      }
    }
  ]

  return listRoute
}
