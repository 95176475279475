/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllJenisPembayaran, fetchOneJenisPembayaran, addJenisPembayaran, editJenisPembayaran
} from '@/data/source/remote/api/master'
import { Observable } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'

class PaymentUsecase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = ''
    if (filter) {
      params = (filter.search) ? `?$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : ''
    }
    const response = new Repository(fetchAllJenisPembayaran(params), null).getResult(false)
    return response
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      return new Repository(editJenisPembayaran(id, data), null).getResult(false)
    }
    return new Repository(addJenisPembayaran(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  searchInput(input: string, filter: any = '') {
    const search = Observable.create((observer: any) => {
      observer.next(input)
    })
    search.pipe(
      debounceTime(500)
    ).subscribe((val: any) => {
      console.log('input', val)
    })
  }
}

const paymentUseCase = new PaymentUsecase()

export {
  paymentUseCase
}
