import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => [
  {
    path: `${val}`,
    name: 'transaksi-jadwal-bus',
    component: () => import('@/views/transactions/schedule-bus/ScheduleBus.vue'),
    meta: {
      group: 'PENJADWALAN BUS',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  },
  {
    path: `${val}/:idJadwal`,
    name: 'transaksi-jadwal-bus-detail',
    component: () => import('@/views/transactions/schedule-bus/detail/ScheduleBusDetail.vue'),
    meta: {
      group: 'PENJADWALAN BUS',
      breadcrumb: [
        { title: 'Penjadwalan Bus', icon: icon.calendarOutlineEva, routeName: 'transaksi-jadwal-bus' },
        { title: 'Detail Jadwal', icon: null, routeName: 'transaksi-jadwal-bus-detail' },
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  }
]
