import adminUser from './admin-user/adminUser'
import trips from './trips/trips'
import items from './items'
import customers from './customers'
import transactions from './transactions'
import claimAndInsurance from './claim-and-insurance'
import statusCheckResi from './transactions/statusCheckResi'

export default () => {
  const getItems = items()
  const getAdminUser = adminUser()
  const getTrips = trips()
  const getClaimAndInsurance = claimAndInsurance()
  const getCustomers = customers()
  const getTransactions = transactions()
  const getStatusCheckResi = statusCheckResi()

  return [
    ...getItems,
    ...getAdminUser,
    ...getTrips,
    ...getClaimAndInsurance,
    ...getCustomers,
    ...getTransactions,
    ...getStatusCheckResi,
  ]
}
