import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TMicroInfoRequest } from './msUsers-model'

const baseUrl = 'kurir/v1/MsUsers'

/* const loginApi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/LoginUser`, data) */
const fetchAllCourierAccount = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneCourierAccount = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const fetchMinimalInfoCourierAccount = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/MinimalInfo${filter}`)
const addCourierAccount = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editCourierAccount = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteCourierAccount = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveCourierAccount = (data: any, id: any) => new Remote(remoteEnum.put, `${baseUrl}/IsActive/${id}`, data)
const changeIsVerifiedCourierAccount = (data: any, id: any) => new Remote(remoteEnum.put, `${baseUrl}/IsVerified/${id}`, data)
const fetchProfileCourier = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/Profile/${id}`)
const fetchAssignCourier = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/ListAssign${filter}`)
const changeIsBlockedCourierAccount = (data: any, id: any) => new Remote(remoteEnum.put, `${baseUrl}/IsBlocked/${id}`, data)
const fetchWeightComissionResi = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/BeratKomisiResi/${id}`)
const fetchMicroInfo = (data: TMicroInfoRequest[]) => new Remote(remoteEnum.post, `${baseUrl}/MicroInfo`, data)
const fetchAdjustmentCourier = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/AdjustmentDataKurir${filter}`)
/* const changePassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ChangePassword`, data)
const forgotPassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ForgotPassword`, data)
const resetPassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ResetPassword`, data) */

export {
  /* loginApi, */
  fetchAllCourierAccount,
  fetchOneCourierAccount,
  addCourierAccount,
  editCourierAccount,
  deleteCourierAccount,
  changeIsActiveCourierAccount,
  changeIsVerifiedCourierAccount,
  fetchMinimalInfoCourierAccount,
  fetchProfileCourier,
  fetchAssignCourier,
  changeIsBlockedCourierAccount,
  fetchWeightComissionResi,
  fetchMicroInfo,
  fetchAdjustmentCourier
  /* forgotPassword,
  changePassword,
  resetPassword */
}
