import { Remote, remoteEnum } from '@/data/source/remote/remoteIdentity'

const baseUrl = 'v1/msaddress'

const fetchAllUserCompanyAddress = () => new Remote(remoteEnum.get, `${baseUrl}`)
const fetchUserCompanyAddressByUsername = (username: any) => new Remote(remoteEnum.get, `${baseUrl}/GetByUsername/${username}`)
const fetchUserCompanyAddressById = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const changeIsPrimaryCompanyAddress = (id: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/SetAsPrimary`)

export {
  fetchAllUserCompanyAddress,
  fetchUserCompanyAddressByUsername,
  changeIsPrimaryCompanyAddress,
  fetchUserCompanyAddressById
}
