import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.ListRoutes),
    // write: checkPermission(PermissionEnum.CreateRoute),
    // update: checkPermission(PermissionEnum.GetRoute),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  if (checkPermissionObj(permission)) {
    listRoute.push({
      path: `${val}/rute`,
      name: 'master-rute',
      component: () => import('@/views/master/transactions/route/Route.vue'),
      meta: {
        group: 'TRANSAKSI',
        permission
      }
    })
  }
  return listRoute
}
