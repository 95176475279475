import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.ListGroupUsers),
    // write: checkPermission(PermissionEnum.CreateGroupUser),
    // update: checkPermission(PermissionEnum.UpdateGroupUser),
    // delete: checkPermission(PermissionEnum.DeleteGroupUser)
    read: true,
    write: true,
    update: true,
    delete: true
  }
  if (checkPermissionObj(permission) || checkPermission(PermissionEnum.GetGroupUser)) {
    listRoute.push({
      path: `${val}/grup-user`,
      name: 'master-grup-user',
      component: () => import('@/views/master/user-admin/group-user/GroupUser.vue'),
      meta: {
        group: 'USER & ADMIN',
        permission
      }
    })
  }

  if (permission.write) {
    listRoute.push({
      path: `${val}/grup-user/baru`,
      name: 'master-grup-user-baru',
      component: () => import('@/views/master/user-admin/group-user/GroupUserForm.vue'),
      meta: {
        group: 'USER & ADMIN',
        breadcrumb: [
          { title: 'User & Admin', icon: icon.userBx, routeName: 'master-user' },
          { title: 'Grup User', icon: null, routeName: 'master-grup-user' },
          { title: 'Tambah Grup User', icon: null, routeName: 'master-grup-user-baru' },
        ],
        permission
      }
    })
  }

  if (permission.update) {
    listRoute.push({
      path: `${val}/grup-user/edit/:id`,
      name: 'master-grup-user-edit',
      component: () => import('@/views/master/user-admin/group-user/GroupUserForm.vue'),
      meta: {
        group: 'USER & ADMIN',
        breadcrumb: [
          { title: 'User & Admin', icon: icon.userBx, routeName: 'master-user' },
          { title: 'Grup User', icon: null, routeName: 'master-grup-user' },
          { title: 'Edit Grup User', icon: null, routeName: 'master-grup-user' },
        ],
        permission
      }
    })
  }

  listRoute.push({
    path: `${val}/grup-user/:id/permissions`,
    name: 'master-grup-user-permissions',
    component: () => import('@/views/master/user-admin/group-user/GroupUserPermission.vue'),
    meta: {
      group: 'USER & ADMIN',
      breadcrumb: [
        { title: 'User & Admin', icon: icon.userBx, routeName: 'master-user' },
        { title: 'Grup User', icon: null, routeName: 'master-grup-user' },
        { title: 'Edit Permission Group User', icon: null, routeName: 'master-grup-user-permissions' },
      ]
    }
  })

  return listRoute
}
