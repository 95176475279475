import {
  ref, Ref, onBeforeUnmount, watch
} from 'vue'
import { Observable, Subject, Observer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

function subscribeTo<T>(
  observable: Observable<T>,
  next?: (value: T) => void,
  error?: (err: any) => void,
  complete?: () => void
) {
  const unsubscribe$ = new Subject<void>()
  const subscription = observable
    .pipe(takeUntil(unsubscribe$))
    .subscribe(next, error, complete)
  onBeforeUnmount(() => {
    unsubscribe$.next()
    unsubscribe$.complete()
  })

  return subscription
}

export function useObservable<T>(
  observable: Observable<T>,
  defaultValue?: T
): Ref<T> {
  const handler = ref(defaultValue) as Ref<T>
  subscribeTo(
    observable,
    (value) => {
      handler.value = value
    },
    (error) => {
      throw error
    }
  )

  return handler
}

export function useSubscription<T>(
  observable: Observable<T>,
  next?: (value: T) => void,
  error?: (err: any) => void,
  complete?: () => void
) {
  return subscribeTo(observable, next, error, complete)
}

export function useDOMEvent() {
  const subject = new Subject()
  return {
    subject,
    callback: (event: Event) => {
      subject.next(event)
    }
  }
}
type RefObs<T = any> = Ref<T> & Observer<T> & Observable<T>
// and something like
export function refObs(value?: unknown): RefObs {
  const or = ref(value) as RefObs
  const beha = new Subject()
  or.next = beha.next.bind(beha)
  or.pipe = beha.pipe.bind(beha)
  or.subscribe = beha.subscribe.bind(beha)
  watch(or, (val) => beha.next(val))
  onBeforeUnmount(() => beha.unsubscribe())
  return or
}