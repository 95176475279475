import { Remote, remoteEnum } from '@/data/source/remote/remoteOpenTicket'

// const baseUrl = 'ticket-support/api/v1/login'
const baseUrl = 'api/v1/login'

const authUser = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

export {
  authUser
}
