import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransUserPengirimans'

const fetchAllTransUserPengirimans = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneTransUserPengirimans = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const multipleAssignCourier = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/AssignCourier`, data)
const addTransUserPengirimans = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const multiplePackageReturns = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ReturnPacket`, data)
const transferCourier = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/TransferKurir`, data)
const changeCourier = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ChangeCourier`, data)

export {
  fetchAllTransUserPengirimans,
  multipleAssignCourier,
  addTransUserPengirimans,
  multiplePackageReturns,
  transferCourier,
  changeCourier,
  fetchOneTransUserPengirimans
}
