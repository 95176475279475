import store from '@/store'
import Permision from '../enum/permissions'

const module = 'appActiveUser'

const moduleGetter = store.getters[`${module}/getPermissions`]
const checkPermission = (permissionEnum = ''): boolean => moduleGetter.includes(Permision.AllPermission) || moduleGetter.includes(permissionEnum)

const checkPermissionObj = (permission: { [key: string]: boolean }): boolean => {
  const convertToArr = Object.keys(permission)
  const getValAttr = convertToArr.map((key: string) => permission[key])
  return getValAttr.includes(true)
}

export {
  checkPermission,
  checkPermissionObj
}
