
/* eslint-disable */
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import GeolocationExample from "@/plugins/system/geolocation";

export default {
  name: "Maps",
  emits: ['sendData'],
  setup(props: any, { emit }: any) {
    const otherPos = ref(null);
    const mapDiv = ref(null);

    const selectedLocation = ref("") as any;
    const lat = ref('') as any;
    const lng = ref('') as any;
    let map = null as any;
    let infoWindow = null as any
    let markers: google.maps.Marker[] = [];

    const geocoder = new google.maps.Geocoder();

    let currentLatLng = ref({
      longitude: "",
      latitude: ""
    }) as any;

    let marker = null as any;
    console.log("version", google.maps.version);
    const icon = {
        url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        size: new google.maps.Size(30, 30),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25)
      };

    function clearMarker() {
      markers.forEach(marker => {
        marker.setMap(null);
      });
      markers = [];
    }

    const infoWindowRestart = (latLangData: any, marker: any) => {
        infoWindow?.close();
        infoWindow = new google.maps.InfoWindow({
          position: latLangData
        });
        infoWindow?.setContent('Lokasi Anda ');
        infoWindow?.open(map, marker);
      }
    const infoWindowRestartNew  =(latLangData: any, marker: any, address: any) => {
        infoWindow?.close();
        infoWindow = new google.maps.InfoWindow({
          position: latLangData
        });
        selectedLocation.value = address
        lat.value = latLangData.lat()
        lng.value = latLangData.lng()
        infoWindow?.setContent(`${address}, {lat: ${lat.value}, lng: ${lng.value}}`);
        infoWindow?.open(map, marker);
    }

    const createMarker = (map: any, placeInfo: any) => {
      const marker =  new google.maps.Marker({
        map,
        icon,
        title: placeInfo.name,
        position: placeInfo.location
      });
      markers.push(marker);
      return marker;
    }

    function CenterControl(controlDiv: Element, map: google.maps.Map) {
      const controlUI = document.getElementById("desckriptionEl") as HTMLDivElement;

      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginTop = "8px";
      controlUI.style.marginBottom = "22px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to recenter the map";
      controlDiv.appendChild(controlUI);
      controlUI.addEventListener("click", () => {
        saveData()
      });

    }


    const geocodeLatLngAndMarkPan = ( latlng: any, geocoder: any ) => {
        geocoder.geocode({ location: latlng })
          .then((response: any) => {
            if (response.results[0]) {
              map.setZoom(15);
              const marker = createMarker(map, { name: "test potision", location: latlng })
              infoWindowRestartNew(latlng, marker, `${response.results[0].formatted_address}`)
            } else {
              window.alert("No results found");
            }
          })
          .catch((e: any) => window.alert("Geocoder failed due to: " + e));
    }

    const initMap = async () => {
      currentLatLng.value = await GeolocationExample.getCurrentPosition();
      map = new google.maps.Map(document.getElementById("mapDiv") as HTMLElement, {
        center: {
          lat: currentLatLng.value.latitude,
          lng: currentLatLng.value.longitude
        },
        zoom: 15
      }) as any;

      infoWindow = new google.maps.InfoWindow({
        content: "Lokasi Anda",
        position: {
          lat: currentLatLng.value.latitude,
          lng: currentLatLng.value.longitude
        }
      })

      const centerControlDiv = document.createElement("div");
      CenterControl(centerControlDiv, map);
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);

      map.addListener("click", (mapsMouseEvent: any) => {
        clearMarker();
        console.log("markers", markers);
        geocodeLatLngAndMarkPan( mapsMouseEvent.latLng, geocoder )
      });

      const input = document.getElementById("pac-input") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);

      map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        console.log("places_changed searchBox.getPlaces()", searchBox.getPlaces());
        const places = searchBox.getPlaces() as any;

        if (places.length == 0) {
          return;
        }

        clearMarker();

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place: any) => {

          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }
          // let marker = createMarker(map, {title: place.name, position: place.geometry.location })
          geocodeLatLngAndMarkPan( place.geometry.location, geocoder )

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        map.fitBounds(bounds);
      });
    };
    const saveData = ()=>{
      // clearMarker()
      const res  = {selectedLocation: selectedLocation.value, lat: lat.value, lng: lng.value}
      emit('sendData', JSON.stringify(res) )
    }
    onMounted(async () => {
      initMap();
    });
    onUnmounted(async () => {
      // initMap();
    });

    return {
      otherPos,
      mapDiv,
      selectedLocation,
      saveData
    };
  }
};
