/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllJadwalHd,
  fetchOneJadwalHd,
  addJadwalHd,
  editJadwalHd,
  deleteJadwalHd,
  addJadwalHdMultiple,
  addJadwalHdDuplicate,
  fetchAllTipeBus,
  changeIsActiveJadwalHd,
  searchJadwalHd,
  penambahanWaktu,
  closeJadwal,
  fetchAllVwPenutupanKasir
} from '@/data/source/remote/api'

class ScheduleBusUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveJadwalHd(data), null).getResult(false)
  }

  searchJadwalBus(filter: any): Promise<Response> {
    return new Repository(searchJadwalHd(filter), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    // console.log('ini filter', filter)
    let params = '$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(KodeJadwal), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    }
    // console.log(query)
    return new Repository(fetchAllJadwalHd(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<Response> {
    const query = '?$expand=TipeBus($select=Nama,Kode,Id)'
    return new Repository(fetchOneJadwalHd(id, query), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== null) {
      data.Id = id
      return new Repository(editJadwalHd(id, data), null).getResult(false)
    } return new Repository(addJadwalHd(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteJadwalHd(id), null).getResult(false)
  }

  addMultipleData(data: any): Promise<Response> {
    return new Repository(addJadwalHdMultiple(data), null).getResult(false)
  }

  duplicateData(data: any): Promise<Response> {
    return new Repository(addJadwalHdDuplicate(data), null).getResult(false)
  }

  getTipeBus(): Promise<Response> {
    return new Repository(fetchAllTipeBus('?$count=true&$filter=IsActive eq true&$select=Id,IsActive,Kode,Nama'), null).getResult(false)
  }

  addedTime(data: any): Promise<Response> {
    return new Repository(penambahanWaktu(data), null).getResult(false)
  }

  closeSchedule(id: any, data: any): Promise<Response> {
    return new Repository(closeJadwal(id, data), null).getResult(false)
  }

  getAllVwPenutupanKasir(filter: any = null): Promise<Response> {
    // console.log('ini filter', filter)
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(KodeJadwal), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    }
    // console.log(query)
    return new Repository(fetchAllVwPenutupanKasir(params), null).getResult(false)
  }
}

const transScheduleBusUseCase = new ScheduleBusUseCase()

export {
  transScheduleBusUseCase
}
