import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsDiskon'

const fetchAllDiskon = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneDiskon = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addDiskon = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editDiskon = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteDiskon = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const checkVoucherDiskon = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/CheckVoucher`, data)
const changeIsActiveDiskon = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllDiskon,
  fetchOneDiskon,
  addDiskon,
  editDiskon,
  deleteDiskon,
  checkVoucherDiskon,
  changeIsActiveDiskon
}
