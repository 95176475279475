import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsPulau'

const fetchPulau = (filter = '', top: number, skip: number) => new Remote(remoteEnum.get, `${baseUrl}?$count=true&$top=${top}&$skip=${skip}${filter}`)
const fetchAllPulau = () => new Remote(remoteEnum.get, `${baseUrl}`)
const fetchOnePulau = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addPulau = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPulau = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deletePulau = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchPulau,
  fetchAllPulau,
  fetchOnePulau,
  addPulau,
  editPulau,
  deletePulau
}
