import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsGroupKota'

const fetchAllGroupKota = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)

const fetchOneGroupKota = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)

const addGroupKota = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

const editGroupKota = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

const deleteGroupKota = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllGroupKota,
  fetchOneGroupKota,
  addGroupKota,
  editGroupKota,
  deleteGroupKota
}
