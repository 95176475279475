export default {
  SET_DEFAULT_VOUCHER: (state: any) => {
    state.Kode = null
    state.Nama = null
    state.Keterangan = null
    state.Discount = 0
    state.MaxPotongan = 0
    state.Qty = 1
    state.UseLimit = 0
    state.Poin = 0
    state.GenerateRefCode = true
    state.IsMoney = false
    state.IsActive = true
    state.IsHidden = true
    state.Gambar.src = null
    state.Gambar.image = null
    state.MsVoucherDetails = []
    state.Type = null
  },
  SET_KODE: (state: any, payload: any) => {
    state.Kode = payload
  },
  SET_NAMA: (state: any, payload: any) => {
    state.Nama = payload
  },
  SET_DESCRIPTION: (state: any, payload: any) => {
    state.Keterangan = payload
  },
  SET_DISCOUNT: (state: any, payload: any) => {
    state.Discount = payload
  },
  SET_MAXPOTONGAN: (state: any, payload: any) => {
    state.MaxPotongan = payload
  },
  SET_QTY: (state: any, payload: any) => {
    state.Qty = payload
  },
  SET_USELIMIT: (state: any, payload: any) => {
    state.UseLimit = payload
  },
  SET_POIN: (state: any, payload: any) => {
    state.Poin = payload
  },
  SET_GEN_REFCODE: (state: any, payload: any) => {
    state.GenerateRefCode = payload
  },
  SET_ISMONEY: (state: any, payload: any) => {
    state.IsMoney = payload
  },
  SET_ISACTIVE: (state: any, payload: any) => {
    state.IsActive = payload
  },
  SET_ISHIDDEN: (state: any, payload: any) => {
    state.IsHidden = payload
  },
  SET_GAMBAR: (state: any, payload: any) => {
    state.Gambar.src = payload.src
    state.Gambar.image = payload.image
  },
  SET_VOUCHER_DETAILS: (state: any, payload: any) => {
    state.MsVoucherDetails = payload
  },
  SET_VOUCHER_TYPE: (state: any, payload: any) => {
    state.Type = payload
  },
  SET_VOUCHER_PENGGUNAAN: (state: any, payload: any) => {
    state.Penggunaan = payload
  },
}
