import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKomisiHd'

const fetchAllKomisi = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKomisi = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addKomisi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKomisi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKomisi = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const addKomisiArray = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Detail`, data)
const editKomisiArray = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/Detail/${id}`, data)

export {
  fetchAllKomisi,
  fetchOneKomisi,
  addKomisi,
  editKomisi,
  deleteKomisi,
  addKomisiArray,
  editKomisiArray
}
