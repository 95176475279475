import { openDB } from 'idb'

const idb = (table = '') => {
  if (!('indexedDB' in window)) {
    throw new Error('Browser does not support')
  }

  return openDB(process.env.INDEXEDDB_NAME || 'hjex', 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (!db.objectStoreNames.contains(table)) {
        const store = db.createObjectStore(table, {
          keyPath: 'id',
          autoIncrement: true
        })

        store.createIndex('username', 'username')
      }
    },
  })
}

export { idb }
