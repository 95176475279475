import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsDynamicReport'

const fetchAllDynamicReport = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchAllDynamicReportDynamic = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Dynamic`, data)
const excelAsuransi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/ExcelAsuransi`, data)
const fetchAllDynamicReportList = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/ListOfReport${filter}`)
const fetchAllDynamicExcelList = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/ListOfExcel${filter}`)
const fetchUrlReport = (data: any) => new Remote(remoteEnum.get, `${baseUrl}/Url/${data}`)

export {
  fetchAllDynamicReport,
  fetchAllDynamicReportDynamic,
  fetchAllDynamicReportList,
  fetchAllDynamicExcelList,
  fetchUrlReport,
  excelAsuransi
}