import courierData from './courierData'
import courierTracking from './courierTracking'
import courierComission from './courierComission'
import courierLogAttendance from './courierLogAttendance'
import courierComissionConfirmation from './comissionConfirmation'
import paymentComission from './payment-comission'
import comissionAdjustment from './comissionAdjustment'

const prefix = 'kurir'

export default () => {
  const getCourierData = courierData(prefix)
  const getCourierComission = courierComission(prefix)
  const getCourierTracking = courierTracking(prefix)
  const getCourierLogAttendance = courierLogAttendance(prefix)
  const getCourierComissionConfirmation = courierComissionConfirmation(prefix)
  const getPaymentComission = paymentComission(prefix)
  const getComissionAdjustment = comissionAdjustment(prefix)

  return [
    ...getCourierData,
    ...getCourierTracking,
    ...getCourierComission,
    ...getCourierLogAttendance,
    ...getCourierComissionConfirmation,
    ...getPaymentComission,
    ...getComissionAdjustment
  ]
}
