import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  const routes = [
    {
      path: `${val}`,
      name: 'open-ticket',
      component: () => import('@/views/home/open-ticket/OpenTicket.vue'),
      meta: {
        group: 'Submit Masalah',
        permission
      }
    },
  ]

  return routes
}
