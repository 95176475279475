import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/citygroups'

const fetchAllCityGroups = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addCityGroup = (payload: any) => new Remote(remoteEnum.post, `${baseUrl}`, payload)

export {
  fetchAllCityGroups,
  addCityGroup
}
