
import { computed, ref } from 'vue'

export default {
  props: ['rows', 'totalRecords'],
  emits: ['onPage'],
  setup(props: any, { emit }: any) {
    const row = ref(props.rows)
    const total = computed(() => props.totalRecords)
    const onPage = (event: any) => {
      const skip = event.page * row.value
      emit('onPage', skip)
    }
    return {
      row,
      total,
      onPage
    }
  }
}
