import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKategoriBarang'

const fetchAllKategoriBarang = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKategoriBarang = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addKategoriBarang = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKategoriBarang = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKategoriBarang = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveKategoriBarang = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
const fetchKategoriTipeAgent = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/KategoriTipeAgen${filter}`)

export {
  fetchAllKategoriBarang,
  fetchOneKategoriBarang,
  addKategoriBarang,
  editKategoriBarang,
  deleteKategoriBarang,
  changeIsActiveKategoriBarang,
  fetchKategoriTipeAgent
}
