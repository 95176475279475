import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransStockLabel'

const fetchAllStockLabel = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneStockLabel = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addStockLabel = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editStockLabel = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteStockLabel = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const generateLabelAgen = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/TambahStockLabel`, data)

export {
  fetchAllStockLabel,
  fetchOneStockLabel,
  addStockLabel,
  editStockLabel,
  deleteStockLabel,
  generateLabelAgen
}
