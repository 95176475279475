import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKomisiKurirPrediksi'

const fetchAllKomisiKurirPrediksi = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKomisiKurirPrediksi = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addKomisiKurirPrediksi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKomisiKurirPrediksi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKomisiKurirPrediksi = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllKomisiKurirPrediksi,
  fetchOneKomisiKurirPrediksi,
  addKomisiKurirPrediksi,
  editKomisiKurirPrediksi,
  deleteKomisiKurirPrediksi
}
