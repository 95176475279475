import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPenerimaanFiles'

const fetchAllPenerimaanFiles = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addPenerimaanFiles = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const deletePenerimaanFiles = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllPenerimaanFiles,
  addPenerimaanFiles,
  deletePenerimaanFiles
}