import {
  fetchRute,
  deleteRute,
  editRute,
  addRute,
  fetchAllKota,
  fetchAllHarga,
  fetchAllRoutes,
  fetchAllPrices,
  fetchOneRoutes,
  addRoute,
  editRoute
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

const getListRute = (filter: any = '') => {
  let query = '?$count=true'
  if (filter) {
    if (filter.expand) {
      query = `${query}${filter.expand}`
    }
    if (filter.select) {
      query = `${query}${filter.select}`
    }

    const arrayFilter = []
    if (filter.hargaRetail) {
      arrayFilter.push(`HargaRetailId eq ${filter.hargaRetail}`)
    }
    if (filter.hargaCompany) {
      arrayFilter.push(`HargaCompanyId eq ${filter.hargaCompany}`)
    }
    if (filter.search) {
      arrayFilter.push(`(Contains(tolower(KotaAsal/Kode),'${filter.search}') or Contains(tolower(KotaAsal/Nama), '${filter.search}') or Contains(tolower(KotaTujuan/Kode),'${filter.search}') or Contains(tolower(KotaTujuan/Nama), '${filter.search}'))`)
    }
    if (filter.custom) {
      arrayFilter.push(filter.custom)
    }

    let strFilter = arrayFilter.join(' and ')
    if (strFilter) {
      strFilter = `&$filter=${strFilter}`
    }

    if (filter.top) {
      strFilter = `${strFilter}&$top=${filter.top}`
    }
    if (filter.skip) {
      strFilter = `${strFilter}&$skip=${filter.skip}`
    }
    query = `${query}${strFilter}`
  }
  return new Repository(fetchRute(query), null).getResult(false)
}

const getListRuteX = (filter: any) => {
  console.log('filter', filter)
  // let params = '?'
  // if (filter) {
  //   const arrayFilter = []
  //   if (filter.search) {
  //     arrayFilter.push(`filter=${filter.search}`)
  //   }
  //   if (filter.pageNumber) {
  //     arrayFilter.push(`PageNumber=${filter.pageNumber}`)
  //   }
  //   if (filter.pageSize) {
  //     arrayFilter.push(`PageSize=${filter.pageSize}`)
  //   }
  //   if (filter.companyPriceId) {
  //     arrayFilter.push(`companyPriceId=${filter.companyPriceId}`)
  //   }
  //   if (filter.retailPriceId) {
  //     arrayFilter.push(`retailPriceId=${filter.retailPriceId}`)
  //   }
  //   if (arrayFilter.length > 0) {
  //     params = `${params}${arrayFilter.join('&')}`
  //   }
  // }
  return new Repository(fetchAllRoutes(returnUrlPrams(filter)), null).getResult(false)
}

const submitNewRute = async (data: any, id: any) => {
  let response
  if (id) {
    data.Id = Number(id)
    response = await new Repository(editRoute(data.Id, data), null).getResult(false)
  } else {
    response = await new Repository(addRoute(data), null).getResult(false)
  }
  return response
}

const getSingleRute = (id: number) => new Repository(fetchOneRoutes(id), null).getResult(false)

const deleteDataRute = (id: any) => new Repository(deleteRute(id), null).getResult(false)

const getSelectKota = (filter: any) => new Repository(fetchAllKota(filter), null).getResult(false)

const getSelectHarga = (filter: any) => new Repository(fetchAllHarga(filter), null).getResult(false)

const getAllPriceX = (filter: any) => {
  console.log(filter)
  let params = '?'
  if (filter) {
    const arrayFilter = []
    if (filter.search) {
      arrayFilter.push(`filter=${filter.search}`)
    }
    if (filter.orderby) {
      arrayFilter.push(`orderby=${filter.orderby}`)
    }
    if (filter.pageNumber) {
      arrayFilter.push(`PageNumber=${filter.pageNumber}`)
    }
    if (filter.pageSize) {
      arrayFilter.push(`PageSize=${filter.pageSize}`)
    }
    if (arrayFilter.length > 0) {
      params = `${params}${arrayFilter.join('&')}`
    }
  }
  return new Repository(fetchAllPrices(params), null).getResult(false)
}

export {
  getListRute,
  submitNewRute,
  deleteDataRute,
  getSelectKota,
  getSelectHarga,
  getListRuteX,
  getAllPriceX,
  getSingleRute
}
