import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_Paginator, {
      first: $setup.first,
      "onUpdate:first": _cache[1] || (_cache[1] = ($event: any) => ($setup.first = $event)),
      rows: $setup.row,
      totalRecords: $setup.total,
      onPage: $setup.onPage
    }, null, 8, ["first", "rows", "totalRecords", "onPage"])
  ]))
}