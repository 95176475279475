import receiveItem from './receiveItem'

const prefix = 'penerimaan-barang'

export default () => {
  const getReceiveItem = receiveItem(prefix)

  return [
    ...getReceiveItem
  ]
}
