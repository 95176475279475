import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/transpengirimanhds'

const fetchAllTransPengirimanHd = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchSingleTransPengirimanHd = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchSingleTransPengirimanHdCancelationReq = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/cancellationrequest`)
const acknowledgeCancelRequestedPackage = (id: any, data: any = null) => new Remote(remoteEnum.put, `${baseUrl}/${id}/acknowledgecancel`, data)
const approveCancelRequestedPackage = (id: any, data: any = null) => new Remote(remoteEnum.put, `${baseUrl}/${id}/approvecancel`, data)
const declineCancelRequestedPackage = (id: any, data: any = null) => new Remote(remoteEnum.put, `${baseUrl}/${id}/declinecancel`, data)
const fetchTransPengirimanHdPackageLogs = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/deliverystatuslogs`)
const fetchTransPengirmanHdLabelUsed = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/labels`)
const fetchTransPengirmanHdRecipient = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/reception`)
const fetchReturnRequestDetail = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/returnrequest`)
const approveReturnPackage = (id: string) => new Remote(remoteEnum.put, `${baseUrl}/${id}/approvereturn`)
const declineReturnPackage = (id: string) => new Remote(remoteEnum.put, `${baseUrl}/${id}/declinereturn`)

export {
  fetchAllTransPengirimanHd,
  fetchSingleTransPengirimanHd,
  fetchSingleTransPengirimanHdCancelationReq,
  acknowledgeCancelRequestedPackage,
  approveCancelRequestedPackage,
  declineCancelRequestedPackage,
  fetchTransPengirimanHdPackageLogs,
  fetchTransPengirmanHdLabelUsed,
  fetchTransPengirmanHdRecipient,
  fetchReturnRequestDetail,
  approveReturnPackage,
  declineReturnPackage
}
