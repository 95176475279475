import Compressor from 'compressorjs'

const compressImage = async (file: any) => new Promise((resolve, reject) => {
  const response = new Compressor(file, {
    quality: 0.5,
    convertSize: 5000,
    success(result: any) {
      resolve(result)
    },
    error(err: any) {
      reject(err)
    }
  })
  return response
})

export {
  compressImage
}