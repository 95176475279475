// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'KurirTransAbsensis'

const fetchCustomKurirTransAbsensis = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/DataAbsensi${filter}`)

export {
  fetchCustomKurirTransAbsensis
}
