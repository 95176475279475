/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  generateLabelAgen,
  fetchAllStockLabelV2,
  fetchOneStockLabelV2,
  addStockLabelV2,
  editStockLabelV2,
  deleteStockLabelV2,
  transferLabelV2,
  fetchLabelStocks
} from '@/data/source/remote/api'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { IFormLabelAgent } from '@/utils/interfaces/iLabelAgent'

class LabelAgentUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = null): Promise<Response> {
    return new Repository(fetchAllStockLabelV2(returnUrlPrams(filter)), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneStockLabelV2(id), null).getResult(false)
      return {
        title: 'Edit Label',
        data: response
      }
    }

    return {
      title: 'Tambah Label',
      data: null
    }
  }

  submitData(id: any, data: IFormLabelAgent): Promise<Response> {
    if (id) {
      const payload = {
        MsAgenId: data.destinationAgent
      }
      return new Repository(transferLabelV2(id, payload), null).getResult(false)
    }
    const payload = {
      MsAgenId: data.agentId,
      MsLabelId: data.labelId,
      Qty: Number(data.qty),
      CodePrefix: data.codePrefix?.toUpperCase(),
      CodeOffset: data.codeOffset
    }
    return new Repository(addStockLabelV2(payload), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteStockLabelV2(id), null).getResult(false)
  }

  tambahStockLabelAgent(id: any): Promise<Response> {
    return new Repository(generateLabelAgen(id), null).getResult(false)
  }

  getStockLabelAgent(id: any, params: any): Promise<Response> {
    // const params = `?isPicked=false&labelId=${labelId}`
    console.log('param', returnUrlPrams(params))
    return new Repository(fetchLabelStocks(id, returnUrlPrams(params)), null).getResult(false)
  }
}

const labelAgentUseCase = new LabelAgentUseCase()

export {
  labelAgentUseCase
}
