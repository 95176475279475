import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransStockSticker'

const fetchAllStockSticker = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneStockSticker = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addStockSticker = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editStockSticker = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteStockSticker = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const addStockStickerAgent = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/TambahStockSticker`, data)

export {
  fetchAllStockSticker,
  fetchOneStockSticker,
  addStockSticker,
  editStockSticker,
  deleteStockSticker,
  addStockStickerAgent
}
