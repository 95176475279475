/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import {
  fetchAllCompany,
  fetchOneCompany,
  fetchAllCompanyAddress,
  fetchOneCompanyAddress,
  addPengirimanHd,
  editPengirimanHd,
  fetchOnePelanggan,
  fetchAllPelanggan,
  fetchAllAsuransi,
  fetchMinimalInfoUsersCompany,
  fetchProfileUsersCompany,
  fetchListMinimalInfoUsersCompany,
  fetchUserCompanyAddressByUsername,
  fetchUserCompanyAddressById,
  fetchAllJenisPembayaran,
  fetchPickupScheduleCourier,
  fetchAllUsersV2,
  fetchOneUserV2
} from '@/data/source/remote/api'
import store from '@/store'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

class SenderDataUseCase {
  private store = store

  private modules = 'deliveryItem'

  async getSelectDataCompany(filter: any): Promise<Response> {
    // console.log('filter:', filter)
    // return new Repository(fetchAllCompany(params), null).getResult(false)
    return new Repository(fetchListMinimalInfoUsersCompany(returnUrlPrams(filter)), null).getResult(false)
  }

  public getSelectDataInsurance(filter: any): Promise<Response> {
    return new Repository(fetchAllAsuransi(filter), null).getResult(false)
  }

  public getSpecificCompany(filter: any): Promise<Response> {
    return new Repository(fetchOneCompany(filter), null).getResult(false)
  }

  public getSelectDataCustomer(filter: any): Promise<Response> {
    const params = `?$filter=IsActive eq true ${filter}`
    return new Repository(fetchAllPelanggan(params), null).getResult(false)
  }

  public getSelectDataCustomerV2(filter: any, pageNumber: any, search: any = ''): Promise<Response> {
    const params = `?groupUserName=pelanggan&pageNumber=${pageNumber}${search} ${filter}`
    return new Repository(fetchAllUsersV2(params), null).getResult(false)
  }

  public getSpecificCustomer(filter: any): Promise<Response> {
    return new Repository(fetchOnePelanggan(filter), null).getResult(false)
  }

  public getSpecificCustomerV2(filter: any): Promise<Response> {
    return new Repository(fetchOneUserV2(filter), null).getResult(false)
  }

  async getSelectCompanyAddress(filter: any) {
    // const params = `?$filter=IsActive eq true and CompanyId eq ${filter.companyId}`
    const params = `${filter.companyId}`
    return new Repository(fetchUserCompanyAddressByUsername(params), null).getResult(false)
  }

  public getSpecificCompanyAddress(filter: any): Promise<Response> {
    return new Repository(fetchUserCompanyAddressById(filter), null).getResult(false)
  }

  public getDefaultPaymentType(): Promise<Response> {
    const params = "?$filter=Attribute1 eq 'DEFAULT'"
    return new Repository(fetchAllJenisPembayaran(params), null).getResult(false)
  }

  public getOptionSchedule(): Promise<Response> {
    return new Repository(fetchPickupScheduleCourier(), null).getResult(false)
  }

  // eslint no-else-return: "error"
  public newSubmitData = async (data: any, id: any): Promise<Response> => {
    // console.log('newsubmit', data)
    if (id) {
      data.Id = Number(id)
      const response = await new Repository(editPengirimanHd(id, data), null).getResult(false)
      if (!response.error) {
        this.store.dispatch(`${this.modules}/setDataAfterPost`, response.result)
      }
      return response
    }
    const response = await new Repository(addPengirimanHd(data), null).getResult(false)
    // console.log(response.result)
    if (!response.error) {
      this.store.dispatch(`${this.modules}/setDataAfterPost`, response.result)
    }
    return response
  }
}

const senderDataUseCase = new SenderDataUseCase()

export {
  senderDataUseCase
}
