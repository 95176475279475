import payment from './payment'
import price from './price'
import route from './route'
import typeBus from './typeBus'
import voucherDiskon from './voucherDiskon'
import dateReceipt from './dateReceipt'
import comission from './comission'

export default () => {
  const getPrice = price('transaksi')
  const getPayment = payment('transaksi')
  const getRoute = route('transaksi')
  const getVoucherDiskon = voucherDiskon('transaksi')
  const getTypeBus = typeBus('transaksi')
  const getDateReceipt = dateReceipt('transaksi')
  const getComission = comission('transaksi')
  return [
    ...getPayment,
    ...getPrice,
    ...getRoute,
    ...getTypeBus,
    ...getDateReceipt,
    ...getVoucherDiskon,
    ...getComission
  ]
}
