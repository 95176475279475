import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsSticker'

const fetchAllSticker = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneSticker = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addSticker = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editSticker = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteSticker = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveSticker = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllSticker,
  fetchOneSticker,
  addSticker,
  editSticker,
  deleteSticker,
  changeIsActiveSticker
}
