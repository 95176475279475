import { GetterTree } from 'vuex'
import { RootState } from './types'

const getters: GetterTree<RootState, RootState> = {
  getAuthenticated: (state) => state.authenticated,
  getIsAuthen: (state) => state.authenticated.isAuthen,
  getDateAuthen: (state) => state.authenticated.dateAuthen,
  getConnectionNetwork: (state) => state.network.connection,
  getConnectionTypeNetwork: (state) => state.network.connectionType,
  getNoInternetModalNetwork: (state) => state.network.noInternetModal,
  getPlatform: (state) => state.device.platform,
  getIsWeb: (state) => state.device.isWeb,
  getIsMobile: (state) => state.device.isMobile,
  getIsIos: (state) => state.device.isIos,
  getIsAndroid: (state) => state.device.isAndroid,
  getShowLoader: (state) => state.loading.show,
  getToggleMenu: (state) => state.toggleMenu,
  getOpenTicketIsAuthen: (state) => state.openTicket.isAuthenticated,
  getOpenTicketData: (state) => state.openTicket,
}

export default getters
