import { ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

export default {
  props: ['data'],
  setup(props: any) {
    const item = toRefs(props).data
    const store = useStore()
    const toAgentId = ref(store.getters['appActiveUser/getAgent']?.id)

    return {
      item,
      toAgentId,
      moment
    }
  }
}
