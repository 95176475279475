import report from './report'
import reportSetting from './reportSetting'
import receiptChecking from './receiptChecking'
import courierPricelist from './courierPricelist'
import dashboard from './dashboard'
import dashboardKurir from './dashboardKurir'

export default () => {
  const getReport = report('reporting-data')
  const getReportSetting = reportSetting('report-setting')
  const getReceiptChecking = receiptChecking('receipt-checking')
  const getCourierPricelist = courierPricelist('courier-pricelist')
  const getDashboard = dashboard('dashboard')
  const getDashboardCourier = dashboardKurir('courier-dashboard')

  return [
    ...getDashboard,
    ...getReport,
    ...getReportSetting,
    ...getReceiptChecking,
    ...getCourierPricelist,
    ...getDashboardCourier
  ]
}
