import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsHargaDetail'

const fetchAllHargaDetail = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneHargaDetail = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addHargaDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editHargaDetail = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteHargaDetail = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const addMultipleHargaDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Multiple`, data)
const editAltMultipleHargaDetail = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/AltMultiple`, data)
const addAltMultipleHargaDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/AltMultiple`, data)
const addDuplicateHargaKategori = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/DuplicateKategori`, data)
const deleteHargaKategori = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/DeleteKategori`, data)

export {
  fetchAllHargaDetail,
  fetchOneHargaDetail,
  addHargaDetail,
  editHargaDetail,
  deleteHargaDetail,
  addMultipleHargaDetail,
  editAltMultipleHargaDetail,
  addAltMultipleHargaDetail,
  addDuplicateHargaKategori,
  deleteHargaKategori
}