import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsTanggalPenerimaan'

const fetchAllTanggalPenerimaan = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneTanggalPenerimaan = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addTanggalPenerimaan = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editTanggalPenerimaan = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteTanggalPenerimaan = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveTanggalPenerimaan = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllTanggalPenerimaan,
  fetchOneTanggalPenerimaan,
  addTanggalPenerimaan,
  editTanggalPenerimaan,
  changeIsActiveTanggalPenerimaan,
  deleteTanggalPenerimaan
}
