import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/transstocklabels'

const fetchAllStockLabelV2 = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneStockLabelV2 = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addStockLabelV2 = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editStockLabelV2 = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteStockLabelV2 = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const transferLabelV2 = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/transfer`, data)

export {
  fetchAllStockLabelV2,
  fetchOneStockLabelV2,
  addStockLabelV2,
  editStockLabelV2,
  deleteStockLabelV2,
  transferLabelV2
}
