import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = 'penalty/v1/penalties'

const fetchAllPenalties = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchPenaltyPayment = (id: any, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}/payments${filter}`)
const fetchPenaltyReferences = (id: any, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}/references${filter}`)
const addPenalty = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const approvePenalty = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/approve`, data)
const rejectPenalty = (id: number) => new Remote(remoteEnum.put, `${baseUrl}/${id}/reject`)

export {
  fetchAllPenalties,
  fetchPenaltyPayment,
  fetchPenaltyReferences,
  addPenalty,
  approvePenalty,
  rejectPenalty
}
