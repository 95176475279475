import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/identity/v2/profile'

const fetchUserProfileV2 = (username: string) => new Remote(remoteEnum.get, `${baseUrl}/${username}`)
const fetchProfileV2 = () => new Remote(remoteEnum.get, `${baseUrl}`)

export {
  fetchUserProfileV2,
  fetchProfileV2
}
