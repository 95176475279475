import manifestDetailState from './manifestDetailState'
import manifestDetailAction from './manifestDetailAction'
import manifestDetailGetters from './manifestDetailGetters'
import manifestDetailMutations from './manifestDetailMutations'

export default {
  namespaced: true,
  state: manifestDetailState,
  actions: manifestDetailAction,
  getters: manifestDetailGetters,
  mutations: manifestDetailMutations
}
