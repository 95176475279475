import iconDependency from '@/dependencies/iconDependency'
import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}`,
      name: 'receipt-checking',
      component: () => import('@/views/home/receipt-checking/ReceiptChecking.vue'),
      meta: {
        group: 'Pengecekan Resi',
        permission
      }
    },
    {
      path: `${val}/:id`,
      name: 'receipt-checking-params',
      component: () => import('@/views/home/receipt-checking/ReceiptChecking.vue'),
      meta: {
        group: 'Pengecekan Resi',
        permission
      }
    },
    {
      path: `${val}/detail/:id`,
      name: 'receipt-checking-detail',
      component: () => import('@/views/home/receipt-checking/detail/ReceiptCheckingDetail.vue'),
      meta: {
        group: 'Pengecekan Resi',
        breadcrumb: [
          { title: 'Pengecekan Resi', icon: icon.fileText, routeName: 'receipt-checking' },
          { title: 'Pengecekan Resi Detail', icon: null, routeName: 'receipt-checking-detail' },
        ],
        permission
      }
    }
  ]

  return listRoute
}
