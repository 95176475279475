/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllCompanyAddress,
  fetchOneCompanyAddress,
  addCompanyAddress,
  editCompanyAddress,
  deleteCompanyAddress,
  changeIsActiveCompanyAddress,
  addUserCompanyAddress
} from '@/data/source/remote/api'

class CompanyAddressUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveCompanyAddress(data), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    return new Repository(fetchAllCompanyAddress(`?$count=true&$top=${filter.top}&$skip=${filter.skip}&$expand=Kota($select=Id,Nama)&$filter=CompanyId eq ${filter.idCompany} and (Contains(tolower(Alias),'${filter.search}') or Contains(tolower(Alamat),'${filter.search}') or Contains(tolower(Penerima),'${filter.search}'))`), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== undefined) {
      const response = await new Repository(fetchOneCompanyAddress(id), null).getResult(false)
      return {
        title: 'Edit Alamat Pengiriman',
        data: response
      }
    }

    return {
      title: 'Tambah Alamat Pengiriman',
      data: null
    }
  }

  submitData(username: any = null, data: any): Promise<Response> {
    if (username !== null) {
      // data.Id = Number(id)
      // return new Repository(editCompanyAddress(id, data), null).getResult(false)
    } return new Repository(addUserCompanyAddress(username, data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteCompanyAddress(id), null).getResult(false)
  }
}

const customerCompanyAddressUseCase = new CompanyAddressUseCase()

export {
  customerCompanyAddressUseCase
}
