/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllKomisi, addKomisiArray, editKomisiArray, deleteKomisi
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

class ComissionUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      const query = []
      if (filter.expand) {
        query.push(`$expand=${filter.expand}`)
      }
      if (filter.select) {
        query.push(`$select=${filter.select}`)
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Nama), '${filter.search.toLowerCase()}'))`)
      }

      if (arrFilter.length > 0) {
        query.push(`$filter=${arrFilter.join(' and ')}`)
      }

      if (query.length > 0) {
        params = `${params}&${query.join('&')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    }
    return new Repository(fetchAllKomisi(params), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id) {
      data.Id = id
      return new Repository(editKomisiArray(id, data), null).getResult(false)
    }
    return new Repository(addKomisiArray(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteKomisi(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const comissionUseCase = new ComissionUseCase()

export {
  comissionUseCase
}