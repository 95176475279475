import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TPayloadPrice } from './prices-model'

const baseUrl = '/backend/v2/prices'

const fetchAllPrices = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchSinglePrice = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchSinglePriceChilds = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/childs`)
const fetchSinglePriceGoodCategory = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/goodcategories`)
const addPrice = (data: TPayloadPrice) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPrice = (id: number, data: TPayloadPrice) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteSinglePriceGoodCategory = (id: number, goodCategoryId: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}/goodCategories/${goodCategoryId}`)

export {
  fetchAllPrices,
  fetchSinglePrice,
  fetchSinglePriceChilds,
  fetchSinglePriceGoodCategory,
  addPrice,
  editPrice,
  deleteSinglePriceGoodCategory
}
