import { Remote, remoteEnum } from '@/data/source/remote/remoteCompany'

const baseUrl = 'MsCutoffs'

const fetchAllMsCutoffs = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneMsCutoffs = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchOneDetailsMsCutoffs = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addMsCutoffs = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editMsCutoffs = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteMsCutoffs = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveMsCutoffs = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
// const generateCutOff = (data: any) => new Remote(remoteEnum.get, `${baseUrl}/Generate${data}`)
const generateCutOff = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/ManualCutoff/${id}`)

export {
  fetchAllMsCutoffs,
  fetchOneMsCutoffs,
  addMsCutoffs,
  editMsCutoffs,
  deleteMsCutoffs,
  changeIsActiveMsCutoffs,
  fetchOneDetailsMsCutoffs,
  generateCutOff
}
