import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TPayloadMsAgentTypesSave } from './msAgentTypes-model'

const baseUrl = '/backend/v2/msagenttypes'

const fetchAgentTypesAll = (filters = '') => new Remote(remoteEnum.get, `${baseUrl}${filters}`)
const fetchAgentTypesSingle = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addAgentTypes = (data: TPayloadMsAgentTypesSave) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const updateAgentTypes = (id: number, data: TPayloadMsAgentTypesSave) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteAgentTypes = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAgentTypesAll,
  fetchAgentTypesSingle,
  addAgentTypes,
  updateAgentTypes,
  deleteAgentTypes
}
