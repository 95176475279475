import { App } from 'vue'
import PaginatorPage from '@/views/shared-components/paginator/PaginatorPage.vue'
import PaginatorUrl from '@/views/shared-components/paginator/PaginatorUrl.vue'

export default {
  install: (app: App<Element>) => {
    app.component('PaginatorPage', PaginatorPage)
    app.component('PaginatorUrl', PaginatorUrl)
  }
}
