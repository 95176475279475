/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllLabelV2, changeIsActiveLabelV2, fetchOneLabelV2, editLabelV2, addLabelV2, deleteLabelV2
} from '@/data/source/remote/api'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

class LabelUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    const id = data.Id
    const payload = [{
      op: 'replace',
      path: '/isActive',
      value: data.IsActive
    }]
    return new Repository(changeIsActiveLabelV2(id, payload), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    return new Repository(fetchAllLabelV2(returnUrlPrams(filter)), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneLabelV2(id), null).getResult(false)
      return {
        title: 'Edit Label',
        data: response
      }
    }

    return {
      title: 'Tambah Label',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id

      return new Repository(editLabelV2(id, data), null).getResult(false)
    }

    data = {
      Nama: data.Nama,
      Kode: data.Kode,
      Warna: data.Warna,
      Keterangan: data.Keterangan
    }

    return new Repository(addLabelV2(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteLabelV2(id), null).getResult(false)
  }
}

const labelUseCase = new LabelUseCase()

export {
  labelUseCase
}
