/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import Repository from '@/data/repository'
import {
  fetchAllPengirimanHd,
  fetchOnePengirimanHd,
  ubahJadwalPending,
  refund
} from '@/data/source/remote/api'

class DeliveryPendingUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Resi), '${filter.search.toLowerCase()}')) `)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    }
    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }

  getDataForm(id: any, filter: any = ''): Promise<any> {
    let params = '?'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
    }
    return new Repository(fetchOnePengirimanHd(id, params), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeScheduleDelivery(data: any): Promise<Response> {
    return new Repository(ubahJadwalPending(data), null).getResult(false)
  }

  refundPengiriman(id: any, data: any): Promise<Response> {
    return new Repository(refund(id, data), null).getResult(false)
  }
}

const deliveryPendingUseCase = new DeliveryPendingUseCase()

export {
  deliveryPendingUseCase
}