import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransJadwalDetail'

const fetchAllJadwalDetail = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneJadwalDetail = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addJadwalDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const addJadwalDetailMultiple = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Multiple`, data)
const editJadwalDetail = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteJadwalDetail = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllJadwalDetail,
  fetchOneJadwalDetail,
  addJadwalDetail,
  editJadwalDetail,
  deleteJadwalDetail,
  addJadwalDetailMultiple
}
