import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  if (checkPermissionObj(permission)) {
    listRoute.push({
      path: `${val}/label`,
      name: 'master-label',
      component: () => import('@/views/master/items/label/Label.vue'),
      meta: {
        group: 'BARANG',
        permission
      }
    })
  }
  return listRoute
}
