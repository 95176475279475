import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsStatusPengecekanResi'

const fetchAllPengecekanResi = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOnePengecekanResi = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addPengecekanResi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPengecekanResi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deletePengecekanResi = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllPengecekanResi,
  fetchOnePengecekanResi,
  addPengecekanResi,
  editPengecekanResi,
  deletePengecekanResi
}
