import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsCompanyAddress'

const fetchAllCompanyAddress = (filter : any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneCompanyAddress = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addCompanyAddress = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editCompanyAddress = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteCompanyAddress = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveCompanyAddress = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllCompanyAddress,
  fetchOneCompanyAddress,
  addCompanyAddress,
  editCompanyAddress,
  deleteCompanyAddress,
  changeIsActiveCompanyAddress
}
