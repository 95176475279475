/* eslint-disable class-methods-use-this */
// import networkBoundResource from '@/data/networkBoundResource'
import store from '@/store'
import { Response } from '@/domain/entities'

class RepositoryV2<TResult = any> {
  remote: any

  sourceDb: any

  savingData: any

  constructor(remote: any, sourceDb: any, savingData: any = null) {
    this.remote = remote
    this.sourceDb = sourceDb
    if (savingData) {
      this.savingData = savingData
    }
  }

  message(e: any) {
    if (e.response.data.Header && e.response.data.Detail) {
      return `${e.response.data.Header} ${e.response.data.Detail}`
    }
    if (e.response.data?.title || e.response.data?.detail) {
      return `${e.response.data.detail}`
    }
    return 'Gagal! Silahkan hubungi Administrator'
  }

  private result = async (haveLocal: boolean) => {
    const data = new Response<TResult>()
    if (store.state.network.connection) {
      try {
        const fetch = await this.fetchData().run()
        const code = fetch.status
        const result = fetch.data
        if (code === 200) {
          if (result?.Data && Array.isArray(result.Data)) {
            data.result = result.Data
            data.count = result.Count
            data.pageNumber = result.PageNumber
            data.pageSize = result.PageSize
            data.totalPages = result.TotalPages
          } else {
            data.result = result
          }
        }
        if (result?.Detail) {
          data.message = `${result.Detail}`
        }
        data.code = code
      } catch (e: any) {
        if (typeof e.response === 'undefined') {
          data.error = true
          data.message = 'A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.'
          // return Promise.reject(e)
          return data
        }
        data.error = true
        data.code = e.response.status
        data.message = this.message(e)
      }
      if (haveLocal) {
        this.saveResultData(data)
      }
    } else if (haveLocal) {
      data.result = await this.loadFromDB()
    } else {
      store.dispatch('setNoInternet', true)
    }

    return data
  }

  loadFromDB() {
    return this.sourceDb
  }

  fetchData() {
    return this.remote
  }

  saveResultData(data: any): void {
    this.sourceDb.changeData(data)
    this.loadFromDB().run()
    console.log('save result data', data)
  }

  getResult = async (haveLocal: boolean): Promise<Response<TResult>> => {
    const data = await this.result(haveLocal)
    return data
  }
}

export default RepositoryV2
