/* eslint-disable class-methods-use-this */
import {
  fetchAgentTypesAll,
  fetchAgentTypesSingle,
  deleteAgentTypes,
  updateAgentTypes,
  addAgentTypes
} from '@/data/source/remote/api'
import RepositoryV2 from '@/data/repositoryV2'
import returnUrlPrams, { IFilterPrams } from '@/utils/helpers/returnUrlPrams'
import { Response } from '@/domain/entities'
import type {
  TMsAgentTypes,
  TMsAgentTypesDetail,
  TPayloadMsAgentTypesSave
} from '@/data/source/remote/api/backend/V2/msAgentTypes-model'

class AgentTypeUseCase {
  getAll(filters: Partial<IFilterPrams> = {}): Promise<Response<Array<TMsAgentTypes>>> {
    const params = returnUrlPrams(filters)
    return new RepositoryV2(fetchAgentTypesAll(params), null).getResult(false)
  }

  getDetail(id: number): Promise<Response<TMsAgentTypesDetail>> {
    return new RepositoryV2(fetchAgentTypesSingle(id), null).getResult(false)
  }

  delete(id: number) {
    return new RepositoryV2(deleteAgentTypes(id), null).getResult(false)
  }

  saveData(id: number | null = null, payload: TPayloadMsAgentTypesSave) {
    if (id) {
      return new RepositoryV2(updateAgentTypes(id, payload), null).getResult(false)
    } return new RepositoryV2(addAgentTypes(payload), null).getResult(false)
  }
}

export const agentTypeUseCase = new AgentTypeUseCase()
