/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllAsuransi, addAsuransi, editAsuransi, changeIsActiveAsuransi
} from '@/data/source/remote/api/master'

class InsuranceUsecase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    const params = (filter.search) ? `?$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Alamat), '${filter.search}') or Contains(tolower(Telepon), '${filter.search}'))` : ''
    const response = new Repository(fetchAllAsuransi(params), null).getResult(false)
    return response
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      return new Repository(editAsuransi(id, data), null).getResult(false)
    }
    return new Repository(addAsuransi(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveAsuransi(data), null).getResult(false)
  }
}

const insuranceUseCase = new InsuranceUsecase()

export {
  insuranceUseCase
}
