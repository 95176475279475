import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsStatusPengecekanResi'

const fetchAllStatusResi = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneStatusResi = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addStatusResi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editStatusResi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteStatusResi = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveStatusResi = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllStatusResi,
  fetchOneStatusResi,
  addStatusResi,
  editStatusResi,
  deleteStatusResi,
  changeIsActiveStatusResi
}
