import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsAgen'

const fetchAgen = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)

const fetchAllAgen = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)

const fetchOneAgen = (id: number, query = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${query}`)

const addAgen = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

const editAgen = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

const deleteAgen = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

const changeIsActiveAgent = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

const qrProfileAgent = () => new Remote(remoteEnum.get, `${baseUrl}/QrProfile`)

const addKuotaAgen = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/Kuota/${id}`, data)

const fetchPickupScheduleCourier = () => new Remote(remoteEnum.get, `${baseUrl}/JadwalPenjemputanKurir`)

export {
  fetchAgen,
  fetchAllAgen,
  fetchOneAgen,
  addAgen,
  editAgen,
  changeIsActiveAgent,
  deleteAgen,
  qrProfileAgent,
  addKuotaAgen,
  fetchPickupScheduleCourier
}
