import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  // if (checkPermission()) {
  listRoute.push({
    path: `${val}/:type/tambah`,
    name: 'transaksi-pengiriman-barang-tambah',
    component: () => import('@/views/transactions/delivery-item/create/CreateDeliveryItem.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      breadcrumbFromParams: 'type',
      breadcrumb: [
        { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Jenis Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Tambah Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang-tambah' }
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      },
      noSpaceHorizontal: true,
    }
  })
  // }

  listRoute.push({
    path: `${val}`,
    name: 'transaksi-pengiriman-barang',
    component: () => import('@/views/transactions/delivery-item/delivery-item/index.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      breadcrumb: [
        { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: '' },
        { title: 'Buat Pengiriman Baru', icon: null, routeName: 'transaksi-pengiriman-barang' },
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })

  listRoute.push({
    path: `${val}/:type/edit/:id`,
    name: 'transaksi-pengiriman-barang-edit',
    component: () => import('@/views/transactions/delivery-item/create/CreateDeliveryItem.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      breadcrumbFromParams: 'type',
      breadcrumb: [
        { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Jenis Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Tambah Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang-tambah' }
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      },
      noSpaceHorizontal: true,
    }
  })

  listRoute.push({
    path: `${val}/report/:Id`,
    name: 'report-transaksi-pengiriman-barang',
    component: () => import('@/views/pages/Report.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      breadcrumbFromParams: 'type',
      breadcrumb: [
        { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Jenis Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang' },
        { title: 'Tambah Pengiriman', icon: null, routeName: 'transaksi-pengiriman-barang-tambah' }
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })

  return listRoute
}
