/* eslint-disable */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import { fetchOneTransUserPengirimans, assignJadwal, assignLabelAndSticker, ubahStatusPengiriman, changeArriveTransPengirimanHdScans, itemToManifest, fetchAllPengirimanHd } from '@/data/source/remote/api'
import Repository from '@/data/repository'

class DepartureProcessUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getOne(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any, filter: any = ''): Promise<Response> {
    let params = '?'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.custom) {
        arrFilter.push(filter.custom)
      }
      if (filter.search) {
        arrFilter.push(`${filter.search}`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderby=${filter.sorting}` : params
    }
    return new Repository(fetchOneTransUserPengirimans(id, params), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  assignSchedule(data: any): Promise<Response> {
    return new Repository(assignJadwal(data), null).getResult(false)
  }

  assignLabelsAndStickers(data: any): Promise<Response> {
    return new Repository(assignLabelAndSticker(data), null).getResult(false)
  }

  changeStatusDelivery(data: any): Promise<Response> {
    return new Repository(changeArriveTransPengirimanHdScans(data), null).getResult(false)
  }

  toManifest(data: any): Promise<Response> {
    return new Repository(itemToManifest(data), null).getResult(false)
  }

  getDetailResi(id: any = ''): Promise<Response> {
    let params = '?'
    if (id) {
      console.log([typeof(id), id])
      const filter = typeof(id) === 'string' ? `$filter=Guid eq ${id}` : `$filter=Id eq ${id}`
      const expand = '$expand=AgenAsal($expand=Kota),AgenTujuan($expand=Kota),StatusPengiriman,TransPengirimanHdBiayaTambahans($expand=MsBiayaTambahan),TransPengirimanDetails($expand=KategoriBarang($expand=Satuan))'

      params = `${params}&${filter}&${expand}`
    }
    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }
}

const departureProcessUseCase = new DepartureProcessUseCase()

export {
  departureProcessUseCase
}
