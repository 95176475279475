import monitoringPackage from './monitoring-package'
import departureProcess from './departure-process'

const prefix = 'package-order'

export default () => {
  const getMonitoringPackage = monitoringPackage(prefix)
  const getDepartureProcess = departureProcess(prefix)

  return [
    ...getMonitoringPackage,
    ...getDepartureProcess
  ]
}
