import {
  ActionTree,
  GetterTree,
  MutationTree
} from 'vuex'
import { RootState } from '@/store/types'
import { globalSettingUseCase } from '@/domain/usecase'
import { cloneDeep, forEach } from 'lodash'
import {
  TResultGlobalSettings
} from '@/data/source/remote/api/setting/V1/globalSettings-modal'
import { TGlobalSetting } from './types'

const filterGlobalSettingByCode = (list: Array<TResultGlobalSettings>, code: string) => {
  const tmpList = cloneDeep(list)
  const objSetting = tmpList.find((x) => x.Code === code)
  let returnObj = null
  if (objSetting) {
    returnObj = {
      description: objSetting.Description,
      code: objSetting.Code,
      name: objSetting.Name,
      value: objSetting.Value
    }
  }
  return returnObj
}

const states: Array<TGlobalSetting> = []

const mutations: MutationTree<Array<TGlobalSetting>> = {
  SET_GLOBAL_SETTING: (state, payload: Array<TResultGlobalSettings>) => {
    const globalSettings = [
      filterGlobalSettingByCode(payload, 'MaxDiskonPelanggan'),
      filterGlobalSettingByCode(payload, 'DigitNomor'),
      filterGlobalSettingByCode(payload, 'ToleransiKeberangkatan'),
      filterGlobalSettingByCode(payload, 'MaxBatasPembayaranDeposit')
    ] as Array<TGlobalSetting>
    const newState = globalSettings
    state.push(...newState)
  },
  SET_GLOBAL_SETTING_DEFAULT: (state) => {
    state = []
  }
}

const actions: ActionTree<Array<TGlobalSetting>, RootState> = {
  setGlobalSetting: async ({ commit }) => {
    try {
      const {
        error,
        result
      } = await globalSettingUseCase.getAll()
      if (!error) {
        commit('SET_GLOBAL_SETTING_DEFAULT')
        commit('SET_GLOBAL_SETTING', result)
      }
    } catch (error) {
      console.log('err set global setting')
    }
  }
}

const getters: GetterTree<Array<TGlobalSetting>, RootState> = {
  maxCustomerDiscount: (state) => state.find((v) => v.code === 'MaxDiskonPelanggan'),
  digitNumber: (state) => state.find((v) => v.code === 'DigitNomor'),
  departureTolerence: (state) => state.find((v) => v.code === 'ToleransiKeberangkatan'),
  maxPaymentDepositLimit: (state) => state.find((v) => v.code === 'MaxBatasPembayaranDeposit')
}

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
  getters
}
