/* eslint-disable no-underscore-dangle */
class Response<TResult = any> {
  private _error: boolean

  private _message: string

  private _code: number

  private _result: any

  private _count: number

  private _pageNumber: number

  private _pageSize: number

  private _totalPages: number

  constructor() {
    this._error = false
    this._message = 'ok'
    this._code = 200
    this._result = null
    this._count = 0
    this._pageNumber = 0
    this._pageSize = 0
    this._totalPages = 0
  }

  set error(err: boolean) {
    this._error = err
  }

  get error(): boolean {
    return this._error
  }

  set message(msg: string) {
    this._message = msg
  }

  get message(): string {
    return this._message
  }

  set code(code: number) {
    this._code = code
  }

  get code(): number {
    return this._code
  }

  set result(result: TResult) {
    this._result = result
  }

  get result(): TResult {
    return this._result
  }

  set count(count: number) {
    this._count = count
  }

  get count(): number {
    return this._count
  }

  set pageNumber(pageNumber: number) {
    this._pageNumber = pageNumber
  }

  get pageNumber(): number {
    return this._pageNumber
  }

  set pageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  get pageSize(): number {
    return this._pageSize
  }

  set totalPages(totalPages: number) {
    this._totalPages = totalPages
  }

  get totalPages(): number {
    return this._totalPages
  }
}

export {
  Response
}
