/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchFilterDatePayout,
  fetchAllDataPayoutKomisi,
  confirmedPayout,
  fetchOnePayoutKomisi
} from '@/data/source/remote/api'

class ComissionConfirmationUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    // return new Repository(changeIsActiveAbsensiCourier, createAbsensiKey(data), null).getResult(false)
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Username),'${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllDataPayoutKomisi(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getCustomPayout(id: any, filter: any = {}): Promise<Response> {
    let params
    if (filter.custom) {
      params = filter.custom
    }
    return new Repository(fetchOnePayoutKomisi(id, params), null).getResult(false)
  }

  getFilterDatePayout(): Promise<Response> {
    return new Repository(fetchFilterDatePayout(), null).getResult(false)
  }

  submitConfirmedPayout(data: any): Promise<Response> {
    return new Repository(confirmedPayout(data), null).getResult(false)
  }
}

const comissionConfirmationUseCase = new ComissionConfirmationUseCase()

export {
  comissionConfirmationUseCase
}
