/* eslint-disable class-methods-use-this */
import Repository from '@/data/repository'
import {
  checkVoucherDiskon, fetchAllTransPengirimanDetail, checkVoucher, validateVoucher
} from '@/data/source/remote/api'

class ListItemsReceiptUseCase {
  getAllData(id: any) {
    return new Repository(fetchAllTransPengirimanDetail(`?$filter=PengirimanHdId eq ${id}`), null).getResult(false)
  }

  getDataByAgenAsal(params: any) {
    let query = '$count=true'
    if (params.filters) {
      const filters = ['$filter=', params.filters.join(' and ')].join('')
      query = `${query}&${filters}`
    }
    if (params.select) {
      const $select = `$select=${params.select}`
      query = `${query}&${$select}`
    }
    return new Repository(fetchAllTransPengirimanDetail(`?${query}`), null).getResult(false)
  }

  checkVoucher(data: any) {
    // return new Repository(checkVoucherDiskon(data), null).getResult(false)
    // return new Repository(checkVoucher(data), null).getResult(false)
    return new Repository(validateVoucher(data), null).getResult(false)
  }
}

const listItemsReceiptUseCase = new ListItemsReceiptUseCase()

export {
  listItemsReceiptUseCase
}
