/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllSticker, fetchOneSticker, addSticker, editSticker, deleteSticker, changeIsActiveSticker
} from '@/data/source/remote/api'

class StickerUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveSticker(data), null).getResult(false)
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}') or Contains(tolower(Keterangan), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      if (filter.top) {
        params = `${params}&$top=${filter.top}`
      }
      if (filter.skip) {
        params = `${params}&$skip=${filter.skip}`
      }
    }
    return new Repository(fetchAllSticker(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneSticker(id), null).getResult(false)
      return {
        title: 'Edit Stiker',
        data: response
      }
    }

    return {
      title: 'Tambah Stiker',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = Number(id)
      return new Repository(editSticker(id, data), null).getResult(false)
    }
    return new Repository(addSticker(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteSticker(id), null).getResult(false)
  }
}

const stickerUseCase = new StickerUseCase()

export {
  stickerUseCase
}
