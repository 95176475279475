import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'

const baseUrl = 'MsJenisKendaraans'

const fetchAllJenisKendaraans = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneJenisKendaraans = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addJenisKendaraans = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editJenisKendaraans = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteJenisKendaraans = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveJenisKendaraans = (data: any, id: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive/${id}`, data)

export {
  fetchAllJenisKendaraans,
  fetchOneJenisKendaraans,
  addJenisKendaraans,
  editJenisKendaraans,
  deleteJenisKendaraans,
  changeIsActiveJenisKendaraans
}
