import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(PermissionEnum.CreateCity),
    // update: checkPermission(PermissionEnum.UpdateCity),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  // if (checkPermissionObj(permission) || checkPermission(PermissionEnum.CreateCityGroup)) {
  listRoute.push({
    path: `${val}/kota`,
    name: 'master-kota',
    component: () => import('@/views/master/trips/city/City.vue'),
    meta: {
      group: 'KOTA & AGEN',
      permission
    }
  })
  // }
  return listRoute
}
