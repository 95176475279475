import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import {
  checkPermission,
  checkPermissionObj
} from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.ListUsers),
    // write: checkPermission(PermissionEnum.ListUsers),
    // update: checkPermission(PermissionEnum.ListUsers),
    // delete: checkPermission(PermissionEnum.ListUsers)
    read: true,
    write: true,
    update: true,
    delete: true
  }

  if (checkPermissionObj(permission)) {
    listRoute.push({
      path: `${val}/user`,
      name: 'master-user',
      component: () => import('@/views/master/user-admin/user/UserV2.vue'),
      meta: {
        group: 'USER & ADMIN',
        permission
      }
    })

    if (permission.write) {
      listRoute.push({
        path: `${val}/user/tambah`,
        name: 'master-user-add',
        component: () => import('@/views/master/user-admin/user/UserFormV2.vue'),
        meta: {
          group: 'USER & ADMIN',
          breadcrumb: [
            { title: 'User & Admin', icon: icon.userBx, routeName: 'master-user' },
            { title: 'User', icon: null, routeName: 'master-user' },
            { title: 'Tambah User', icon: null, routeName: 'master-user-tambah' },
          ],
          permission
        }
      })
    }

    if (permission.update) {
      listRoute.push({
        path: `${val}/user/edit/:userId`,
        name: 'master-user-edit',
        component: () => import('@/views/master/user-admin/user/UserFormV2.vue'),
        meta: {
          group: 'USER & ADMIN',
          breadcrumb: [
            { title: 'User & Admin', icon: icon.userBx, routeName: 'master-user' },
            { title: 'User', icon: null, routeName: 'master-user' },
            { title: 'Edit User', icon: null, routeName: 'master-user-edit' },
          ],
          permission
        }
      })
    }
  }

  return listRoute
}
