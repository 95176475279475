import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsDynamicReportAuthen'

const fetchAllReportAuthenGroup = (queryString: any = '') => new Remote(remoteEnum.get, `${baseUrl}${queryString}`)
const addReportAuthenGroup = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/SetAuth`, data)

export {
  fetchAllReportAuthenGroup,
  addReportAuthenGroup
}
