/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllGroupUser,
  fetchOneGroupUser,
  addGroupUser,
  editGroupUser,
  editGroupUserAuthByGroupId,
  fetchGroupUserPermission,
  deleteSingleGroupUserPermission,
  fetchAllPermissions,
  addGroupUserPermission
} from '@/data/source/remote/api'
import returnUrlPrams, { IFilterPrams } from '@/utils/helpers/returnUrlPrams'
import {
  TResultGroupUser,
  TResultAllGroupUser,
  TResultGroupUserPermission,
  TPayloadAddUpdateGroupUser,
  TPayloadAddGroupUserPermission
} from '@/data/source/remote/api/identity/V2/msGroupUsers-model'
import RepositoryV2 from '@/data/repositoryV2'

class GroupUserUseCase implements ICrud {
  getAll(filter?: Partial<IFilterPrams>): Promise<Response<TResultAllGroupUser>> {
    const urlParams = returnUrlPrams(filter)
    return new Repository(fetchAllGroupUser(urlParams), null).getResult(false)
  }

  getAllGroupUser(filters: Partial<IFilterPrams>): Promise<Response<TResultGroupUser[]>> {
    const urlParams = returnUrlPrams(filters)
    return new RepositoryV2(fetchAllGroupUser(urlParams), null).getResult(false)
  }

  getDataForm(id: any): Promise<Response<TResultGroupUser>> {
    return new Repository(fetchOneGroupUser(id), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(editGroupUserAuthByGroupId(data), null).getResult(false)
  }

  submitDataForm(id: string, data: TPayloadAddUpdateGroupUser): Promise<Response> {
    if (id) {
      const payload = {
        ...data,
        Id: id
      }
      return new Repository(editGroupUser(id, payload), null).getResult(false)
    } return new Repository(addGroupUser(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getUserPermissions(id: any): Promise<Response<TResultGroupUserPermission[]>> {
    return new Repository(fetchGroupUserPermission(id), null).getResult(false)
  }

  getAllPermission(filter: Partial<IFilterPrams> = {}): Promise<Response<TResultGroupUserPermission[]>> {
    const urlParams = returnUrlPrams(filter)
    return new RepositoryV2(fetchAllPermissions(urlParams), null).getResult(false)
  }

  deleteSingleUserPermission(id: number, permissionId: number): Promise<Response> {
    return new RepositoryV2(deleteSingleGroupUserPermission(id, permissionId), null).getResult(false)
  }

  addGroupUserPermission(id: string, data: TPayloadAddGroupUserPermission): Promise<Response> {
    return new RepositoryV2(addGroupUserPermission(id, data), null).getResult(false)
  }
}

const groupUserUseCase = new GroupUserUseCase()

export {
  groupUserUseCase
}
