/* eslint-disable import/no-unresolved */
import { StoreOptions, createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import stateRoot from './state'
import mutationsRoot from './mutations'
import actionsRoot from './actions'
import gettersRoot from './getters'
import dashboard from './modules/dashboard'
import deliveryItem from './modules/transactions/delivery-item/deliveryItemIndex'
import manifestDetail from './modules/transactions/manifest-detail/manifestDetailIndex'
import voucher from './modules/voucher/voucherIndex'
import kurir from './modules/courier/courierIndex'
import appActiveUser from './modules/appActiveUser'
import { RootState } from './types'
import globalSetting from './modules/globalSetting'

const ls = new SecureLS({ encodingType: 'aes', isCompression: false, encryptionSecret: 'H4r4p4nJ@y@3x-2021' })

const store: StoreOptions<RootState> = {
  state: stateRoot,
  getters: gettersRoot,
  mutations: mutationsRoot,
  actions: actionsRoot,
  plugins: [createPersistedState({
    key: 'MG-HJEX',
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  modules: {
    deliveryItem,
    dashboard,
    voucher,
    kurir,
    manifestDetail,
    appActiveUser,
    globalSetting
  }
}

export default createStore(store)
