import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsCompany'

const fetchAllCompany = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneCompany = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addCompany = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editCompany = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteCompany = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveCompany = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllCompany,
  fetchOneCompany,
  addCompany,
  editCompany,
  deleteCompany,
  changeIsActiveCompany
}
