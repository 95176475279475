import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'PayoutKomisi'

const fetchAllDataPayoutKomisi = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchFilterDatePayout = () => new Remote(remoteEnum.get, `${baseUrl}/DateFilters`)
const confirmedPayout = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Confirm`, data)
const fetchOnePayoutKomisi = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const approvePayout = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Approve`, data)
const manualPayout = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Manual`, data)

export {
  fetchFilterDatePayout,
  fetchAllDataPayoutKomisi,
  confirmedPayout,
  fetchOnePayoutKomisi,
  approvePayout,
  manualPayout
}
