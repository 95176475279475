import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsGlobalSettingOption'

const fetchGlobalSettingOption = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)

const fetchOneGlobalSettingOption = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)

const addGlobalSettingOption = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

const editGlobalSettingOption = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

const deleteGlobalSettingOption = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchGlobalSettingOption,
  fetchOneGlobalSettingOption,
  addGlobalSettingOption,
  editGlobalSettingOption,
  deleteGlobalSettingOption
}
