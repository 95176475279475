import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(PermissionEnum.ListPrices),
    // write: checkPermission(PermissionEnum.CreatePrice),
    // update: checkPermission(PermissionEnum.UpdatePrice),
    // delete: checkPermission(PermissionEnum.UpdatePrice)
    read: true,
    write: true,
    update: true,
    delete: true
  }

  let listRoute: any = []

  if (checkPermissionObj(permission)) {
    listRoute = [
      {
        path: `${val}/harga`,
        name: 'master-harga',
        component: () => import('@/views/master/transactions/price/PriceIndex.vue'),
        meta: {
          group: 'TRANSAKSI',
          permission
        }
      },
      {
        path: `${val}/harga/:id`,
        name: 'master-harga-detail',
        component: () => import('@/views/master/transactions/price/price-detail/PriceDetail.vue'),
        meta: {
          group: 'TRANSAKSI',
          breadcrumb: [
            { title: 'Transaksi', icon: icon.moneyAttachRoundIc, routeName: 'master-harga' },
            { title: 'Harga', icon: null, routeName: 'master-harga' },
            { title: 'Detail Harga', icon: null, routeName: 'master-harga-detail' },
          ],
          permission: {
            ...permission,
            read: checkPermission(PermissionEnum.GetPriceDetail),
            update: checkPermission(PermissionEnum.ModifyPriceDetail)
          }
        }
      }
    ]
  }

  return listRoute
}
