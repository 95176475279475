import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  // if (checkPermission('*:*')) {
  listRoute.push({
    path: `${val}/agent`,
    name: 'master-agent',
    component: () => import('@/views/master/trips/agent/Agent.vue'),
    meta: {
      group: 'KOTA & AGEN',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })
  // }
  // if (checkPermission('*:*')) {
  listRoute.push({
    path: `${val}/agent/tambah`,
    name: 'master-agent-tambah',
    component: () => import('@/views/master/trips/agent/AgentForm.vue'),
    meta: {
      group: 'KOTA & AGEN',
      breadcrumb: [
        { title: 'Perjalanan', icon: icon.placeOutlineIc, routeName: 'master-agent' },
        { title: 'Agen', icon: null, routeName: 'master-agent' },
        { title: 'Tambah Agen', icon: null, routeName: 'master-agent-tambah' },
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })
  // }
  // if (checkPermission('*:*')) {
  listRoute.push({
    path: `${val}/agent/edit/:agentId`,
    name: 'master-agent-edit',
    component: () => import('@/views/master/trips/agent/AgentForm.vue'),
    meta: {
      group: 'KOTA & AGEN',
      breadcrumb: [
        { title: 'Perjalanan', icon: icon.placeOutlineIc, routeName: 'master-agent' },
        { title: 'Agen', icon: null, routeName: 'master-agent' },
        { title: 'Edit Agen', icon: null, routeName: 'master-agent-edit' },
      ],
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })
  // }
  return listRoute
}
