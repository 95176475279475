/* eslint-disable import/no-unresolved */
import {
  ActionTree,
  GetterTree,
  MutationTree
} from 'vuex'
import { authUseCase } from '@/domain/usecase'
import { RootState } from '@/store/types'
import { IResultFetchUserInfo } from '@/data/source/remote/api/auth/userinfo-model'
import { IResultAgentInfo } from '@/data/source/remote/api/backend/V2/dashboard-model'
import { TResultAgentSingle } from '@/data/source/remote/api/backend/V2/msAgens-model'
import { TResultProfile } from '@/data/source/remote/api/identity/V2/profile-model'
import {
  TAppActiveUser,
  TAgentAttr
} from './types'

const agentAttrDefault: TAgentAttr = {
  id: 0,
  guid: '',
  kotaId: 0,
  kotaCode: '',
  kotaName: '',
  code: '',
  name: '',
  hp: '',
  address: '',
  canPickup: false,
  canDrop: false,
  isLuarPulau: false,
  isKurirDooring: false,
  tipeAgenId: 0,
  tipeAgenType: '',
  tipeAgenName: '',
  isActive: false,
  latitude: null,
  longitude: null,
  cityGroupId: null
}

const states: TAppActiveUser = {
  authTime: 0,
  birthdate: null,
  email: '',
  emailVerified: false,
  gender: '',
  id: null,
  guid: '',
  fullname: '',
  permissions: [],
  phoneNumber: '',
  phoneNumberVerified: false,
  picture: '',
  role: '',
  roleId: 0,
  roleName: '',
  sub: '',
  agent: agentAttrDefault
}

const mutations: MutationTree<TAppActiveUser> = {
  SET_DEFAULT_APP_ACTIVE_USER: (state) => {
    state.authTime = 0
    state.birthdate = null
    state.email = ''
    state.emailVerified = false
    state.gender = ''
    state.id = null
    state.fullname = ''
    state.permissions = []
    state.phoneNumber = ''
    state.phoneNumberVerified = false
    state.picture = ''
    state.role = ''
    state.roleId = 0
    state.roleName = ''
    state.sub = ''
    state.agent = agentAttrDefault
  },
  SET_USER_INFO: (state, payload: IResultFetchUserInfo) => {
    state.authTime = payload.auth_time
    state.birthdate = payload.birthdate
    state.email = payload.email
    state.emailVerified = payload.email_verified
    state.gender = payload.gender
    state.id = payload.id
    state.fullname = payload.name
    state.permissions = payload.permissions
    state.phoneNumber = payload.phone_number
    state.phoneNumberVerified = payload.phone_number_verified
    state.picture = payload.picture
    state.role = payload.role
    state.roleId = payload.role_id
    state.roleName = payload.role_name
    state.sub = payload.sub
  },
  SET_USER_AGENT_INFO_ID: (state, payload: IResultAgentInfo) => {
    state.agent.id = payload.Id
  },
  SET_PROFIL_AGENT: (state, payload: TResultAgentSingle) => {
    const mapPayload = {
      id: state.agent.id,
      guid: payload.Guid,
      kotaId: payload.CityId,
      kotaCode: payload.CityCode,
      kotaName: payload.CityName,
      code: payload.Code,
      name: payload.Name,
      address: payload.Address,
      canPickup: payload.CanPickup,
      canDrop: payload.CanDrop,
      isLuarPulau: payload.IsLuarPulau,
      tipeAgenId: payload.AgentTypeId,
      tipeAgenType: payload.AgentTypeCode,
      tipeAgenName: payload.AgentTypeName,
      isActive: payload.IsActive,
      latitude: payload.Latitude,
      longitude: payload.Longitude,
      cityGroupId: payload.CityGroupId
    }
    state.agent = {
      isKurirDooring: false,
      ...mapPayload
    }
  },
  SET_PROFILE: (state, payload: TResultProfile) => {
    state.guid = payload.Guid
  }
}

const actions: ActionTree<TAppActiveUser, RootState> = {
  setUserInfo: async ({ commit }) => {
    try {
      const {
        error,
        result
      } = await authUseCase.getUserInfo()
      if (!error) {
        commit('SET_USER_INFO', result)
      }
    } catch (err) {
      console.log(err)
    }
  },
  setProfile: async ({ commit }) => {
    try {
      const {
        error,
        result
      } = await authUseCase.getProfile()
      if (!error) {
        commit('SET_PROFILE', result)
      }
    } catch (err) {
      console.log(err)
    }
  },
  setUserProfilAgent: async ({ commit, state }) => {
    try {
      const {
        error,
        result
      } = await authUseCase.getLoginUser(state.agent.id?.toString())
      if (!error) {
        commit('SET_PROFIL_AGENT', result)
      }
    } catch (err) {
      console.log(err)
    }
  },
  setUserAgentInfo: async ({ commit, dispatch }) => {
    try {
      const {
        error,
        result
      } = await authUseCase.getAgentInfo()
      if (!error) {
        commit('SET_USER_AGENT_INFO_ID', result)
        await dispatch('setUserProfilAgent')
      }
    } catch (err) {
      console.log(err)
    }
  }
}

const getters: GetterTree<TAppActiveUser, RootState> = {
  getAgent: (state) => state.agent,
  getPermissions: (state) => state.permissions,
  getAppActiveUser: (state) => state
}

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
  getters
}
