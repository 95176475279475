import { Remote, remoteEnum } from '@/data/source/remote/remoteVoucherV2'

const baseUrl = 'msmyvouchers'

const fetchAllMyVoucher = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addUserVoucher = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const deleteUserVoucher = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const redeemSouvenirByGuid = (id: any) => new Remote(remoteEnum.get, `${baseUrl}?guid=${id}`)

export {
  fetchAllMyVoucher,
  addUserVoucher,
  deleteUserVoucher,
  redeemSouvenirByGuid
}
