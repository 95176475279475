import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsJenisPembayaran'

const fetchAllJenisPembayaran = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneJenisPembayaran = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addJenisPembayaran = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editJenisPembayaran = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

export {
  fetchAllJenisPembayaran,
  fetchOneJenisPembayaran,
  addJenisPembayaran,
  editJenisPembayaran
}
