import courierActions from './courierActions'
import courierGetters from './courierGetters'
import courierMutations from './courierMutations'
import courierState from './courierState'

export default {
  namespaced: true,
  state: courierState,
  mutations: courierMutations,
  actions: courierActions,
  getters: courierGetters
}
