/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllAbsensiCourier, fetchOneAbsensiCourier, addAbsensiCourier, editAbsensiCourier, deleteAbsensiCourier, changeIsActiveAbsensiCourier, createAbsensiKey
} from '@/data/source/remote/api'

class CourierAbsensiUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    // return new Repository(changeIsActiveAbsensiCourier, createAbsensiKey(data), null).getResult(false)
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = {}): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  createCourierAbsensiKey(data: any): Promise<Response> {
    return new Repository(createAbsensiKey(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const courierAbsensiUseCase = new CourierAbsensiUseCase()

export {
  courierAbsensiUseCase
}
