import {
  Remote,
  remoteEnum
} from '@/data/source/remote/remoteApi'

const endpoint = '/company/v2/transdepositrefunds'

export enum statusDepositRefund {
  approve = 'approve',
  pay = 'pay'
}

export class TransDepositRefundV2 {
  fetchAll = (filter = '') => (new Remote(remoteEnum.get, `${endpoint}${filter}`))

  editStatus = (id: number, status: statusDepositRefund) => (new Remote(remoteEnum.put, `${endpoint}/${id}/${status}`))

  addRefund = (data: any) => new Remote(remoteEnum.post, endpoint, data)
}
