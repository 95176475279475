import localDbEnum from '../enum/localDbEnum'
import { ILocalData } from './iLocal'

export default class LocalDbEntity implements ILocalData {
  action: localDbEnum

  table: string

  filter: any

  key: string | number

  data: any

  constructor(action: localDbEnum, table = '', key: string | number = '', data: any = {}, filter: any = '') {
    this.action = action
    this.table = table
    this.filter = filter
    this.key = key
    this.data = data
  }
}
