/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import store from '@/store'
import localDbEnum from '@/data/source/local/enum/localDbEnum'
import LocalDbEntity from '@/data/source/local/databases/localDbEntity'
import { ILocal, ILocalData } from './iLocal'
import sqllite from './sqllite/sqllite'
import { addStore, putStore } from './indexeddb/idbService'

class LocalDb implements ILocal {
  action: localDbEnum

  table: string

  data: any

  filter: any

  key: any

  constructor(data: ILocalData) {
    this.action = data.action
    this.table = data.table
    this.data = data.data
    this.filter = data.filter
    this.key = data.key
  }

  add(): any {
    const db = sqllite
    if (store.state.device.isWeb) {
      addStore(this.table, this.data)
    }
    console.log('data', this.data)
  }

  put(): void {
    if (store.state.device.isWeb) {
      putStore(this.table, this.data, this.key)
    }
  }

  getAll(): any[] {
    throw new Error('Method not implemented.')
  }

  getOne() {
    throw new Error('Method not implemented.')
  }

  removeOne(): void {
    throw new Error('Method not implemented.')
  }

  removeAll(): void {
    throw new Error('Method not implemented.')
  }

  changeData(newData: any) {
    this.data = newData
  }

  run() {
    switch (this.action) {
      case localDbEnum.add:
        return this.add()
      case localDbEnum.put:
        return this.put()
      case localDbEnum.getAll:
        return this.getAll()
      case localDbEnum.getOne:
        return this.getOne()
      case localDbEnum.removeOne:
        return this.removeOne()
      case localDbEnum.removeAll:
        return this.removeAll()
      default:
        return []
    }
  }
}

export { LocalDb, localDbEnum, LocalDbEntity }
