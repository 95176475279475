import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/identity/v1/msuser'

const changeIsActiveProfileMsUser = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)
const resetDeviceProfileMsUser = (username: any) => new Remote(remoteEnum.post, `${baseUrl}/${username}/resetdevice`)
const addUserCompanyAddress = (username: any, data: any) => new Remote(remoteEnum.post, `${baseUrl}/${username}/Address`, data)

export {
  changeIsActiveProfileMsUser,
  resetDeviceProfileMsUser,
  addUserCompanyAddress
}
