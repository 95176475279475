/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllDynamicReportList,
  fetchAllDynamicExcelList,
  fetchUrlReport,
  excelAsuransi,
  fetchAllDynamicReport,
  fetchAllReportList,
  generateReportResult,
  fetchGenerateReportParams
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

class ReportingUseCase implements ICrud {
  getAll(filter: any = {}): Promise<Response> {
    // return new Repository(fetchAllDynamicReportList(), null).getResult(false)
    return new Repository(fetchAllReportList(returnUrlPrams(filter)), null).getResult(false)
  }

  getAllListExcel(filter: any = ''): Promise<Response> {
    return new Repository(fetchAllDynamicExcelList(), null).getResult(false)
  }

  getUrlReport(data: any): Promise<Response> {
    return new Repository(fetchUrlReport(data), null).getResult(false)
  }

  uploadExcel(data: any): Promise<Response> {
    return new Repository(excelAsuransi(data), null).getResult(false)
  }

  getAllDynamicReport(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Alamat), '${filter.search}') or Contains(tolower(Kota/Nama), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllDynamicReport(params), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getReportParams(id: any): Promise<Response> {
    return new Repository(fetchGenerateReportParams(id), null).getResult(false)
  }

  generateReport(id: any, payload: any): Promise<Response> {
    return new Repository(generateReportResult(id, payload), null).getResult(false)
  }
}

const reportingUseCase = new ReportingUseCase()

export {
  reportingUseCase
}
