import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKomisiKurirDetail'

const fetchAllKomisiKurirDetail = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKomisiKurirDetail = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addKomisiKurirDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKomisiKurirDetail = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKomisiKurirDetail = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchKomisiJenisKendaraan = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/KomisiJenisKendaraan/${id}`)

export {
  fetchAllKomisiKurirDetail,
  fetchOneKomisiKurirDetail,
  addKomisiKurirDetail,
  editKomisiKurirDetail,
  deleteKomisiKurirDetail,
  fetchKomisiJenisKendaraan
}