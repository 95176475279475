/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/store'
import http from '@/plugins/service/http'
import axios from '@/plugins/service/axios'
import { IRemote } from './iRemote'
import remoteEnum from './enumActions'

// eslint-disable-next-line no-shadow

class Remote implements IRemote {
  method: remoteEnum

  endPoint: string

  header: any

  data: any

  constructor(method: remoteEnum, endPoint: string, data: any = null, header = {}) {
    this.method = method
    this.endPoint = endPoint
    this.header = header
    this.data = data

    if (store.state.authenticated.isAuthen && store.state.token.accessToken) {
      this.header.authorization = `Bearer ${store.state.token.accessToken}`
      this.header.timeout = 5000
    }
  }

  get() {
    const remote = (this.header) ? axios.get(this.endPoint, {
      headers: this.header
    }) : axios.get(this.endPoint)
    // if (store.state.device.isMobile) {
    //   remote = http.request({
    //     method: 'GET',
    //     url: process.env.VUE_APP_API_URL + this.endPoint,
    //     headers: this.header
    //   })
    // }
    return remote
  }

  post() {
    const remote = axios.post(this.endPoint, this.data, {
      headers: this.header
    })
    // if (store.state.device.isMobile) {
    //   remote = http.request({
    //     method: 'POST',
    //     url: process.env.VUE_APP_API_URL + this.endPoint,
    //     headers: this.header,
    //     data: this.data
    //   })
    // }
    return remote
  }

  delete() {
    const remote = axios.delete(this.endPoint, {
      headers: this.header
    })
    // if (store.state.device.isMobile) {
    //   remote = http.request({
    //     method: 'DELETE',
    //     url: process.env.VUE_APP_API_URL + this.endPoint,
    //     headers: this.header
    //   })
    // }
    return remote
  }

  put() {
    const remote = axios.put(this.endPoint, this.data, {
      headers: this.header
    })
    // if (store.state.device.isMobile) {
    //   remote = http.request({
    //     method: 'PUT',
    //     url: process.env.VUE_APP_API_URL + this.endPoint,
    //     headers: this.header
    //   })
    // }
    return remote
  }

  patch() {
    const remote = axios.patch(this.endPoint, this.data, {
      headers: this.header
    })
    return remote
  }

  run() {
    switch (this.method) {
      case remoteEnum.get:
        return this.get()
      case remoteEnum.post:
        return this.post()
      case remoteEnum.put:
        return this.put()
      case remoteEnum.delete:
        return this.delete()
      case remoteEnum.patch:
        return this.patch()
      default:
        return []
    }
  }
}

export { Remote, remoteEnum }
