import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKota'

const fetchAllKota = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}/GetAll`)
const fetchKota = (filter : any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKota = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addKota = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKota = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKota = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllKota,
  fetchOneKota,
  addKota,
  editKota,
  deleteKota,
  fetchKota
}
