/* eslint-disable */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import { fetchAllPengirimanHd, fetchAllTransUserPengirimans, assignKurir, multipleAssignCourier, fetchMicroInfo, multiplePackageReturns, transferCourier, changeCourier, fetchAllJadwalKurirs, fetchAllPenjemputanPengirimanHd, fetchAllPickup } from '@/data/source/remote/api'
import Repository from '@/data/repository'
import Store from '@/store'
import RepositoryV2 from '@/data/repositoryV2'
import { TMicroInfoRequest, TMicroInfoResult } from '@/data/source/remote/api/kurir/V1/msUsers-model'

const store = Store as any
class NewPackageUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.custom) {
        arrFilter.push(filter.custom)
      }
      if (filter.search) {
        arrFilter.push(`${filter.search}`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderby=${filter.sorting}` : params
    }
    return new Repository(fetchAllTransUserPengirimans(params), null).getResult(false)
  }

  getOne(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeCourier(data: any): Promise<Response> {
    return new Repository(assignKurir(data), null).getResult(false)
  }

  changeTransferCourier(data: any): Promise<Response> {
    return new Repository(transferCourier(data), null).getResult(false)
  }

  changeMultipleCourier(data: any): Promise<Response> {
    return new Repository(multipleAssignCourier(data), null).getResult(false)
  }

  getMicroInfo(data: TMicroInfoRequest[]): Promise<Response<Array<TMicroInfoResult>>> {
    return new RepositoryV2<Array<TMicroInfoResult>>(fetchMicroInfo(data), null).getResult(false)
  }

  changeMultiplePackageReturns(data: any): Promise<Response> {
    return new Repository(multiplePackageReturns(data), null).getResult(false)
  }

  submitChangeCourier(data: any): Promise<Response> {
    return new Repository(changeCourier(data), null).getResult(false)
  }

  getJadwalKurir(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.custom) {
        arrFilter.push(filter.custom)
      }
      if (filter.search) {
        arrFilter.push(`${filter.search}`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderby=${filter.sorting}` : params
    }
    return new Repository(fetchAllJadwalKurirs(params), null).getResult(false)
  }

  getPickup(listSchedule: Array<number> = [], resi: any = ''): Promise<Response> {
    const data = {
      JadwalPenjemputanIds: listSchedule,
      GroupKotaId: store.getters['appActiveUser/getAgent']?.cityGroupId,
      Resi: resi
    }
    return new Repository(fetchAllPenjemputanPengirimanHd(data), null).getResult(false)
  }

  getPickupNew(filter: any = ''): Promise<Response> {
    return new Repository(fetchAllPickup(), null).getResult(false)
  }
}

const newPackageUseCase = new NewPackageUseCase()

export {
  newPackageUseCase
}
