import {
  ref, getCurrentInstance, reactive, computed
} from 'vue'
import { deliveryManifestUseCase } from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import store from '@/store'

export default {
  props: {
    isShowDialog: {
      default: false
    },
    deliveryHdId: {
      default: null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const dialogPending = computed(() => propertiesProps.isShowDialog) as any
    const deliveryHdId = computed(() => propertiesProps.deliveryHdId) as any
    const submitted = ref(false)
    const dataAdd = reactive({
      description: ''
    })
    const rules = {
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataAdd)

    const hideDialog = () => {
      emit('hideDialog')
    }

    const proccessPendingManifest = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        PengirimanHdId: [deliveryHdId.value],
        Keterangan: dataAdd.description
      }

      const response = await deliveryManifestUseCase.submitPendingManifest(dataForm)
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 1500
        })
        hideDialog()
        emit('reloadData')
      }
    }

    const submitPendingManifest = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessPendingManifest()
    }

    return {
      v$,
      submitted,
      dialogPending,
      hideDialog,
      submitPendingManifest,
    }
  }
}