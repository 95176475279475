/* eslint-disable class-methods-use-this */
import RepositoryV2 from '@/data/repositoryV2'
import {
  approveReturnPackage,
  declineReturnPackage,
  fetchReturnRequestDetail,
  fetchProfileCourier,
  fetchMicroInfo
} from '@/data/source/remote/api'
import { TReturnRequestDetail } from '@/data/source/remote/api/backend/V2/transPengirimanHds-model'
import { TMicroInfoRequest, TMicroInfoResult } from '@/data/source/remote/api/kurir/V1/msUsers-model'
import { Response } from '@/domain/entities'

class ReadyDeliverUseCase {
  getDetailReturnPackage(id: string): Promise<Response<TReturnRequestDetail>> {
    return new RepositoryV2<TReturnRequestDetail>(fetchReturnRequestDetail(id), null).getResult(false)
  }

  approveReturnPackage(id: string) {
    return new RepositoryV2(approveReturnPackage(id), null).getResult(false)
  }

  declineReturnPackage(id: string) {
    return new RepositoryV2(declineReturnPackage(id), null).getResult(false)
  }

  getProfileCourier(id: string): Promise<Response> {
    return new RepositoryV2(fetchProfileCourier(id), null).getResult(false)
  }

  async getSingleMicroInfo(id: number): Promise<TMicroInfoResult | null> {
    const {
      error,
      result
    } = await new RepositoryV2<Array<TMicroInfoResult>>(fetchMicroInfo([{ Id: id }]), null).getResult(false)
    if (!error) {
      return result[0]
    } return null
  }
}

const readyDeliverUseCase = new ReadyDeliverUseCase()

export {
  readyDeliverUseCase
}
