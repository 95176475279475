/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllPelanggan, fetchOnePelanggan, addPelanggan, editPelanggan, deletePelanggan, changeIsActivePelanggan, fetchAllLevelDiskon, fetchAllKota, fetchAllUsersV2, fetchOneUserV2, changeIsActiveuserV2, fetchAllVoucherCodes, addUserVoucher, fetchAllUserAddress, editOneUserV2,
  fetchUserProfileV2
} from '@/data/source/remote/api'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

class PelangganUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActivePelanggan(data), null).getResult(false)
  }

  changeIsActiveV2(id: any, data: any): Promise<Response> {
    return new Repository(changeIsActiveuserV2(id, data), null).getResult(false)
  }

  getAll(filter: any): Promise<Response> {
    let params = ''
    if (filter.filter) {
      params = ` and ${filter.filter}`
    }
    return new Repository(fetchAllPelanggan(`?$count=true&$skip=${filter.skip}&$top=${filter.top}&$filter=(Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Alamat), '${filter.search}'))${params}`), null).getResult(false)
  }

  getAllV2(filter: any): Promise<Response> {
    console.log('aaaaa')
    // let params = '?'
    // const arrayFilter = []
    // if (filter.pageNumber) {
    //   arrayFilter.push(`PageNumber=${filter.pageNumber}`)
    // }
    // if (filter.pageSize) {
    //   arrayFilter.push(`PageSize=${filter.pageSize}`)
    // }
    // if (filter.groupUser) {
    //   arrayFilter.push(`groupUser=${filter.groupUser}`)
    // }
    // if (filter.search !== '') {
    //   arrayFilter.push(`fullName=${filter.search}`)
    // }
    // if (arrayFilter.length > 0) {
    //   params = `${params}${arrayFilter.join('&')}`
    // }
    return new Repository(fetchAllUsersV2(returnUrlPrams(filter)), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== undefined) {
      const response = await new Repository(fetchOneUserV2(id), null).getResult(false)
      return {
        title: 'Edit Pelanggan Retail',
        data: response
      }
    }

    return {
      title: 'Tambah Pelanggan Retail',
      data: null
    }
  }

  submitData(Id: any = null, data: any): Promise<Response> {
    if (Id !== null) {
      data.Id = Number(Id)
      return new Repository(editOneUserV2(Id, data), null).getResult(false)
    }
    return new Repository(addPelanggan(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deletePelanggan(id), null).getResult(false)
  }

  getAllDataLevelDiskon(): Promise<Response> {
    return new Repository(fetchAllLevelDiskon('?$filter=IsActive eq true and IsDeleted eq false&$select=Keterangan,Id,MinTransaksi,Kelas,Diskon,Attribute1'), null).getResult(false)
  }

  getSelectKota(filter: any = ''): Promise<Response> {
    let params = '$filter=IsActive eq true'
    if (filter) {
      if (filter.kotaId) {
        params = `${params} and Id eq ${filter.kotaId}`
      }
    }
    return new Repository(fetchAllKota(`$select=Id,IsActive,Provinsi,Nama,PulauId,Kode&${params}`), null).getResult(false)
  }

  getSelectVoucher(filter: any = ''): Promise<Response> {
    return new Repository(fetchAllVoucherCodes(filter), null).getResult(false)
  }

  submitUserVoucher(data: any): Promise<Response> {
    return new Repository(addUserVoucher(data), null).getResult(false)
  }

  getUserAddress(id: any = null): Promise<Response> {
    return new Repository(fetchAllUserAddress(id), null).getResult(false)
  }

  getCustomerProfile(username: string): Promise<Response> {
    return new Repository(fetchUserProfileV2(username), null).getResult(false)
  }
}

const customerRetailUseCase = new PelangganUseCase()

export {
  customerRetailUseCase
}
