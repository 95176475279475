import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { useStore } from 'vuex'
import { receiveItemUseCase, receiveItemFileUseCase } from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required, helpers, maxLength, minLength, numeric
} from '@vuelidate/validators'
import { useDropzone } from 'vue3-dropzone'
import { useRoute, useRouter } from 'vue-router'
import { takePhoto } from '@/plugins/system/camera'
import { compressImage } from '@/plugins/compressor'
import moment from 'moment'

export default {
  props: ['id'],
  emits: ['hideForm', 'successBack'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $dataURLToFile, $dataURItoBlob
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataForm = reactive({
      name: '',
      numberPhone: '',
      noKtp: '',
      images: []
    })
    const listImage = ref([]) as any
    const submitted = ref(false)
    const route = useRoute()
    const rules = {
      name: {
        required: helpers.withMessage('Nama penerima harus diisi', required)
      },
      numberPhone: {
        required: helpers.withMessage('No.telp harus diisi', required),
        numeric: helpers.withMessage('No.telp harus berisi angka', numeric)
      },
      noKtp: {
        required: helpers.withMessage('No.ktp harus diisi', required),
        maxLength: helpers.withMessage('No.ktp harus berisi 16 digit', maxLength(16)),
        minLength: helpers.withMessage('No.ktp harus berisi 16 digit', minLength(16)),
        numeric: helpers.withMessage('No.ktp harus berisi angka', numeric)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const checkSrcListImage = () => listImage.value.filter((image: any) => image.src === null).length

    const generateListImage = () => {
      for (let index = 0; index < 5; index++) {
        listImage.value.push({
          name: 'add-image',
          src: null,
          image: null
        })
      }
    }

    const pushListImage = async (item: any, from: any = '') => {
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
        console.log('file to compress', file)
      }
      for (let index = 0; index < listImage.value.length; index++) {
        const image = listImage.value[index]
        if (image.src === null) {
          listImage.value[index].src = URL.createObjectURL(item)
          listImage.value[index].image = file
          break
        }
      }
    }

    const removeImage = (index: number) => {
      listImage.value[index].src = null
      listImage.value[index].image = null
    }

    const resetImage = () => {
      for (let index = 0; index < listImage.value.length; index++) {
        listImage.value[index].src = null
        listImage.value[index].image = null
      }
    }

    function onDrop(acceptFiles: any, rejectReasons: any) {
      if (checkSrcListImage() >= acceptFiles.length) {
        for (let index = 0; index < acceptFiles.length; index++) {
          if (acceptFiles[index].type.includes('image')) {
            pushListImage(acceptFiles[index])
          } else {
            $toast.add({
              severity: 'error', detail: 'Hanya support tipe file gambar (png/jpg/jpeg)', group: 'bc', life: 1500
            })
          }
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Maksimal upload gambar sebanyak 5', group: 'bc', life: 1500
        })
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })

    const hideForm = () => {
      emit('hideForm')
    }

    const proccessUploadFile = async (receive: any) => {
      const listPromise = [] as any
      console.log('proccessUpoad', JSON.stringify(listImage.value))
      for (let index = 0; index < listImage.value.length; index++) {
        const item = listImage.value[index]
        if (item.src !== null) {
          const formData = new FormData()
          // const senderId = props.id
          const senderId = props.id
          const img = item.image
          // formData.append('PengirimanId', senderId)
          // formData.append('IsPenerimaan', 'false')
          const sendData = {
            IsPenerimaan: true,
            PengirimanId: senderId
          } as any
          if (img.from === 'camera') {
            // console.log('if on camera')
            formData.append('file', img.image, `${img.name}.${img.ext}`)
          } else {
            // console.log('img', img)
            formData.append('file', img)
          }
          // listPromise.push(receiveItemFileUseCase.submitData(receive.TransPengirimanHd.Id, formData))
          receiveItemFileUseCase.submitFile('penerimaan&useid=true', formData).then((res) => {
            // console.log('hasil upload res', res)
            sendData.Filename = res.result.Filename
            sendData.Thumbnail = res.result.Thumbnail
            listPromise.push(receiveItemFileUseCase.submitDataPenerimaanFiles(receive.Id, sendData))
          })

          // listPromise.push(receiveItemFileUseCase.submitFile(formData))
        }
        // if (item.src !== null) {
        //   const formData = new FormData()
        //   const senderId = props.id
        //   const img = item.image
        //   formData.append('PengirimanId', senderId)
        //   formData.append('IsPenerimaan', 'true')
        //   if (img.from === 'camera') {
        //     console.log('if on camera')
        //     formData.append('photo', img.image, `${img.name}.${img.ext}`)
        //   } else {
        //     console.log('img', img)
        //     formData.append('photo', img)
        //   }
        //   listPromise.push(receiveItemFileUseCase.submitData(receive.Id, formData))
        // }
      }

      await receiveItemFileUseCase.submitDataAll(listPromise).then((responses) => {
        $toast.add({
          severity: 'success', detail: 'Berhasil upload gambar', group: 'bc', life: 1500
        })
        hideForm()
        emit('successBack')
        // router.push({ name: 'transaksi-barang-diterima' })
      }).catch(() => {
        $toast.add({
          severity: 'error', detail: 'Gagal upload gambar, silahkan coba kembali', group: 'bc', life: 1500
        })
      })
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const dataSubmit = {
        Nama: dataForm.name,
        NoKtp: dataForm.noKtp,
        Telepon: dataForm.numberPhone
      }
      const response = await receiveItemUseCase.submitData(props.id, dataSubmit)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        await proccessUploadFile(response.result)
      }
      store.dispatch('hideLoading')
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      if (checkSrcListImage() === 5) {
        $toast.add({
          severity: 'error', detail: 'Silahkan upload gambar terlebih dahulu', group: 'bc', life: 1500
        })
        return
      }
      proccessSubmit()
    }

    const showCamera = async () => {
      const response = await takePhoto() as any
      console.log('response', JSON.stringify(response))
      pushListImage(response, 'camera')
    }

    onMounted(() => {
      generateListImage()
    })

    return {
      v$,
      dataForm,
      submitted,
      getRootProps,
      getInputProps,
      removeImage,
      resetImage,
      checkSrcListImage,
      ...rest,
      listImage,
      showCamera,
      hideForm,
      submitData
    }
  }
}