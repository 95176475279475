import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'

const baseUrl = 'MsUsers'

const fetchAllCourierFile = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addCourierFile = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/UploadSingle`, data)
const deleteCourierFile = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllCourierFile,
  addCourierFile,
  deleteCourierFile
}
