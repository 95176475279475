import { App } from 'vue'
import InputIconRightGrey from '@/views/shared-components/form/InputIconRightGrey.vue'
import InputIconLeftWhite from '@/views/shared-components/form/InputIconLeftWhite.vue'

export default {
  install: (app: App<Element>) => {
    app.component('InputIconRightGrey', InputIconRightGrey)
    app.component('InputIconLeftWhite', InputIconLeftWhite)
  }
}
