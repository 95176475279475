
import {
  ref
} from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'

export default {
  name: 'PaginatorUrl',
  props: {
    totalRecords: {
      required: true,
      type: Number,
      default: () => 0
    }
  },
  emits: ['onPage'],
  setup(props: any, {
    emit
  }: any) {
    const router = useRouter()
    const route = useRoute() as any
    const row = ref(10)
    const first = ref(route.query?.PageNumber ? Number(row.value * (route.query.PageNumber - 1)) : 0)
    const total = ref(props.totalRecords)
    const onPage = (event: any) => {
      // first.value = event.page + 1
      router.replace({
        // name: route.name,
        query: {
          PageSize: row.value,
          PageNumber: event.page + 1,
          // CompanyName: route.query.CompanyName
        }
      }).then(() => {
        emit('onPage')
      }).catch(() => {
        console.log()
      })
      // emit('onPage', skip)
    }
    return {
      row,
      total,
      onPage,
      first
    }
  }
}
