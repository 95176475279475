import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  listRoute.push(
    {
      path: `${val}/comission-confirmation`,
      name: 'confirmation-comission',
      component: () => import('@/views/master/courier/comission-confirmation/ComissionConfirmation.vue'),
      meta: {
        group: 'Kurir',
        permission
      }
    }
  )
  return listRoute
}
