import { Remote, remoteEnum } from '@/data/source/remote/remoteReport'

const baseUrl = 'GetPdf'

const fetchPdfResi = (id: any, userId: any) => new Remote(remoteEnum.getBlob, `${baseUrl}/Resi?IdPengiriman=${id}&IdUser=${userId}`)
const fetchPdfManifest = (id: any) => new Remote(remoteEnum.getBlob, `${baseUrl}/Manifest?mnfstid=${id}`)
const fetchPdfResiByte = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/ByteResi?IdPengiriman=${id}`)

export {
  fetchPdfResi,
  fetchPdfManifest,
  fetchPdfResiByte
}
