import { checkPermissionObj } from '@/plugins/methodsBase'
import deliveryItem from './deliveryItem'
import deliveryManifest from './deliveryManifest'
import deliveryPending from './deliveryPending'
import refund from './refund'
import deliveryCanceled from './deliveryCanceled'

const prefix = 'pengiriman-barang'

export default () => {
  const getDeliveryItem = deliveryItem(prefix)
  const getDeliveryManifest = deliveryManifest(prefix)
  const getdeliveryPending = deliveryPending(prefix)
  const getRefund = refund(prefix)
  const getDeliveryCanceled = deliveryCanceled(prefix)

  return [
    ...getDeliveryItem,
    ...getDeliveryManifest,
    ...getdeliveryPending,
    ...getRefund,
    ...getDeliveryCanceled
  ]
}
