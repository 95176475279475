/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/store'
import http from '@/plugins/service/http'
import axios from '@/plugins/service/axios'
import { IRemote } from './iRemote'
import remoteEnum from './enumActions'

class RemoteGoogle implements IRemote {
  method: remoteEnum

  endPoint: string

  header: any

  data: any

  constructor(method: remoteEnum, endPoint: string, data: any = null, header = {}) {
    axios.defaults.baseURL = 'https://maps.googleapis.com/maps/api/'
    this.method = method
    this.endPoint = `${endPoint}&key=${process.env.VUE_APP_GOOGLE_API}`
    // this.endPoint = `${endPoint}&key=AIzaSyBHsQPcuRrfglDvRqFtg57GsxCh1RfVTw8`
    this.header = header
    this.data = data
  }

  get() {
    let remote = (this.header) ? axios.get(this.endPoint, {
      headers: this.header
    }) : axios.get(this.endPoint)
    if (store.state.device.isMobile) {
      remote = http.request({
        method: 'GET',
        url: this.endPoint,
        headers: this.header
      })
    }
    return remote
  }

  post() {
    let remote = axios.post(this.endPoint, this.data, {
      headers: this.header
    })
    if (store.state.device.isMobile) {
      remote = http.request({
        method: 'POST',
        url: this.endPoint,
        headers: this.header,
        data: this.data
      })
    }
    return remote
  }

  delete() {
    let remote = axios.delete(this.endPoint, {
      headers: this.header
    })
    if (store.state.device.isMobile) {
      remote = http.request({
        method: 'DELETE',
        url: this.endPoint,
        headers: this.header
      })
    }
    return remote
  }

  put() {
    let remote = axios.put(this.endPoint, this.data, {
      headers: this.header
    })
    if (store.state.device.isMobile) {
      remote = http.request({
        method: 'PUT',
        url: this.endPoint,
        headers: this.header
      })
    }
    return remote
  }

  run() {
    switch (this.method) {
      case remoteEnum.get:
        return this.get()
      case remoteEnum.post:
        return this.post()
      case remoteEnum.put:
        return this.put()
      case remoteEnum.delete:
        return this.delete()
      default:
        return []
    }
  }
}

export { RemoteGoogle, remoteEnum }
