import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPengirimanHd'

const fetchAllPengirimanHd = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchAllPengirimanHdKedatangan = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/Kedatangan${filter}`)
const fetchOnePengirimanHd = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addPengirimanHd = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPengirimanHd = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const ubahJadwalPending = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/UbahJadwalPending`, data)
const cetakResiPengirimanHd = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/CetakResiV2`, data)
const deletePengirimanHd = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const refund = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/Refund/${id}`, data)
const dropAgent = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/DropAgen`, data)
const fetchDashboardPendapatan = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/DashboardPendapatan/${id}`)
const fetchStockLabelSticker = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/StockLabelSticker/${id}`)
const addLogRegUid = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/logReqUID/${id}`)
const scanResi = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/ScanResi`, data)
const ubahPengirimanHd = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/UbahPembayaran`, data)
const pengecekanResiCheckResi = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/CheckResi`, data)
const assignKurir = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/AssignKurir`, data)
const fetchAllPenjemputanPengirimanHd = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Penjemputan`, data)
const assignLabelAndSticker = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/AssignLabelAndSticker`, data)
const assignJadwal = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/AssignJadwal`, data)
const ubahStatusPengiriman = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/UbahStatusPengiriman`, data)
const itemToManifest = (id: any) => new Remote(remoteEnum.put, `${baseUrl}/ProcessToManifest/${id}`)
const fetchAllPickup = () => new Remote(remoteEnum.get, `${baseUrl}/Pickup`)

export {
  fetchAllPengirimanHd,
  fetchAllPengirimanHdKedatangan,
  fetchOnePengirimanHd,
  addPengirimanHd,
  editPengirimanHd,
  deletePengirimanHd,
  cetakResiPengirimanHd,
  ubahJadwalPending,
  refund,
  dropAgent,
  fetchStockLabelSticker,
  fetchDashboardPendapatan,
  addLogRegUid,
  scanResi,
  pengecekanResiCheckResi,
  ubahPengirimanHd,
  assignKurir,
  fetchAllPenjemputanPengirimanHd,
  assignLabelAndSticker,
  assignJadwal,
  ubahStatusPengiriman,
  itemToManifest,
  fetchAllPickup
}
