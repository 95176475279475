/* eslint-disable class-methods-use-this */
import Configuration from '@/utils/configuration/configuration'

class Environment {
  setEnv(processDev: any, processProd: any): any {
    // console.log('in set env', processDev, processProd)
    if (processProd !== null) {
      return processProd
    } return processDev
  }

  getApiBase() {
    return this.setEnv(process.env.VUE_APP_API_BASE, Configuration.value('API_BASE'))
  }

  getApiUrl() {
    return this.setEnv(process.env.VUE_APP_API_URL, Configuration.value('API_URL'))
  }

  getApiReporting() {
    return this.setEnv(process.env.VUE_APP_API_REPORTING, Configuration.value('API_REPORTING'))
  }

  getApiReceipt() {
    return this.setEnv(process.env.VUE_APP_API_RECEIPT, Configuration.value('API_RECEIPT'))
  }

  getApiIdentity() {
    return this.setEnv(process.env.VUE_APP_API_IDENTITY, Configuration.value('API_IDENTITY'))
  }

  getApiIdentityV2() {
    return this.setEnv(process.env.VUE_APP_API_IDENTITYV2, Configuration.value('API_IDENTITYV2'))
  }

  getApiOpenTicket() {
    return this.setEnv(process.env.VUE_APP_API_OPENTICKET, Configuration.value('API_OPENTICKET'))
  }

  getApiCourier() {
    return this.setEnv(process.env.VUE_APP_API_COURIER, Configuration.value('API_COURIER'))
  }

  getApiVoucher() {
    return this.setEnv(process.env.VUE_APP_API_VOUCHER, Configuration.value('API_VOUCHER'))
  }

  getApiVoucherV2() {
    return this.setEnv(process.env.VUE_APP_API_VOUCHERV2, Configuration.value('API_VOUCHERV2'))
  }

  getApiCompany() {
    return this.setEnv(process.env.VUE_APP_API_COMPANY, Configuration.value('API_COMPANY'))
  }

  getApiUser() {
    return this.setEnv(process.env.VUE_APP_API_USER, Configuration.value('API_USER'))
  }

  getApiUserV2() {
    return this.setEnv(process.env.VUE_APP_API_USERV2, Configuration.value('API_USERV2'))
  }

  getApiSetting() {
    return this.setEnv(process.env.VUE_APP_API_SETTING, Configuration.value('API_SETTING'))
  }

  getVersion() {
    return this.setEnv(process.env.VUE_APP_VERSION, Configuration.value('VERSION'))
  }

  getGoogleKey() {
    return this.setEnv(process.env.VUE_APP_GOOGLE_KEY, Configuration.value('GOOGLE_KEY'))
  }

  getHydraApi() {
    return this.setEnv(process.env.VUE_APP_HYDRA_API, Configuration.value('HYDRA_API'))
  }

  getHydraClientId() {
    return this.setEnv(process.env.VUE_APP_HYDRA_CLIENT_ID, Configuration.value('HYDRA_CLIENT_ID'))
  }

  getHydraScope() {
    return this.setEnv(process.env.VUE_APP_HYDRA_SCOPE, Configuration.value('HYDRA_SCOPE'))
  }

  getHydraResponseType() {
    return this.setEnv(process.env.VUE_APP_HYDRA_RESPONSE_TYPE, Configuration.value('HYDRA_RESPONSE_TYPE'))
  }

  getHydraState() {
    return this.setEnv(process.env.VUE_APP_HYDRA_STATE, Configuration.value('HYDRA_STATE'))
  }

  getHydraClientSecret() {
    return this.setEnv(process.env.VUE_APP_HYDRA_CLIENT_SECRET, Configuration.value('HYDRA_CLIENT_SECRET'))
  }

  getHydraGrantType() {
    return this.setEnv(process.env.VUE_APP_HYDRA_GRANT_TYPE, Configuration.value('HYDRA_GRANT_TYPE'))
  }

  getUrlBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_URL_BASE_MQTT, Configuration.value('URL_BASE_MQTT'))
  }

  getPortBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_PORT_BASE_MQTT, Configuration.value('PORT_BASE_MQTT'))
  }

  getUsernameMqtt() {
    return this.setEnv(process.env.VUE_APP_USERNAME_MQTT, Configuration.value('USERNAME_MQTT'))
  }

  getPasswordMqtt() {
    return this.setEnv(process.env.VUE_APP_PASSWORD_MQTT, Configuration.value('PASSWORD_MQTT'))
  }

  getProtocolBaseMqtt() {
    return this.setEnv(process.env.VUE_APP_PROTOCOL_BASE_MQTT, Configuration.value('PROTOCOL_BASE_MQTT'))
  }

  getLinkWa() {
    return this.setEnv(process.env.VUE_APP_LINK_WA, Configuration.value('LINK_WA'))
  }
}

const environment = new Environment()

export {
  environment
}
