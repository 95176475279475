import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPengirimanHdScans'

const changeArriveTransPengirimanHdScans = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Arrive`, data)
const checkTransPengirimanHdScans = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Check`, data)

export {
  changeArriveTransPengirimanHdScans,
  checkTransPengirimanHdScans
}
