import user from './user'
import groupUser from './groupUser'
import globalSetting from './globalSetting'

export default () => {
  const getUser = user('user-admin')
  const getGroupUser = groupUser('user-admin')
  const getGlobalSetting = globalSetting('user-admin')
  return [
    ...getUser,
    ...getGroupUser,
    ...getGlobalSetting
  ]
}
