import { MutationTree } from 'vuex'
import {
  DashboardState,
  LastReload,
  StockLabel,
  StockSticker,
  TypePendingPackage
} from './types'

const mutations: MutationTree<DashboardState> = {
  SET_DEFAULT_STATE: (state) => {
    state.income = 0
    state.deliveryTotal = 0
    state.receiveTotal = 0
    state.lastReload = {
      date: '',
      firstReload: false
    }
    state.stockSticker = {
      Stock: 0,
      Stickers: []
    }
    state.stockLabel = {
      Stock: 0,
      Labels: []
    }
    state.pendingPickup = {
      goodsCount: 0,
      waybillsCount: 0
    }
    state.pendingDelivery = {
      goodsCount: 0,
      waybillsCount: 0
    }
  },
  SET_DELIVERY_TOTAL: (state, total: number) => {
    state.deliveryTotal = total
  },
  SET_INCOME: (state, total: number) => {
    state.income = total
  },
  SET_RECEIVE_TOTAL: (state, total: number) => {
    state.receiveTotal = total
  },
  SET_CLAIM_TOTAL: (state, total: number) => {
    state.claimTotal = total
  },
  SET_LAST_RELOAD: (state, lastReload: LastReload) => {
    state.lastReload = lastReload
  },
  SET_STOCK_LABEL: (state, stockLabel: StockLabel) => {
    state.stockLabel = stockLabel
  },
  SET_STOCK_STICKER: (state, stockSticker: StockSticker) => {
    state.stockSticker = stockSticker
  },
  SET_PENDING_PICKUP: (state, pendingPackage: TypePendingPackage) => {
    state.pendingPickup = pendingPackage
  },
  SET_PENDING_DELIVERY: (state, pendingPackage: TypePendingPackage) => {
    state.pendingDelivery = pendingPackage
  },
}

export default mutations
