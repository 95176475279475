import {
  environment
} from '@/utils'
import store from '@/store'
import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance
} from 'axios'

const setup = (api: AxiosInstance) => {
  api.interceptors.response.use(async (response: AxiosResponse) => response, async (err: AxiosError) => {
    // console.log('masuk res err', err?.response)
    if (err?.response && err?.response.status === 401) {
      store.dispatch('updateDataToken')
      // throw new axios.Cancel('Operation canceled by the user.')
    }
    return Promise.reject(err)
  })
}

export default setup
