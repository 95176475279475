import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsAuthTiket'

const fetchAllOtUser = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneOtUser = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)

export {
  fetchAllOtUser,
  fetchOneOtUser
}
