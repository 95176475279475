import openTicket from './openTicket'

const prefix = 'open-ticket'

export default () => {
  const getOpenTicket = openTicket(prefix)

  return [
    ...getOpenTicket
  ]
}
