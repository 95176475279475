import { DashboardState } from './types'

const state: DashboardState = {
  income: 0,
  deliveryTotal: 0,
  receiveTotal: 0,
  claimTotal: 0,
  lastReload: {
    date: '',
    firstReload: false
  },
  stockSticker: {
    Stock: 0,
    Stickers: []
  },
  stockLabel: {
    Stock: 0,
    Labels: []
  },
  pendingPickup: {
    goodsCount: 0,
    waybillsCount: 0
  },
  pendingDelivery: {
    goodsCount: 0,
    waybillsCount: 0
  }
}

export default state
