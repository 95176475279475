import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import {
  TPayloadAddUpdateGroupUser,
  TPayloadAddGroupUserPermission
} from './msGroupUsers-model'

const baseUrl = '/identity/v2/msgroupusers'

const fetchAllGroupUser = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneGroupUser = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addGroupUser = (data: TPayloadAddUpdateGroupUser) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editGroupUser = (id: string, data: TPayloadAddUpdateGroupUser) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteGroupUser = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchGroupUserPermission = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/permissions`)
const addGroupUserPermission = (id: string, data: TPayloadAddGroupUserPermission) => new Remote(remoteEnum.post, `${baseUrl}/${id}/permissions`, data)
const deleteSingleGroupUserPermission = (id: number, idPermission: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}/permissions/${idPermission}`)

export {
  fetchAllGroupUser,
  fetchOneGroupUser,
  addGroupUser,
  editGroupUser,
  deleteGroupUser,
  fetchGroupUserPermission,
  addGroupUserPermission,
  deleteSingleGroupUserPermission
}
