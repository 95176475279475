import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default (val = '') => {
  const listRoute = []
  // if (checkPermission()) {
  listRoute.push({
    path: `${val}/pengaturan-global`,
    name: 'master-pengaturan-global',
    component: () => import('@/views/master/user-admin/global-setting/GlobalSetting.vue'),
    meta: {
      group: 'USER & ADMIN',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  })
  // }

  return listRoute
}
