import { Remote, remoteEnum } from '@/data/source/remote/remoteOpenTicket'

// const baseUrl = 'ticket-support/api/v1/tickets/create'
const baseUrl = 'api/v1/tickets/create'

const addTicket = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

export {
  addTicket
}
