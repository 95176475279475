/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllJenisKendaraans,
  deleteJenisKendaraans,
  editJenisKendaraans,
  addJenisKendaraans
} from '@/data/source/remote/api'

class JenisKendaraanUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        // arrayFilter.push(`Contains(tolower(Nama),'${filter.search}')`)
        arrayFilter.push(`(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllJenisKendaraans(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id || id === 0) {
      data.Id = Number(id)
      return new Repository(editJenisKendaraans(data.Id, data), null).getResult(false)
    }
    return new Repository(addJenisKendaraans(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteJenisKendaraans(id), null).getResult(false)
  }
}

const jenisKendaraanUseCase = new JenisKendaraanUseCase()

export {
  jenisKendaraanUseCase
}
