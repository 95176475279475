export default {
  setDefaultVoucher: ({ commit }: any) => {
    commit('SET_DEFAULT_VOUCHER')
  },
  setKode: ({ commit }: any, payload: any) => {
    commit('SET_KODE', payload)
  },
  setNama: ({ commit }: any, payload: any) => {
    commit('SET_NAMA', payload)
  },
  setDescription: ({ commit }: any, payload: any) => {
    commit('SET_DESCRIPTION', payload)
  },
  setDiscount: ({ commit }: any, payload: any) => {
    commit('SET_DISCOUNT', payload)
  },
  setMaxPotongan: ({ commit }: any, payload: any) => {
    commit('SET_MAXPOTONGAN', payload)
  },
  setQty: ({ commit }: any, payload: any) => {
    commit('SET_QTY', payload)
  },
  setUseLimit: ({ commit }: any, payload: any) => {
    commit('SET_USELIMIT', payload)
  },
  setPoin: ({ commit }: any, payload: any) => {
    commit('SET_POIN', payload)
  },
  setGenerateReferalCode: ({ commit }: any, payload: any) => {
    commit('SET_GEN_REFCODE', payload)
  },
  setIsMoney: ({ commit }: any, payload: any) => {
    commit('SET_ISMONEY', payload)
  },
  setIsActive: ({ commit }: any, payload: any) => {
    commit('SET_ISACTIVE', payload)
  },
  setIsHidden: ({ commit }: any, payload: any) => {
    commit('SET_ISHIDDEN', payload)
  },
  setGambar: ({ commit }: any, payload: any) => {
    commit('SET_GAMBAR', payload)
  },
  setVoucherDetails: ({ commit }: any, payload: any) => {
    commit('SET_VOUCHER_DETAILS', payload)
  },
  setVoucherType: ({ commit }: any, payload: any) => {
    commit('SET_VOUCHER_TYPE', payload)
  },
  setVoucherPenggunaan: ({ commit }: any, payload: any) => {
    commit('SET_VOUCHER_PENGGUNAAN', payload)
  },
}
