import CourierAttendance from './courierAttendace'
import SchedulingCourier from './schedulingCourier'
import TransportationType from './transportationType'
import AdditionalFee from './additionalFee'

const prefix = 'kurir'

export default () => {
  const getCourierAttendance = CourierAttendance(prefix)
  const getSchedulingCourier = SchedulingCourier(prefix)
  const getTransportationType = TransportationType(prefix)
  const getAdditionalFee = AdditionalFee(prefix)

  return [
    ...getCourierAttendance,
    ...getSchedulingCourier,
    ...getTransportationType,
    ...getAdditionalFee
  ]
}
