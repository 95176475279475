/* eslint-disable */
import { Plugins } from '@capacitor/core'

const { Geolocation } = Plugins

class GeolocationExample {
  async getCurrentPosition() {
    const coordinates = await Geolocation.getCurrentPosition()
    return coordinates?.coords;
  }

  watchPosition() {
    const wait = Geolocation.watchPosition({}, (position, err) => {})
  }
}

// export default new GeolocationExample()


const getCurrentPosition = () => ''
const watchPosition = async () => ''

// export {
//   // getCurrentPosition, watchPosition
//   GeolocationExample

// }
export default new GeolocationExample()

