// eslint-disable-next-line no-shadow
enum localDbEnum {
  add,
  put,
  getAll,
  getOne,
  removeOne,
  removeAll,
  noAction
}

export default localDbEnum
