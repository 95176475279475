import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'PayoutRefundTransaksi'

const fetchAllDataPayoutRefund = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const confirmPayoutRefund = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Confirm`, data)
const approvePayoutRefund = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Approve`, data)

export {
  fetchAllDataPayoutRefund,
  confirmPayoutRefund,
  approvePayoutRefund
}
