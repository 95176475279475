import {
  createRouter,
  createWebHistory
} from 'vue-router'
import iconDependency from '@/dependencies/iconDependency'
import { isMobile } from '@/plugins/system/device'

import moment from 'moment'
import { environment } from '@/utils'
import store from '../store'
import master from './master/master'
import transactions from './transactions/transactions'
import home from './home'
import cashier from './cashier'
import help from './help'
import packageOrder from './package-order'
import courier from './courier'
import setting from './setting'

const Home = () => import('@/views/pages/Home.vue')
const Error404 = () => import('@/views/errors/Error404.vue')
const BarcodeScanner = () => import('@/views/shared-components/BarcodeScanner.vue')

const icon = iconDependency()
const getHome = home() ?? []
const getMaster = master() ?? []
const getTransaction = transactions() ?? []
const getCashier = cashier() ?? []
const getHelp = help() ?? []
const getPackageOrder = packageOrder() ?? []
const getCourier = courier() ?? []
const getSetting = setting() ?? []

const mergeRoute = [
  ...getHome,
  ...getMaster,
  ...getTransaction,
  ...getCashier,
  ...getHelp,
  ...getSetting,
  ...getPackageOrder,
  ...getCourier
]
// const checkConditionRouting = () => {
//   if (mergeRoute.length > 0) {
//     return mergeRoute[0].path
//   }
//   return null
// }

const routes = [{
  path: '/',
  name: 'home',
  /* eslint-disable consistent-return */
  beforeEnter: async () => {
    if (await store.getters.getIsAuthen && store.state.token.accessToken) {
      return '/hjex/'
      // eslint-disable-next-line no-else-return
    } else if (await isMobile()) {
      return '/loginV2'
    } else {
      window.location.href = `${environment.getHydraApi()}oauth2/auth?client_id=${environment.getHydraClientId()}&scope=${environment.getHydraScope()}&response_type=${environment.getHydraResponseType()}&state=${environment.getHydraState()}&redirect_uri=${window.location.origin}/callback&login_uri=${window.location.origin}/loginV2`
    }
  },
  component: Home,
},
{
  path: '/barcode',
  name: 'barcode',
  component: BarcodeScanner
},
{
  path: '/barcode-pengecekan-resi',
  name: 'barcode-pengecekan-resi',
  component: BarcodeScanner
},
{
  path: '/loginV2',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    hideForAuth: true
  }
},
{
  path: '/callback',
  name: 'callback',
  component: () => import('@/views/pages/Callback.vue'),
  // meta: {
  //   // hideForAuth: true
  //   requiresAuth: true
  // }
},
{
  path: '/forgot-password',
  name: 'forgot-password',
  component: () => import('@/views/pages/ForgotPassword.vue'),
  meta: {
    hideForAuth: true
  }
},
{
  path: '/reset-password/:hashEmail(.*)',
  name: 'reset-password',
  component: () => import('@/views/pages/ResetPassword.vue'),
  meta: {
    hideForAuth: true
  }
},
{
  path: '/report/:jenisReport/:Id/:idUser?',
  name: 'report',
  component: () => import('@/views/pages/Report.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  path: '/Home/Viewer/BuktiKlaimVoucherRetail_IdTransVoucher=:id',
  name: 'report-souvenir',
  component: () => import('@/views/pages/Report.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  path: '/report-khusus-aziz/:jenisReport/:idUser?',
  name: 'report-khusus-aziz',
  component: () => import('@/views/pages/Report.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  path: '/hjex/ansurance/',
  redirect: '/hjex/ansurance/reporting',
  component: () => import('@/views/layouts/ansurance-panel/Main.vue'),
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'change-password',
      name: 'change-password-ansurance',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        group: 'REPORT',
      }
    },
    {
      path: 'reporting',
      name: 'reporting-print-ansurance',
      component: () => import('@/views/home/reporting/Reporting.vue'),
      meta: {
        group: 'REPORT'
      },
    },
    {
      path: 'reporting/:id/excel',
      name: 'reporting-data-detail-ansurance',
      component: () => import('@/views/home/reporting/ReportingDetail.vue'),
      meta: {
        group: 'REPORT',
        breadcrumb: [
          { title: 'Reporting Data', icon: icon.fileExcelRi, routeName: 'reporting-print-ansurance' },
          { title: 'Reporting Detail', icon: null, routeName: 'reporting-data-detail-ansurance' },
        ],
      }
    }
  ]
},
{
  path: '/hjex/',
  redirect: '/hjex/dashboard',
  component: () => import('@/views/layouts/panel/Main.vue'),
  meta: {
    requiresAuth: true
  },
  children: [
    // {
    //   path: 'dashboard',
    //   name: 'dashboard',
    //   component: () => import('@/views/dashboard/Dashboard.vue'),
    //   meta: {
    //     group: 'DASHBOARD',
    //   }
    // },
    {
      path: 'profil-agen',
      name: 'profil-agen',
      component: () => import('@/views/pages/ProfileAgent.vue'),
      meta: {
        group: 'REPORT',
      }
    },
    {
      path: 'change-password',
      name: 'change-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        group: 'DASHBOARD',
      }
    },
    ...mergeRoute
  ]
},
{ path: '/:pathMatch(.*)*', name: 'notFound', component: Error404 }]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const checkDateExpired = () => {
  if (store.getters.getDateAuthen && store.state.token.accessToken) {
    const dateLogin = moment(store.state.authenticated.dateAuthen)
    const now = moment()

    const checkHours = moment.duration(now.diff(dateLogin)).asHours()

    if (checkHours <= 48) {
      return true
    }
  }
  store.dispatch('logoutAccount')

  return false
}

router.beforeEach((to, from, next) => {
  store.dispatch('hideLoading')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getIsAuthen && store.state.token.accessToken) {
      if (checkDateExpired()) {
        next()
      } else {
        next({ name: 'home' })
      }
    } else {
      next({ name: 'home' })
    }
  } else if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (store.getters.getIsAuthen && store.state.token.accessToken) {
      if (checkDateExpired()) {
        if (store.getters['appActiveUser/getAppActiveUser'].id) {
          next({ name: 'dashboard' })
        } else {
          next({ name: 'reporting-print-ansurance' })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
