import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TPayloadRoute } from './routes-model'

const baseUrl = '/backend/v2/routes'

const fetchAllRoutes = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneRoutes = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addRoute = (data: TPayloadRoute) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editRoute = (id:number, data: TPayloadRoute) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

export {
  fetchAllRoutes,
  fetchOneRoutes,
  addRoute,
  editRoute
}
