import scheduleBus from './schedule-bus'
import deliveryItem from './delivery-item'
import receiveItem from './receive-item'

export default () => {
  const getScheduleBus = scheduleBus()
  const getDeliveryItem = deliveryItem()
  const getReceiveItem = receiveItem()

  return [
    ...getScheduleBus,
    ...getDeliveryItem,
    ...getReceiveItem
  ]
}
