import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(PermissionEnum.ListVoucherClaims),
    // write: checkPermission(PermissionEnum.CreateVoucher),
    // update: checkPermission(PermissionEnum.UpdateVoucher),
    // delete: checkPermission(PermissionEnum.DeleteVoucher)
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}/voucher-diskon`,
      name: 'master-voucher-diskon',
      component: () => import('@/views/master/transactions/voucher-discount/VoucherDiscountV2.vue'),
      meta: {
        group: 'TRANSAKSI',
        permission
      }
    },
    {
      path: `${val}/voucher-diskon-add`,
      name: 'master-voucher-diskon-add',
      component: () => import('@/views/master/transactions/voucher-discount/VoucherDiscountFormV2.vue'),
      meta: {
        group: 'TRANSAKSI',
        breadcrumb: [
          { title: 'Transaksi', icon: icon.moneyAttachRoundIc, routeName: 'master-harga' },
          { title: 'Voucher Diskon', icon: null, routeName: 'master-voucher-diskon' },
          { title: 'Tambah Voucher Diskon', icon: null, routeName: 'master-voucher-diskon-add' },
        ],
        permission
      }
    },
    {
      path: `${val}/voucher-diskon-edit/:idVoucher`,
      name: 'master-voucher-diskon-edit',
      component: () => import('@/views/master/transactions/voucher-discount/VoucherDiscountFormV2.vue'),
      meta: {
        group: 'TRANSAKSI',
        breadcrumb: [
          { title: 'Transaksi', icon: icon.moneyAttachRoundIc, routeName: 'master-harga' },
          { title: 'Voucher Diskon', icon: null, routeName: 'master-voucher-diskon' },
          { title: 'Edit Voucher Diskon', icon: null, routeName: 'master-voucher-diskon-edit' },
        ],
        permission
      }
    },
    {
      path: `${val}/history-redeem-souvenir`,
      name: 'master-voucher-diskon-history-redeem-souvenir',
      component: () => import('@/views/master/transactions/voucher-discount/HistoryRedeemSouvenir.vue'),
      meta: {
        group: 'TRANSAKSI',
        breadcrumb: [
          { title: 'Transaksi', icon: icon.moneyAttachRoundIc, routeName: 'master-harga' },
          { title: 'Voucher Diskon', icon: null, routeName: 'master-voucher-diskon' },
          { title: 'Riwayat Redeem', icon: null, routeName: 'master-voucher-diskon-history-redeem-souvenir' },
        ],
        permission: {
          // read: checkPermission(PermissionEnum.GetVoucherClaim),
          // write: checkPermission(PermissionEnum.CreateVoucherClaim),
          // update: checkPermission(),
          // delete: checkPermission()
          read: true,
          write: true,
          update: true,
          delete: true
        }
      }
    },
  ]

  return listRoute
}
