import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = 'penalty/v1/payments'

// const fetchAllPenalties = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addPenaltyPayment = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const approvePenaltyPayment = (id: number) => new Remote(remoteEnum.put, `${baseUrl}/${id}/confirm`)
const rejectPenaltyPayment = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/reject`, data)

export {
  // fetchAllPenalties,
  addPenaltyPayment,
  approvePenaltyPayment,
  rejectPenaltyPayment
}
