/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllJadwalDetail,
  fetchOneJadwalDetail,
  addJadwalDetail,
  editJadwalDetail,
  deleteJadwalDetail,
  addJadwalDetailMultiple,
  fetchAllAgen,
  fetchAllTanggalPenerimaan
} from '@/data/source/remote/api'

class ScheduleDetailUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = null): Promise<Response> {
    // const query = `?$count=true&$filter=JadwalHdId eq ${filter.id} and (Contains(tolower(Agen/Nama),'${filter.search}') and Contains(tolower(Agen/Kode), '${filter.search}' ))&$expand=Agen($select=Kode,Nama,Id),JadwalHD($select=KodeJadwal,Id,TanggalAktif),TanggalPenerimaan($select=Id,Kode,Nama,Hari)`
    // const query = `?$count=true&$filter=JadwalHdId eq ${filter.id} and (Contains(tolower(Agen/Nama),'${filter.search}') or Contains(tolower(Agen/Kode), '${filter.search}' ))&$expand=Agen($select=Kode,Nama,Id),JadwalHD($select=KodeJadwal,Id,TanggalAktif),TanggalPenerimaan($select=Id,Kode,Nama,Hari)&$orderBy=${filter.orderBy}`
    const query = `?$count=true&$filter=JadwalHdId eq ${filter.id} and (Contains(tolower(Agen/Nama),'${filter.search}') or Contains(tolower(Agen/Kode), '${filter.search}' ))&$expand=Agen($select=Kode,Nama,Id),JadwalHD($select=KodeJadwal,Id,TanggalAktif),TanggalPenerimaan($select=Id,Kode,Nama,Hari)`
    return new Repository(fetchAllJadwalDetail(query), null).getResult(false)
  }

  async getDataForm(id: any): Promise<Response> {
    return new Repository(fetchOneJadwalDetail(id), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== null) {
      data.Id = id
      return new Repository(editJadwalDetail(id, data), null).getResult(false)
    } return new Repository(addJadwalDetail(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteJadwalDetail(id), null).getResult(false)
  }

  addMultipleData(data: any): Promise<Response> {
    return new Repository(addJadwalDetailMultiple(data), null).getResult(false)
  }

  getDataOptionAgent(): Promise<Response> {
    const query = '?$count=true&$filter=IsActive eq true&$select=Id,Alamat,Nama,Kode,KodeResi'
    return new Repository(fetchAllAgen(query), null).getResult(false)
  }

  getDataOptionTanggalPenerimaan(): Promise<Response> {
    const query = '?$count=true&$filter=IsActive eq true&$select=Id,Kode,Nama,Hari'
    return new Repository(fetchAllTanggalPenerimaan(query), null).getResult(false)
  }
}

const transScheduleDetailUseCase = new ScheduleDetailUseCase()

export {
  transScheduleDetailUseCase
}
