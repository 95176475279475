import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}`,
      name: 'transaksi-barang-diterima',
      component: () => import('@/views/transactions/receive-item/ReceiveItem.vue'),
      meta: {
        group: 'BARANG DITERIMA',
        permission
      }
    },
    {
      path: `${val}/:id`,
      name: 'transaksi-barang-diterima-detail',
      component: () => import('@/views/transactions/receive-item/detail/ReceiveItemDetail.vue'),
      meta: {
        group: 'BARANG DITERIMA',
        breadcrumb: [
          { title: 'Barang Diterima', icon: icon.boxOpenUicons, routeName: 'transaksi-barang-diterima' },
          { title: 'Detail Penerimaan', icon: null, routeName: 'transaksi-barang-diterima-detail' },
        ],
        permission
      }
    }
  ]

  return listRoute
}
