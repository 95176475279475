/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllGlobalSetting,
  fetchOneGlobalSetting,
  editGlobalSetting,
  editAllGlobalSetting
} from '@/data/source/remote/api'

class GlobalSettingUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      // if (filter.search) {
      //   arrayFilter.push(`(Contains(tolower(Nama), '${filter.search}'))`)
      // }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    const response = new Repository(fetchAllGlobalSetting(), null).getResult(false)
    return response
  }

  getDataForm(id: any): Promise<any> {
    return new Repository(fetchOneGlobalSetting(id), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(editGlobalSetting(id, data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  submitAllData(data: any): Promise<Response> {
    return new Repository(editAllGlobalSetting(data), null).getResult(false)
  }
}

const globalSettingUseCase = new GlobalSettingUseCase()

export {
  globalSettingUseCase
}
