import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsPelanggan'

const fetchAllPelanggan = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOnePelanggan = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addPelanggan = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPelanggan = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deletePelanggan = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActivePelanggan = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllPelanggan,
  fetchOnePelanggan,
  addPelanggan,
  editPelanggan,
  deletePelanggan,
  changeIsActivePelanggan
}
