import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKomisiKurirJenisKendaraan'

const fetchAllKomisiJenisKendaraan = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKomisiJenisKendaraan = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addKomisiJenisKendaraan = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKomisiJenisKendaraan = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKomisiJenisKendaraan = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllKomisiJenisKendaraan,
  fetchOneKomisiJenisKendaraan,
  addKomisiJenisKendaraan,
  editKomisiJenisKendaraan,
  deleteKomisiJenisKendaraan
}
