import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  listRoute.push(
    {
      path: `${val}/comission-payment/manual`,
      name: 'comission-manual-payment',
      component: () => import('@/views/master/courier/comission-payment/manual-payment/ManualPayment.vue'),
      meta: {
        // breadcrumb: [
        //   // { title: 'Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
        //   { title: 'Master Kurir', icon: icon.placeOutlineIc, routeName: 'master-courier' },
        //   { title: 'Tracking Kurir', icon: null, routeName: 'courier-tracking' },
        // ],
        group: 'Kurir',
        permission
      }
    }
  )
  return listRoute
}
