import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/mslabels'

const fetchAllLabelV2 = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneLabelV2 = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addLabelV2 = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editLabelV2 = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteLabelV2 = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveLabelV2 = (id: number, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)

export {
  fetchAllLabelV2,
  fetchOneLabelV2,
  addLabelV2,
  editLabelV2,
  deleteLabelV2,
  changeIsActiveLabelV2
}
