import iconDependency from '@/dependencies/iconDependency'
import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}`,
      name: 'courier-pricelist',
      component: () => import('@/views/home/courier-pricelist/CourierPricelist.vue'),
      meta: {
        group: 'Cek Harga Pengiriman',
        permission
      }
    }
  ]

  return listRoute
}
