import iconDependency from '@/dependencies/iconDependency'
import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const company = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  const retail = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = []
  // if (checkPermission('*:*')) {
  if (company.read || retail.read) {
    listRoute.push({
      path: `${val}/pelanggan`,
      name: 'master-pelanggan',
      component: () => import('@/views/master/customers/customer/Customer.vue'),
      meta: {
        group: 'PELANGGAN',
        permission: {
          retail,
          company
        } as any
      },
    })
  }
  // }

  // if (checkPermission('*:*')) {
  //   if (checkPermission('*:*')) {
  if (company.read) {
    if (company.read) {
      listRoute.push(
        {
          path: `${val}/pelanggan-perusahaan/detail/:idCompany`,
          name: 'master-pelanggan-perusahaan-detail',
          component: () => import('@/views/master/customers/customer/customer-company/detail/DetailPelangganCompany.vue'),
          meta: {
            group: 'PELANGGAN',
            breadcrumb: [
              { title: 'Pelanggan', icon: icon.buildingFe, routeName: 'master-pelanggan' },
              { title: 'Detail Pelanggan Perusahaan', icon: null, routeName: 'master-pelanggan-perusahaan-detail' },
            ],
            permission: {
              // read: checkPermission(),
              // write: checkPermission(),
              // update: checkPermission(),
              // delete: checkPermission()
              read: true,
              write: true,
              update: true,
              delete: true
            }
          }
        }
      )
      // if (checkPermission('*:*')) {
      if (company.write) {
        listRoute.push({
          path: `${val}/pelanggan-perusahaan/tambah`,
          name: 'master-pelanggan-perusahaan-tambah',
          component: () => import('@/views/master/customers/customer/CustomerForm.vue'),
          meta: {
            group: 'PELANGGAN',
            breadcrumb: [
              { title: 'Pelanggan', icon: icon.buildingFe, routeName: 'master-pelanggan' },
              { title: 'Tambah Pelanggan Perusahaan', icon: null, routeName: 'master-pelanggan-perusahaan-tambah' },
            ],
            permission: {
              // read: checkPermission(),
              // write: checkPermission(),
              // update: checkPermission(),
              // delete: checkPermission()
              read: true,
              write: true,
              update: true,
              delete: true
            }
          }
        })
      }
      // if (checkPermission('*:*')) {
      if (company.update) {
        listRoute.push({
          path: `${val}/pelanggan-perusahaan/edit/:idUser`,
          name: 'master-pelanggan-perusahaan-edit',
          component: () => import('@/views/master/customers/customer/CustomerForm.vue'),
          meta: {
            group: 'PELANGGAN',
            breadcrumb: [
              { title: 'Pelanggan', icon: icon.buildingFe, routeName: 'master-pelanggan' },
              { title: 'Edit Pelanggan Perusahaan', icon: null, routeName: 'master-pelanggan-perusahaan-edit' },
            ],
            permission: {
              // read: checkPermission(),
              // write: checkPermission(),
              // update: checkPermission(),
              // delete: checkPermission()
              read: true,
              write: true,
              update: true,
              delete: true
            }
          }
        })
      }
    }
  }

  // if (checkPermission('*:*')) {
  //   if (checkPermission('*:*')) {
  if (retail.read) {
    if (retail.read) {
      listRoute.push({
        path: `${val}/pelanggan-retail/tambah`,
        name: 'master-pelanggan-retail-tambah',
        component: () => import('@/views/master/customers/customer/CustomerForm.vue'),
        meta: {
          group: 'PELANGGAN',
          breadcrumb: [
            { title: 'Pelanggan', icon: icon.buildingFe, routeName: 'master-pelanggan' },
            { title: 'Tambah Pelanggan Retail', icon: null, routeName: 'master-pelanggan-retail-tambah' },
          ],
          permission: {
            // read: checkPermission(),
            // write: checkPermission(),
            // update: checkPermission(),
            // delete: checkPermission()
            read: true,
            write: true,
            update: true,
            delete: true
          }
        }
      })
    }

    // if (checkPermission()) {
    if (retail.update) {
      listRoute.push({
        path: `${val}/pelanggan-retail/edit/:idUser`,
        name: 'master-pelanggan-retail-edit',
        component: () => import('@/views/master/customers/customer/customer-retail/CustomerRetailForm.vue'),
        meta: {
          group: 'PELANGGAN',
          breadcrumb: [
            { title: 'Pelanggan', icon: icon.buildingFe, routeName: 'master-pelanggan' },
            { title: 'Edit Pelanggan Retail', icon: null, routeName: 'master-pelanggan-retail-edit' },
          ],
          permission: {
            // read: checkPermission(),
            // write: checkPermission(),
            // update: checkPermission(),
            // delete: checkPermission()
            read: true,
            write: true,
            update: true,
            delete: true
          }
        }
      })
    }
  }
  return listRoute
}
