/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllStockSticker,
  fetchOneStockSticker,
  addStockSticker,
  editStockSticker,
  deleteStockSticker,
  fetchAllLogStockLabel,
  addStockStickerAgent
} from '@/data/source/remote/api'

class StickerAgentUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Sticker/Nama),'${filter.search.toLowerCase()}') or Contains(tolower(Sticker/Kode),'${filter.search.toLowerCase()}')) `)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllStockSticker(params), null).getResult(false)
  }

  getLogLabel(filter: any = null): Promise<Response> {
    let params = '?$count=true'

    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(KeteranganTambahan),'${filter.search.toLowerCase()}') or Contains(tolower(Agen/Nama), '${filter.search.toLowerCase()}')) `)
      }
      if (filter.createDate) {
        arrFilter.push(filter.createDate)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllLogStockLabel(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneStockSticker(id), null).getResult(false)
      return {
        title: 'Edit Stiker',
        data: response
      }
    }

    return {
      title: 'Tambah Stiker',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== null) {
      data.Id = id

      return new Repository(editStockSticker(id, data), null).getResult(false)
    }
    return new Repository(addStockSticker(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteStockSticker(id), null).getResult(false)
  }

  tambahStockStickerAgent(id: any): Promise<Response> {
    return new Repository(addStockStickerAgent(id), null).getResult(false)
  }
}

const stickerAgentUseCase = new StickerAgentUseCase()

export {
  stickerAgentUseCase
}
