import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsKategoriKlaim'

const fetchAllKategoriKlaim = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKategoriKlaim = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addKategoriKlaim = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKategoriKlaim = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKategoriKlaim = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllKategoriKlaim,
  fetchOneKategoriKlaim,
  addKategoriKlaim,
  editKategoriKlaim,
  deleteKategoriKlaim
}
