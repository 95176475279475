/* eslint-disable class-methods-use-this */
// import networkBoundResource from '@/data/networkBoundResource'
import store from '@/store'
import { Response } from '@/domain/entities'

class ResponseGoogle {
  remote: any

  sourceDb: any

  savingData: any

  constructor(remote: any, sourceDb: any, savingData: any = null) {
    this.remote = remote
    this.sourceDb = sourceDb
    if (savingData) {
      this.savingData = savingData
    }
  }

  private result = async (haveLocal: boolean) => {
    const data = new Response()
    if (store.state.network.connection) {
      try {
        const fetch = await this.fetchData().run()
        const code = fetch.status
        const result = fetch.data
        if (result.status === 'OK') {
          data.result = result
          data.error = false
          data.message = 'Berhasil ditampilkan'
        } else {
          data.message = result.error_message
          data.error = true
        }
        data.code = code
      } catch (e) {
        if (e.response.status === 400) {
          data.error = true
          data.message = 'Gagal menampilkan'
          data.code = (e.response) ? e.request.status : 400
        } else {
          data.error = true
          data.message = 'Gagal menampilkan'
          data.code = (e.response) ? e.request.status : 500
        }
      }
      if (haveLocal) {
        this.saveResultData(data)
      }
    } else if (haveLocal) {
      data.result = await this.loadFromDB()
    } else {
      store.dispatch('setNoInternet', true)
    }

    return data
  }

  loadFromDB() {
    return this.sourceDb
  }

  fetchData() {
    return this.remote
  }

  saveResultData(data: any): void {
    this.sourceDb.changeData(data)
    this.loadFromDB().run()
  }

  getResult = async (haveLocal: boolean): Promise<Response> => {
    const data = await this.result(haveLocal)
    return data
  }
}

export default ResponseGoogle
