/*
Kode => Generate Manual Kode
Persen => Salah Satu antara Persentase dan Potongan Harga
Potongan  => Nomibal Potongan
Colom Persen & Potongan sama dengan kolom kiri
Max Potongan => Kolom Kanan selalu diisi
Qty => Jumlah Voucher
UseLimits => Maks penggunaan tiap pengguna
IsMoney => Jenis Pototngan
GenerateRefCode => Boolean dri pilihan Generate Kode Random */
export default {
  Kode: null,
  Nama: null,
  Keterangan: null,
  Discount: 0,
  MaxPotongan: 0,
  Qty: 1,
  UseLimit: 0,
  Penggunaan: 0,
  Poin: 0,
  GenerateRefCode: true,
  IsMoney: false,
  IsActive: true,
  IsHidden: true,
  Gambar: {
    name: 'add-image',
    src: null,
    image: null
  },
  Type: null,
  MsVoucherDetails: [
    // {
    //   VoucherPramId: null,
    //   Nilai: null
    // }
  ]
}
