import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(PermissionEnum.ListGoodCategory),
    // write: checkPermission(PermissionEnum.CreateGoodCategory),
    // update: checkPermission(PermissionEnum.UpdateGoodCategory),
    // delete: checkPermission(PermissionEnum.DeleteGoodCategory)
    read: true,
    write: true,
    update: true,
    delete: true
  }

  if (checkPermissionObj(permission)) {
    listRoute.push({
      path: `${val}/kategori-barang`,
      name: 'master-kategori-barang',
      component: () => import('@/views/master/items/item-category/index.vue'),
      meta: {
        group: 'BARANG',
        permission
      }
    })
  }
  return listRoute
}
