import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => {
  const permission = {
    read: checkPermission(Permision.ViewDashboard),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    // read: true,
    write: true,
    update: true,
    delete: true
  }

  const listRoute = [
    {
      path: `${val}`,
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        group: 'DASHBOARD',
        permission
      }
    }
  ]

  return listRoute
}
