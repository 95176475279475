import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPenerimaanFile'

const fetchAllPenerimaanFile = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addPenerimaanFile = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/UploadSingle`, data)
const deletePenerimaanFile = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllPenerimaanFile,
  addPenerimaanFile,
  deletePenerimaanFile
}