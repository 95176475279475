import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/setting/v1/GlobalSettings'

const fetchAllGlobalSetting = () => new Remote(remoteEnum.get, `${baseUrl}`)

const fetchOneGlobalSetting = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)

const editGlobalSetting = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)

const editAllGlobalSetting = (data: any) => new Remote(remoteEnum.put, `${baseUrl}`, data)

export {
  fetchAllGlobalSetting,
  fetchOneGlobalSetting,
  editGlobalSetting,
  editAllGlobalSetting
}
