/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import { addCourierFile } from '@/data/source/remote/api'
import ICrud from '@/domain/usecase/iCrud'
import Repository from '@/data/repository'

class CourierFileUseCase implements ICrud {
  getAll(filter: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitDataAll(list: any): Promise<any> {
    return Promise.all(list)
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id) {
      data.append('KurirId', id)
    }
    return new Repository(addCourierFile(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const courierFileUseCase = new CourierFileUseCase()
export {
  courierFileUseCase
}
