export interface IFilterPrams {
  search: string
  filterField: string[]
  custom: string[][]
  pageSize: number
  pageNumber: number,
  isSearcLowerCase: boolean
}

export default ({
  search,
  filterField,
  custom,
  pageSize,
  pageNumber,
  isSearcLowerCase
}: Partial<IFilterPrams> = {
  search: '',
  filterField: [],
  custom: [],
  pageSize: 0,
  pageNumber: 0,
  isSearcLowerCase: true
}): string => {
  const arrParams = []
  let paramsString = ''

  if (search) {
    if (filterField && filterField?.length > 0) {
      filterField.forEach((val: any) => {
        arrParams.push(`${val}=${isSearcLowerCase ? search.toLowerCase() : search}`)
      })
    }
  }
  if (custom && custom?.length > 0) {
    custom.forEach((fil: string[]) => {
      if (fil.length > 0) {
        arrParams.push(`${fil[0]}${fil[1]}${fil[2]}`)
      }
    })
  }
  if (pageSize && pageSize > 0) {
    arrParams.push(`pageSize=${pageSize}`)
  }
  if (pageNumber && pageNumber > 0) {
    arrParams.push(`pageNumber=${pageNumber}`)
  }
  if (arrParams.length > 0) {
    paramsString = `?${arrParams.join('&')}`
  }

  return paramsString
}
