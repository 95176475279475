import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  if (permission.read) {
    listRoute.push(
      {
        path: `${val}/monitoring-package`,
        name: 'monitoring-package',
        component: () => import('@/views/package-order/new-package/NewPackage.vue'),
        meta: {
          group: 'ORDERAN PAKET',
          permission
        },
      }
    )
  }

  return listRoute
}
