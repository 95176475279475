/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import { addPenerimaanFile, addPenerimaanFiles, uploadCustomPath, uploadSingle } from '@/data/source/remote/api'
import ICrud from '@/domain/usecase/iCrud'
import Repository from '@/data/repository'

class ReceiveItemFileUseCase implements ICrud {
  getAll(filter: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitDataAll(list: any): Promise<any> {
    return Promise.all(list)
  }

  submitData(id: any, data: any): Promise<Response> {
    data.append('PenerimaanId', id)
    return new Repository(addPenerimaanFile(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  submitDataPenerimaanFiles(id: any, data: any): Promise<Response> {
    data.PenerimaanId = id
    return new Repository(addPenerimaanFiles(data), null).getResult(false)
  }

  submitFile(type: any, data: any): Promise<Response> {
    return new Repository(uploadCustomPath(type, data), null).getResult(false)
  }

  submitFileSingle(data: any): Promise<Response> {
    return new Repository(uploadSingle(data), null).getResult(false)
  }
}

const receiveItemFileUseCase = new ReceiveItemFileUseCase()
export {
  receiveItemFileUseCase
}