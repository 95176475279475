/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllAdditionalFee, fetchOneAdditionalFee, addAdditionalFee, editAdditionalFee, deleteAdditionalFee, changeIsActiveAdditionalFee
} from '@/data/source/remote/api'

class AdditionalFeeUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveAdditionalFee(data.Id), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Nama),'${filter.search.toLowerCase()}') or Contains(tolower(Kode), '${filter.search.toLowerCase()}') or Contains(tolower(Keterangan), '${filter.search.toLowerCase()}'))`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllAdditionalFee(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneAdditionalFee(id), null).getResult(false)
      return {
        title: 'Edit Biaya tambahan',
        data: response
      }
    }

    return {
      title: 'Tambah Biaya Tambahan',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id

      return new Repository(editAdditionalFee(id, data), null).getResult(false)
    }

    data = {
      Nama: data.Nama,
      Kode: data.Kode,
      Keterangan: data.Keterangan,
      IsActive: data.IsActive
    }

    return new Repository(addAdditionalFee(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteAdditionalFee(id), null).getResult(false)
  }
}

const additionalFeeUseCase = new AdditionalFeeUseCase()

export {
  additionalFeeUseCase
}
