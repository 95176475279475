import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPenerimaan'

const fetchAllPenerimaan = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOnePenerimaan = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addPenerimaan = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editPenerimaan = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deletePenerimaan = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllPenerimaan,
  fetchOnePenerimaan,
  addPenerimaan,
  editPenerimaan,
  deletePenerimaan
}