import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsAsuransi'

const fetchAllAsuransi = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneAsuransi = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addAsuransi = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editAsuransi = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const changeIsActiveAsuransi = (data: any) => new Remote(remoteEnum.patch, `${baseUrl}/IsActive`, data)

export {
  fetchAllAsuransi,
  fetchOneAsuransi,
  addAsuransi,
  editAsuransi,
  changeIsActiveAsuransi
}
