/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllAbsensiCourier, fetchOneAbsensiCourier, addAbsensiCourier, editAbsensiCourier, deleteAbsensiCourier, changeIsActiveAbsensiCourier, createAbsensiKey
} from '@/data/source/remote/api'
import lodash from 'lodash'

class CourierScheduleUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    // return new Repository(changeIsActiveAbsensiCourier, createAbsensiKey(data), null).getResult(false)
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        // arrayFilter.push(`Contains(tolower(Nama),'${filter.search}')`)
        arrayFilter.push(`(Contains(tolower(Username),'${filter.search}') or Contains(tolower(Handphone), '${filter.search}') or Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Attribute2), '${filter.search}') or Contains(tolower(Attribute3), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      if (filter.top) {
        params = `${params}&$top=${filter.top}`
      }
      if (filter.skip) {
        params = `${params}&$skip=${filter.skip}`
      }
    }
    return new Repository(fetchAllAbsensiCourier(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneAbsensiCourier(id), null).getResult(false)
      return {
        title: 'Edit Stiker',
        data: response
      }
    }

    return {
      title: 'Tambah Stiker',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id != null) {
      data.Id = Number(id)
      return new Repository(editAbsensiCourier(id, data), null).getResult(false)
    }
    return new Repository(addAbsensiCourier(data), null).getResult(false)
  }

  createCourierAbsensiKey(data: any): Promise<Response> {
    return new Repository(createAbsensiKey(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteAbsensiCourier(id), null).getResult(false)
  }
}

const courierScheduleUseCase = new CourierScheduleUseCase()

export {
  courierScheduleUseCase
}
