/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllKategoriKlaim,
  addKategoriKlaim,
  editKategoriKlaim,
  deleteKategoriKlaim,
  fetchTransLastKlaim,
  addTransKlaim,
  editTransKlaim,
  fetchAllPenalties,
  addPenalty,
  approvePenalty,
  addPenaltyPayment,
  fetchPenaltyPayment,
  rejectPenaltyPayment,
  approvePenaltyPayment,
  fetchPenaltyReferences
} from '@/data/source/remote/api'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

class ClaimUsecase implements ICrud {
  getAll(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(Nama),'${filter.search.toLowerCase()}') or Contains(tolower(Kode), '${filter.search.toLowerCase()}'))`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    const response = new Repository(fetchAllKategoriKlaim(params), null).getResult(false)
    return response
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      return new Repository(editKategoriKlaim(id, data), null).getResult(false)
    }
    return new Repository(addKategoriKlaim(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteKategoriKlaim(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getTransLastKlaim(filter: any = null): Promise<Response> {
    let params = '?'
    if (filter) {
      if (filter.search) {
        params += `namaAgen=${filter.search}`
      }
      if (filter.pageNumber) {
        params += `&pageNumber=${filter.pageNumber}`
      }
    }
    return new Repository(fetchTransLastKlaim(params), null).getResult(false)
  }

  submitDataTransKlaim(id: any = null, data: any): Promise<Response> {
    if (id !== null) {
      data.Id = id
      return new Repository(editTransKlaim(id, data), null).getResult(false)
    }
    return new Repository(addTransKlaim(data), null).getResult(false)
  }

  getAllTransKlaim(filter: any = null): Promise<Response> {
    console.log(filter)
    return new Repository(fetchAllPenalties(returnUrlPrams(filter)), null).getResult(false)
  }

  submitDataPenalty(data: any): Promise<Response> {
    return new Repository(addPenalty(data), null).getResult(false)
  }

  approveDataPenalty(id: any, data: any): Promise<Response> {
    return new Repository(approvePenalty(id, data), null).getResult(false)
  }

  getPenaltyPayment(id: any, filter: any = null): Promise<Response> {
    return new Repository(fetchPenaltyPayment(id, returnUrlPrams(filter)), null).getResult(false)
  }

  getPenaltyReferences(id: any): Promise<Response> {
    return new Repository(fetchPenaltyReferences(id), null).getResult(false)
  }

  submitDataPayment(data: any): Promise<Response> {
    return new Repository(addPenaltyPayment(data), null).getResult(false)
  }

  approvePenaltyPayment(id: any): Promise<Response> {
    return new Repository(approvePenaltyPayment(id), null).getResult(false)
  }

  rejectPenaltyPayment(id: any, data: any): Promise<Response> {
    return new Repository(rejectPenaltyPayment(id, data), null).getResult(false)
  }
}

const claimUseCase = new ClaimUsecase()

export {
  claimUseCase
}
