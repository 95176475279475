import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsUser'

const loginApi = () => new Remote(remoteEnum.post, `${baseUrl}/LoginUser`)
const fetchAllUser = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneUser = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addUser = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editUser = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteUser = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveUser = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  loginApi,
  fetchAllUser,
  fetchOneUser,
  addUser,
  editUser,
  deleteUser,
  changeIsActiveUser
}
