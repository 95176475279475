import { IQueryParam } from '../interfaces/iQueryParam'

export default ({
  count = false,
  select = '',
  expand = ''
}: IQueryParam) => {
  const arrParams: string[] = []
  const arrFilters: [] = []
  let paramsString = ''

  if (count) {
    arrParams.push('count=true')
  }

  if (select) {
    arrParams.push(`select=${select}`)
  }
  if (expand) {
    arrParams.push(`expand=${expand}`)
  }
  if (arrFilters.length > 0) {
    arrParams.push(`filter=${arrFilters.join(' and ')}`)
  }

  if (arrParams.length > 0) {
    paramsString = `?${arrParams.join('&$')}`
  }

  return paramsString
}
