import { App } from 'vue'
import VueClipboard from 'vue3-clipboard'

export default {
  install: (app: App < Element >) => {
    const vue = app
    vue.use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    })
  }
}
