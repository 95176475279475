import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

export default (val = '') => [
  {
    path: `${val}/tanggal-penerimaan`,
    name: 'master-tanggal-penerimaan',
    component: () => import('@/views/master/transactions/date-receipt/DateReceipt.vue'),
    meta: {
      group: 'TRANSAKSI',
      permission: {
        // read: checkPermission(),
        // write: checkPermission(),
        // update: checkPermission(),
        // delete: checkPermission()
        read: true,
        write: true,
        update: true,
        delete: true
      }
    }
  }
]
