import { environment } from '@/utils'
import axios from 'axios'
// import { cacheAdapterEnhancer } from 'axios-extensions'
import { setupCache } from 'axios-cache-adapter'
import setup from './setupInterceptors'

// const cacheConfig = {
//   enabledByDefault: false,
//   cacheFlag: 'useCache'
// }

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// })

const API_URL = environment.getApiVoucherV2()
const axl = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: '*/*'
  }
})

axl.interceptors.request.use((config: any) => config, (error: any) => Promise.reject(error))

setup(axl)

export default axl
