import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex items-center items-stretch w-100 input-icon-left-white" }
const _hoisted_2 = { class: "background-grey-lighten-4 px-2 items-center flex left-radius" }
const _hoisted_3 = {
  key: 1,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_Password = _resolveComponent("Password")
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            innerHTML: _ctx.icon
          }, null, 8, ["innerHTML"]))
        : (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("p", null, _toDisplayString(_ctx.label), 1)
          ]))
    ]),
    (_ctx.type == this.$enumBase.input.text)
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 0,
          type: "text",
          class: "none-left-radius right-radius flex-auto",
          placeholder: _ctx.placeholder,
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.input = $event))
        }, null, 8, ["placeholder", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.type == this.$enumBase.input.password)
      ? (_openBlock(), _createBlock(_component_Password, {
          key: 1,
          class: "flex-auto",
          toggleMask: true,
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.input = $event)),
          placeholder: _ctx.placeholder
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true),
    (_ctx.type == this.$enumBase.input.select)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 2,
          options: _ctx.options,
          optionLabel: "name",
          optionValue: "id",
          class: "flex-auto",
          placeholder: _ctx.placeholder,
          modelValue: _ctx.input,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.input = $event))
        }, null, 8, ["options", "placeholder", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}