import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransJadwalHd'

const fetchAllJadwalHd = (filter : any = '') => new Remote(remoteEnum.get, `${baseUrl}?${filter}`)
const fetchOneJadwalHd = (id: number, filter: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addJadwalHd = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const addJadwalHdMultiple = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/MultipleJadwal`, data)
const addJadwalHdDuplicate = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/DuplicateJadwal`, data)
const editJadwalHd = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteJadwalHd = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveJadwalHd = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
const searchJadwalHd = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}/PencarianJadwal${filter}`)
const penambahanWaktu = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/PenambahanWaktu`, data)
const closeJadwal = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/CloseJadwal/${id}`, data)

export {
  fetchAllJadwalHd,
  fetchOneJadwalHd,
  addJadwalHd,
  editJadwalHd,
  deleteJadwalHd,
  addJadwalHdMultiple,
  addJadwalHdDuplicate,
  changeIsActiveJadwalHd,
  searchJadwalHd,
  penambahanWaktu,
  closeJadwal
}
