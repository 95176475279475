import { Remote, remoteEnum } from '@/data/source/remote/remoteCompany'

const baseUrl = 'MsUsers'

const fetchAllUsersCompany = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneUsersCompany = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchMinimalInfoUsersCompany = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/MinimalInfo${filter}`)
const fetchMinimalInfoUnverifiedUsersCompany = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/MinimalInfoUnverified${filter}`)
const fetchProfileUsersCompany = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/Profile/${id}`)
const addUsersCompany = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editUsersCompany = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteUsersCompany = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveUsersCompany = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
const verifyUsersCompany = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/VerifyV2/${id}`, data)
const changePaylaterLimitUsersCompany = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/PaylaterLimit/${id}`, data)
const changeLevelDiskonUsersCompany = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/LevelDiskon/${id}`, data)
const changeIsCanPayLaterUsersCompany = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/IsCanPayLater/${id}`, data)
const changeCutoffDateUsersCompany = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/PaylaterCutoffDate/${id}`, data)
const fetchListMinimalInfoUsersCompany = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/List${filter}`)
const generateManualCutOffTOP = (id: any) => new Remote(remoteEnum.post, `${baseUrl}/ManualCutoff/${id}`)
const withdrawDeposit = (id: any) => new Remote(remoteEnum.post, `${baseUrl}/RefundDeposit/${id}`)

export {
  fetchAllUsersCompany,
  fetchOneUsersCompany,
  addUsersCompany,
  editUsersCompany,
  deleteUsersCompany,
  changeIsActiveUsersCompany,
  fetchMinimalInfoUsersCompany,
  fetchProfileUsersCompany,
  verifyUsersCompany,
  fetchMinimalInfoUnverifiedUsersCompany,
  changePaylaterLimitUsersCompany,
  changeLevelDiskonUsersCompany,
  changeIsCanPayLaterUsersCompany,
  changeCutoffDateUsersCompany,
  fetchListMinimalInfoUsersCompany,
  generateManualCutOffTOP,
  withdrawDeposit
}
