import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransManifest'

const fetchAllManifest = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchAllListManifest = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}/ListManifest${filter}`)
const fetchAllListManifestV2 = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}/ListManifestV2${filter}`)
const fetchOneManifest = (id: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchAllLoadingManifest = (filter : any = '') => new Remote(remoteEnum.get, `${baseUrl}/LoadingManifest${filter}`)
const addManifest = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const addPendingManifest = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Pending`, data)
const addManifestList = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/TambahManifestList`, data)
const fetchAllManifestOneReceipt = (data:any) => new Remote(remoteEnum.post, `${baseUrl}/PendingAll`, data)

export {
  fetchAllManifest,
  fetchOneManifest,
  fetchAllLoadingManifest,
  fetchAllListManifest,
  addPendingManifest,
  addManifestList,
  addManifest,
  fetchAllManifestOneReceipt,
  fetchAllListManifestV2
}