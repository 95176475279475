import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsGroupUserAuth'

const fetchAllGroupUserAuth = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneGroupUserAuth = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addGroupUserAuth = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editGroupUserAuth = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteGroupUserAuth = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchGroupUserAuthByGroupId = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/GroupedByGroupName`)
const editGroupUserAuthByGroupId = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/GroupedByGroupName`, data)

export {
  fetchAllGroupUserAuth,
  fetchOneGroupUserAuth,
  addGroupUserAuth,
  editGroupUserAuth,
  deleteGroupUserAuth,
  fetchGroupUserAuthByGroupId,
  editGroupUserAuthByGroupId
}
