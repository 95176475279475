/* eslint-disable class-methods-use-this */
import {
  fetchAllKategoriBarang,
  editKategoriBarangV2,
  fetchOneKategoriBarang,
  addKategoriBarangV2,
  changeIsActiveKategoriBarang,
  fetchAllSatuan,
  deleteKategoriBarangV2,
  fetchKategoriTipeAgent,
  fetchAllProfileGroupUser,
  fetchAllKategoriBarangV2,
  editPartialKategoriBarangV2,
  fetchOneKategoriBarangV2
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import RepositoryV2 from '@/data/repositoryV2'
import { TPayloadSaveMsKategoriBarang, TResultMsKategoriBarangDetail } from '@/data/source/remote/api/backend/V2/msKategoriBarang-model'
import returnUrlPramsOdata from '@/utils/helpers/returnUrlPramsOdata'

const getAllItemsKategoriBarang = (filter: any = null) => {
  let params = ''
  if (filter) {
    if (filter.expand) {
      params = `${params}${filter.expand}`
    }
    if (filter.select) {
      params = `${params}${filter.select}`
    }

    if (filter.top) {
      params = `${params}&$top=${filter.top}`
      params = `${params}&$skip=${filter.skip}`
    }

    const arrayFilter = []
    if (filter.search) {
      arrayFilter.push(`(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))`)
    }
    if (filter.filter) {
      arrayFilter.push(filter.filter)
    }
    if (filter.custom) {
      arrayFilter.push(filter.custom)
    }

    if (arrayFilter.length > 0) {
      params = `${params}&$filter=${arrayFilter.join(' and ')}`
    }
  }
  return new Repository(fetchAllKategoriBarang(`?$count=true&$expand=Satuan($select=Id,Kode,Nama)${params}`), null).getResult(false)
}

const getAllItemsKategoriBarangV2 = (filter: any = null) => {
  let params = '?'
  if (filter) {
    if (filter.page) {
      params = `${params}pagenumber=${filter.page}`
    }
    if (filter.search) {
      params = `${params}&name=${filter.search}`
    }
  }
  return new Repository(fetchAllKategoriBarangV2(params), null).getResult(false)
}

const getAllItemsKategoriTipeAgent = (filter: any = null) => {
  let params = '?'
  if (filter) {
    if (filter.id) {
      params = `${params}tipeAgenId=${filter.id}`
    }
    if (filter.pageNumber) {
      params = `${params}&pageNumber=${filter.pageNumber}`
    }
    if (filter.isDooring) {
      params = `${params}&isDooring=${filter.isDooring}`
    }
  }

  return new Repository(fetchAllKategoriBarangV2(params), null).getResult(false)
}

const editIsActiveKategoriBarang = (id: any, data: any) => {
  const response = new Repository(editPartialKategoriBarangV2(id, data), null).getResult(false)
  return response
}

const getDataOneKategoriBarang = (id: any, filter: any = null) => {
  let params = ''
  if (filter) {
    if (filter.expand) {
      params = `${params}${filter.expand}`
    }
    if (filter.select) {
      params = `${params}${filter.select}`
    }

    const arrayFilter = []
    if (filter.filter) {
      arrayFilter.push(filter.filter)
    }
    if (filter.custom) {
      arrayFilter.push(filter.custom)
    }

    if (arrayFilter.length > 0) {
      params = `${params}&$filter=${arrayFilter.join(' and ')}`
    }
  }
  return new Repository(fetchOneKategoriBarang(id, params), null).getResult(false)
}

class ItemCategoryUseCase {
  getDetail(id: number): Promise<Response<TResultMsKategoriBarangDetail>> {
    return new RepositoryV2<TResultMsKategoriBarangDetail>(fetchOneKategoriBarangV2(id), null).getResult(false)
  }

  getSatuan() {
    return new Repository(fetchAllSatuan(returnUrlPramsOdata({
      select: 'Kode,Nama,Id'
    })), null).getResult(false)
  }

  addOrEdit(id: number | null = null, payload: TPayloadSaveMsKategoriBarang) {
    if (id) {
      return new RepositoryV2(editKategoriBarangV2(id, payload), null).getResult(false)
    } return new RepositoryV2(addKategoriBarangV2(payload), null).getResult(false)
  }

  delete(id: number) {
    return new RepositoryV2(deleteKategoriBarangV2(id), null).getResult(false)
  }
}

const itemCategoryUseCase = new ItemCategoryUseCase()
export {
  getAllItemsKategoriBarang,
  getAllItemsKategoriBarangV2,
  editIsActiveKategoriBarang,
  getAllItemsKategoriTipeAgent,
  getDataOneKategoriBarang,
  itemCategoryUseCase
}
