/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllLevelDiskon, fetchOneLevelDiskon, addLevelDiskon, editLevelDiskon, deleteLevelDiskon, changeIsActiveLevelDiskon
} from '@/data/source/remote/api'

class DiscountRetailUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveLevelDiskon(data), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    return new Repository(fetchAllLevelDiskon('?$filter=Keterangan eq \'Retail\''), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneLevelDiskon(id), null).getResult(false)
      return {
        title: 'Edit Diskon Retail',
        data: response
      }
    }

    return {
      title: 'Tambah Diskon Retail',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      data.Keterangan = 'Retail'
      return new Repository(editLevelDiskon(id, data), null).getResult(false)
    }

    data = {
      Diskon: data.Diskon,
      Kelas: data.Kelas,
      MinTransaksi: data.MinTransaksi,
      Keterangan: 'Retail'
    }

    return new Repository(addLevelDiskon(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteLevelDiskon(id), null).getResult(false)
  }
}

const discountRetailUseCase = new DiscountRetailUseCase()

export {
  discountRetailUseCase
}
