import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsSatuan'

const fetchAllSatuan = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneSatuan = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addSatuan = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editSatuan = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteSatuan = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveSatuan = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllSatuan,
  fetchOneSatuan,
  addSatuan,
  editSatuan,
  deleteSatuan,
  changeIsActiveSatuan
}
