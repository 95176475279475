import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
// import { TPayloadPrice } from './prices-model'

const baseUrl = '/backend/v2/shippingrates'

const fetchShippingRates = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)

export {
  fetchShippingRates
}
