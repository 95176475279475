/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import { deleteTransPengirimanDetail, editMultipleTransPengirimanDetail, addCustomTransPengirimanDetail } from '@/data/source/remote/api'

class TransPengirimanDetailUseCase {
  addCustom(data: any): Promise<Response> {
    return new Repository(addCustomTransPengirimanDetail(data), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(editMultipleTransPengirimanDetail(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteTransPengirimanDetail(id), null).getResult(false)
  }
}

const transPengirimanDetailUseCase = new TransPengirimanDetailUseCase()

export {
  transPengirimanDetailUseCase
}