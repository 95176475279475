/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import {
  addTicket, authUser, fetchAllOtUser, fetchOneOtUser
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

class OpenTicketUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    // return new Repository(fetchAllReportAuthenGroup(`?$filter=DynamicReportId eq ${filter.id} and Aktif eq true&$expand=Group($select=id, groupuser)&$select=Id, DynamicReportId`), null).getResult(false)
    throw new Error('Method not implemented.')
  }

  getAllOtUser(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    } return new Repository(fetchAllOtUser(params), null).getResult(false)
  }

  getOneOtUser(id: number): Promise<Response> {
    return new Repository(fetchOneOtUser(id), null).getResult(false)
  }

  getOne(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(data: any): Promise<Response> {
    return new Repository(addTicket(data), null).getResult(false)
  }

  authUser(email: string, password: string): Promise<Response> {
    return new Repository(authUser({ email, password }), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const openTicketUseCase = new OpenTicketUseCase()

export {
  openTicketUseCase
}
