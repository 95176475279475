import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'TransPengirimanDetail'

const fetchAllTransPengirimanDetail = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addCustomTransPengirimanDetail = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editMultipleTransPengirimanDetail = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/Settlement`, data)
const deleteTransPengirimanDetail = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)

export {
  fetchAllTransPengirimanDetail,
  addCustomTransPengirimanDetail,
  editMultipleTransPengirimanDetail,
  deleteTransPengirimanDetail
}
