import {
  ref, reactive, getCurrentInstance, computed, watch, onMounted
} from 'vue'
import {
  debounceTime, startWith, filter, skip
} from 'rxjs/operators'
import { refObs } from '@/composables/observables'

export default {
  props: {
    event: {
      required: true
    },
    search: {
      default: () => ''
    },
    withDetail: {
      default: true
    }
  },
  emits: ['sendDetail'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const search = computed(() => propertiesProps.search)
    const event = computed(() => propertiesProps.event)
    const serachObs = refObs(search)
    const data = ref([]) as any
    const placeMap = ref()
    const stylesOverlay = ref({
      top: '',
      bottom: '',
      width: '450px'
    })
    const options = {
      componentRestrictions: { country: 'id' },
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: ['establishment'],
    } as google.maps.places.AutocompleteOptions
    const service = new google.maps.places.AutocompleteService()
    let serviceDetials = null as any
    const op = ref()
    const listAuto = ref()
    const opOffsetHeight = ref(200)
    const tempResultId = ref(null) as any
    const clientEvent = ref({}) as any

    const sendDetail = async (result: any) => {
      await emit('sendDetail', result.formatted_address)
      tempResultId.value = result.place_id
    }

    const clickPlace = (placeId: any) => {
      console.log('click Place', placeId)
      serviceDetials.getDetails({
        placeId,
        componentRestrictions: {
          country: 'id'
        }
      }, (result: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          sendDetail(result)
        }
      })
    }

    const initDetails = () => {
      serviceDetials = new google.maps.places.PlacesService(placeMap.value)
    }

    const setupPositionOverlay = () => {
      const testId = document.getElementById('list-autocomplete') as any
      const height = window.innerHeight
      if (testId?.offsetHeight > 0) {
        opOffsetHeight.value = testId?.offsetHeight
      }
      if (clientEvent.value.top > 250) {
        stylesOverlay.value.top = 'unset'
        stylesOverlay.value.bottom = `${height - clientEvent.value.y - document.documentElement.scrollTop + 20}px !important`
      } else {
        stylesOverlay.value.bottom = 'unset'
        stylesOverlay.value.top = `${clientEvent.value.y + clientEvent.value.height + document.documentElement.scrollTop}px !important`
      }
    }

    const setupClientRect = () => {
      if (typeof event.value === 'object') {
        clientEvent.value = event.value.$el.getBoundingClientRect()
      } else {
        clientEvent.value = event.value.getBoundingClientRect()
      }
    }

    const getAutocomplete = async (value: any) => {
      service.getPlacePredictions({
        input: value,
        componentRestrictions: {
          country: 'id'
        }
      }, (predictions: any[] | null,
        status: any) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
          data.value = []
          console.log('status', status)
        } else {
          console.log('predictions', predictions)
          data.value = predictions
          setupPositionOverlay()
        }
      })
    }

    onMounted(() => {
      initDetails()
    })

    serachObs.pipe(
      startWith((val: any) => val.length > 2),
      filter((val) => val.length > 2 || val === ''),
      debounceTime(500),
      skip(1)
    ).subscribe((val: any) => {
      if (val) {
        if (tempResultId.value) {
          op.value.hide()
          tempResultId.value = null
        } else {
          op.value.show({
            currentTarget: (typeof event.value === 'object') ? event.value.$el : event.value
          })
          setupClientRect()
          getAutocomplete(val)
        }
      } else {
        data.value = []
      }
    })
    return {
      search,
      data,
      op,
      placeMap,
      event,
      stylesOverlay,
      listAuto,
      clickPlace
    }
  }
}