// import { Remote, remoteEnum } from '@/data/source/remote/remoteCourier'
import { Remote, remoteEnum } from '@/data/source/remote/remoteUpload'

const baseUrl = 'media/upload'

const uploadSingle = (data: any = null) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const uploadCustomPath = (namePath: any = '', data: any = null) => new Remote(remoteEnum.post, `${baseUrl}/custompath?path=${namePath}`, data)

export {
  uploadSingle,
  uploadCustomPath
}
