import { idb } from './idb'

const checkStore = async (storeName: any) => {
  try {
    const db = await idb()
    const tx = db.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)
    return new Promise((resolve) => resolve(store.get(storeName)))
  } catch (err) {
    return new Promise((reject) => reject(err))
  }
}

const saveStore = async (storeName: string, data: any) => {
  try {
    const db = await idb()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(data)
    store.put(data, storeName)
  } catch (error: any) {
    console.log('error', error)
  }
}

const addStore = async (storeName: string, data: any) => (await idb()).add(storeName, data)

const putStore = async (storeName: string, key: any, data: any) => (await idb()).put(storeName, data, key)

const deleteStore = async (storeName: string, key: any) => (await idb()).delete(storeName, key)

const clearStore = async (storeName: string) => (await idb()).clear(storeName)

export {
  checkStore,
  saveStore,
  addStore,
  putStore,
  deleteStore,
  clearStore
}
