import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TPayloadSaveMsKategoriBarang } from './msKategoriBarang-model'

const baseUrl = '/backend/v2/goodcategories'

const fetchAllKategoriBarangV2 = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneKategoriBarangV2 = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addKategoriBarangV2 = (data: TPayloadSaveMsKategoriBarang) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editKategoriBarangV2 = (id: number, data: TPayloadSaveMsKategoriBarang) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteKategoriBarangV2 = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const editPartialKategoriBarangV2 = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)

export {
  fetchAllKategoriBarangV2,
  fetchOneKategoriBarangV2,
  addKategoriBarangV2,
  editKategoriBarangV2,
  deleteKategoriBarangV2,
  editPartialKategoriBarangV2
}
