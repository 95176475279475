/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAdjustmentCourier,
  addAdjustmentKomisi
} from '@/data/source/remote/api'

class ComissionAdjustmentUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    // return new Repository(changeIsActiveAbsensiCourier, createAbsensiKey(data), null).getResult(false)
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = {}): Promise<Response> {
    let params = ''
    if (filter) {
      params = filter.custom
    }
    return new Repository(fetchAdjustmentCourier(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(addAdjustmentKomisi(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const comissionAdjustmentUseCase = new ComissionAdjustmentUseCase()

export {
  comissionAdjustmentUseCase
}
