import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()

export default (val = '') => {
  const listRoute = []

  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  listRoute.push(
    {
      path: `${val}/paket-hangus`,
      name: 'transaksi-pengiriman-hangus',
      component: () => import('@/views/transactions/delivery-item/delivery-canceled/DeliveryCanceled.vue'),
      meta: {
        breadcrumb: [
          { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: 'transaksi-pengiriman-barang' },
          { title: 'Paket Hangus', icon: null, routeName: 'transaksi-paket-hangus' },
        ],
        group: 'PENGIRIMAN BARANG',
        permission
      }
    }
  )

  return listRoute
}
