/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import {
  fetchDeliveryDrafts,
  fetchUserProfileV2,
  addDeliveryDraft,
  cancelDeliveryDraft
} from '@/data/source/remote/api'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import store from '@/store'
import type {
  TPayloadCancelDeliveryDraft
} from '@/data/source/remote/api/backend/V2/msAgens-model'

const module = 'appActiveUser'

class DraftItemUseCase {
  getDelivery(filter: any = null): Promise<Response> {
    const idAgent = store.getters[`${module}/getAgent`]
    return new Repository(fetchDeliveryDrafts(idAgent.id, returnUrlPrams(filter)), null).getResult(false)
  }

  getProfile(username: string): Promise<Response> {
    return new Repository(fetchUserProfileV2(username), null).getResult(false)
  }

  createDeliveryDraft(reference: string): Promise<Response> {
    const agent = store.getters[`${module}/getAgent`]
    const payload = {
      Reference: reference,
      Source: 'Basket'
    }
    return new Repository(addDeliveryDraft(agent.id, payload), null).getResult(false)
  }

  cancelDeliveryDraft(deliveryId: string, payload: TPayloadCancelDeliveryDraft): Promise<Response> {
    const agent = store.getters[`${module}/getAgent`]
    return new Repository(cancelDeliveryDraft(agent.id, deliveryId, payload), null).getResult(false)
  }
}

const draftItemUseCase = new DraftItemUseCase()

export {
  draftItemUseCase
}
