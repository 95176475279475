import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsHarga'

const fetchAllHarga = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneHarga = (id: number, filter = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const addHarga = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editHarga = (id: number, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteHarga = (id: number) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveHarga = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)
const fetchPencarianHarga = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/PencarianHarga`, data)
const addDuplicateHarga = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/DuplicateHarga`, data)
const cloneHarga = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/CloneHarga`, data)
const addHargaEvent = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/event`, data)

export {
  fetchAllHarga,
  fetchOneHarga,
  addHarga,
  editHarga,
  deleteHarga,
  changeIsActiveHarga,
  fetchPencarianHarga,
  addDuplicateHarga,
  cloneHarga,
  addHargaEvent
}