import { Remote, remoteEnum } from '@/data/source/remote/remoteCompany'

const baseUrl = 'MsLevelDiskons'

const fetchAllLevelDiskon = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneLevelDiskon = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addLevelDiskon = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editLevelDiskon = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteLevelDiskon = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveLevelDiskon = (data: any) => new Remote(remoteEnum.patch, `${baseUrl}/isActive/${data.Id}`, data)

export {
  fetchAllLevelDiskon,
  fetchOneLevelDiskon,
  addLevelDiskon,
  editLevelDiskon,
  deleteLevelDiskon,
  changeIsActiveLevelDiskon
}
