import { Remote, remoteEnum } from '@/data/source/remote/remote'

const baseUrl = 'MsTipeBus'

const fetchAllTipeBus = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneTipeBus = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const addTipeBus = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editTipeBus = (id: any, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteTipeBus = (id: any) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const changeIsActiveTipeBus = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/isActive`, data)

export {
  fetchAllTipeBus,
  fetchOneTipeBus,
  addTipeBus,
  editTipeBus,
  deleteTipeBus,
  changeIsActiveTipeBus
}
