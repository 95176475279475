import PermissionEnum from '@/utils/enum/permissions'
import { checkPermission, checkPermissionObj } from '@/utils/helpers/checkPermission'

const DepositWithdrawal = () => import('@/views/master/customers/deposit-withdrawal/index.vue')

export default (val = '') => {
  const listRoute = []

  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }

  if (checkPermissionObj(permission)) {
    listRoute.push({
      path: `${val}/deposit-withdrawal`,
      name: 'master-deposit-withdrawal-company',
      component: DepositWithdrawal,
      meta: {
        group: 'PELANGGAN',
        permission
      }
    })
  }
  return listRoute
}
