/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllSatuan, fetchOneSatuan, deleteSatuan, addSatuan, editSatuan, changeIsActiveSatuan
} from '@/data/source/remote/api/master'

class ItemUnitUsecase implements ICrud {
  getAll(filter: any = null): Promise<Response> {
    const params = (filter.search) ? `?$filter=(Contains(tolower(Nama),'${filter.search}') or Contains(tolower(Kode), '${filter.search}'))` : ''
    const response = new Repository(fetchAllSatuan(params), null).getResult(false)
    return response
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneSatuan(id), null).getResult(false)
      return {
        title: 'Edit Satuan Barang',
        data: response
      }
    }

    return {
      title: 'Tambah Satuan Barang',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      return new Repository(editSatuan(id, data), null).getResult(false)
    }

    data = {
      Kode: data.Kode,
      Nama: data.Nama
    }
    return new Repository(addSatuan(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteSatuan(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveSatuan(data), null).getResult(false)
  }
}

const itemUnitUseCase = new ItemUnitUsecase()

export {
  itemUnitUseCase
}
