/* eslint-disable import/no-unresolved */
import moment from 'moment'
import { MutationTree } from 'vuex'
import { IResultSendCallback } from '@/data/source/remote/api/auth/oauth-model'
import { RootState } from './types'

const mutations: MutationTree<RootState> = {
  SET_IS_AUTHEN: (state, isAuthen: boolean) => {
    state.authenticated.isAuthen = isAuthen
  },
  SET_DATE_AUTHEN: (state, dateAuthen: string) => {
    state.authenticated.dateAuthen = dateAuthen
  },
  SET_APP_OPENTICKET_ACCOUNT: (state, payload: any) => {
    if (!(state.openTicket.email === payload.email)) {
      state.openTicket.isAuthenticated = false
    }
    state.openTicket.host = payload.host
    state.openTicket.email = payload.email
    state.openTicket.password = payload.password
  },
  SET_APP_OPENTICKET_AUTH: (state, payload: any) => {
    state.openTicket.isAuthenticated = payload.isAuthenticated
    state.openTicket.token = payload.token
    state.openTicket.expiredAt = payload.expiredAt
    state.openTicket.isExpired = payload.isExpired
  },
  SET_APP_OPENTICKET_AUTH_DEFAULT: (state) => {
    state.openTicket.isAuthenticated = false
    state.openTicket.token = ''
    state.openTicket.expiredAt = null
    state.openTicket.isExpired = true
  },
  SET_CONNECTION: (state, connection: boolean) => {
    state.network.connection = connection
  },
  SET_CONNECTION_TYPE: (state, connectionType: string) => {
    state.network.connectionType = connectionType
  },
  SET_NO_INTERNET_MODAL: (state, noInternet: boolean) => {
    state.network.noInternetModal = noInternet
  },
  SET_PLATFORM: (state, platform: string) => {
    state.device.platform = platform
  },
  SET_IS_WEB: (state, web: boolean) => {
    state.device.isWeb = web
  },
  SET_IS_MOBILE: (state, mobile: boolean) => {
    state.device.isMobile = mobile
  },
  SET_IS_ANDROID: (state, android: boolean) => {
    state.device.isAndroid = android
  },
  SET_IS_IOS: (state, ios: boolean) => {
    state.device.isIos = ios
  },
  SET_WIDTH: (state, width: number) => {
    state.device.width = width
  },
  SET_HEIGHT: (state, height: number) => {
    state.device.height = height
  },
  SET_DEFAULT_DEVICE: (state) => {
    state.device.platform = 'web'
    state.device.isIos = false
    state.device.isAndroid = false
    state.device.isWeb = true
    state.device.isMobile = false
    state.device.width = 0
    state.device.height = 0
  },
  SET_SHOW_LOADER: (state, show: boolean) => {
    state.loading.show = show
  },

  SET_TOGGLE_MENU: (state, toggle: boolean) => {
    // console.log('toohl', toggle)
    state.toggleMenu = toggle
  },
  SET_SWITCH_MENU: (state, toggle: number) => {
    state.switchMenu = toggle
  },
  SET_DATA_TOKEN: (state, payload: IResultSendCallback) => {
    // console.log('SET_DATA_TOKEN', payload)
    state.token.accessToken = payload.access_token
    state.token.expiresIn = payload.expires_in
    state.token.idToken = payload.id_token
    state.token.refreshToken = payload.refresh_token
    state.token.scope = payload.scope
    state.token.tokenType = payload.token_type
    state.token.dateToken = moment().format()
  },
  SET_DATA_TOKEN_DEFAULT: (state) => {
    // console.log('SET_DATA_TOKEN', payload)
    state.token.accessToken = ''
    state.token.expiresIn = 0
    state.token.idToken = ''
    state.token.refreshToken = ''
    state.token.scope = ''
    state.token.tokenType = ''
    state.token.dateToken = ''
  },
  SET_SHOW_ADVERTISE: (state, isShow: boolean) => {
    state.showAdvertise = isShow
  }
}

export default mutations
