/* eslint-disable import/no-unresolved */
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import moduleDashboardAction from './actions'
import moduleDashboardGetter from './getters'
import moduleDashboardMutation from './mutations'
import moduleDashboardState from './state'
import { DashboardState } from './types'

const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state: moduleDashboardState,
  mutations: moduleDashboardMutation,
  actions: moduleDashboardAction,
  getters: moduleDashboardGetter
}

export default dashboard
