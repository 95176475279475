import { environment } from '@/utils'
import axios from 'axios'
import setupResponse from './setupInterceptors'

const API_URL = `${environment.getApiBase()}`
const axl = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: '*/*',
  },
})

axl.interceptors.request.use((config: any) => config, (error: any) => Promise.reject(error))

setupResponse(axl)

export default axl
