/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllChekingResi,
  fetchOneChekingResi,
  addChekingResi,
  editChekingResi,
  deleteChekingResi
} from '@/data/source/remote/api'

class CheckingResiUseCase implements ICrud {
  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getAll(filter: any = null): Promise<Response> {
    // console.log('ini filter', filter)
    let params = '$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(KodeJadwal), '${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    }
    // console.log(query)
    return new Repository(fetchAllChekingResi(params), null).getResult(false)
  }

  getSpesificData(id: any): Promise<Response> {
    return new Repository(fetchOneChekingResi(id), null).getResult(false)
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  // getAllVwPenutupanKasir(filter: any = null): Promise<Response> {
  //   // console.log('ini filter', filter)
  //   let params = '?$count=true'
  //   if (filter) {
  //     if (filter.expand) {
  //       params = `${params}${filter.expand}`
  //     }
  //     if (filter.select) {
  //       params = `${params}${filter.select}`
  //     }

  //     const arrayFilter = []
  //     if (filter.search) {
  //       arrayFilter.push(`(Contains(tolower(KodeJadwal), '${filter.search}'))`)
  //     }
  //     if (filter.custom) {
  //       arrayFilter.push(filter.custom)
  //     }
  //     if (filter.filter) {
  //       arrayFilter.push(filter.filter)
  //     }
  //     if (arrayFilter.length > 0) {
  //       params = `${params}&$filter=${arrayFilter.join(' and ')}`
  //     }
  //     params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
  //     params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
  //   }
  //   // console.log(query)
  //   return new Repository(fetchAllVwPenutupanKasir(params), null).getResult(false)
  // }
}

const checkingResiUseCase = new CheckingResiUseCase()

export {
  checkingResiUseCase
}
