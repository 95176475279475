import { Remote, remoteEnum } from '@/data/source/remote/remoteVoucherV2'

const baseUrl = 'TransVoucherClaims'

const fetchHistoryRedeemVoucher = (filter: any) => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
// const claimVoucher = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

export {
  fetchHistoryRedeemVoucher
  // claimVoucher
}
