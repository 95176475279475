/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import Repository from '@/data/repository'
import {
  fetchAllDataPayoutRefund,
  confirmPayoutRefund,
  approvePayoutRefund
} from '@/data/source/remote/api'

class RefundUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    // console.log('filter', filter)
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.date) {
        arrFilter.push(filter.date)
      }
      if (filter.search) {
        // console.log('search', filter.search)
        arrFilter.push(`((Contains(tolower(TransPengirimanHd/Resi), '${filter.search.toLowerCase()}')) or (Contains(tolower(TransPengirimanHd/NamaPengirim), '${filter.search.toLowerCase()}')))`)
      }
      if (arrFilter.length > 0) {
        // console.log('arrFilter', arrFilter)
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      // console.log('params', params)
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.orderBy) ? `${params}&$orderBy=${filter.orderBy}` : params
    }
    return new Repository(fetchAllDataPayoutRefund(params), null).getResult(false)
  }

  getDataForm(id: any, filter: any = ''): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  confirmTransPayoutRefund(data: any): Promise<Response> {
    return new Repository(confirmPayoutRefund(data), null).getResult(false)
  }

  approveTransPayoutRefund(data: any): Promise<Response> {
    return new Repository(approvePayoutRefund(data), null).getResult(false)
  }
}

const refundUseCase = new RefundUseCase()

export {
  refundUseCase
}
