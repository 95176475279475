
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch
} from 'vue'
import {
  deliveryPendingUseCase,
  receiveItemUseCase,
  receiptCheckingUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import moment from 'moment'
import {
  useRouter
} from 'vue-router'
import {
  isEmpty
} from 'lodash'

export default {
  name: 'DialogDetailResi',
  props: {
    isShowDialog: {
      default: false
    },
    isRefund: {
      default: false
    },
    idTrans: {
      default: null
    },
    idJadwal: {
      default: null
    },
    module: {
      type: String,
      default: () => null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const isFromGuid = ref(false)
    const propertiesProps = reactive(props) as any
    const idTrans = computed(() => props.idTrans) as any
    const hidedialogDetailResi = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const dialogDetailResi = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val: any) => {
        // console.log('val =>', val)
        if (!val) {
          hidedialogDetailResi()
        }
      }
    })
    const onRefund = computed(() => propertiesProps.isRefund)
    const data = ref({}) as any
    const isValidData = computed(() => !isEmpty(data.value))
    const isLoading = ref(true)
    const submitted = ref(false)
    const resMessage = ref('')
    const dataForm = reactive({
      description: ''
    })
    const rules = {
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }
    const dataAnsuransi = ref({
      hasAnsuransi: false,
      nama: null,
      noAsuransi: null
    })
    const generateDataAsuransi = () => {
      if (data.value) {
        data.value.dataAnsuransi = dataAnsuransi
        if (data.value.TransPengirimanDetails.length > 0) {
          if (data.value.TransPengirimanDetails[0].Asuransi) {
            dataAnsuransi.value.hasAnsuransi = true
            dataAnsuransi.value.nama = data.value.TransPengirimanDetails[0].Asuransi.Nama
          }
        }
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const prosesSendScanResi = async () => {
      store.dispatch('showLoading')
      const sendData = {
        Guid: data.value.Guid,
        TransJadwalHdId: propertiesProps.idJadwal
      }
      const response = await receiptCheckingUseCase.sendScanResi(sendData)
      // console.log('response =>', response)
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          summary: `${response.result.title ?? response.result.Title}`,
          detail: `${response.result.detail ?? response.result.Detail} ${response.result.Note}`,
          group: 'bc',
          life: 3000
        })
        emit('hideDialog', response.result.Data)
        emit('reloadData')
      }
    }

    const submitScanResi = () => {
      prosesSendScanResi()
    }

    const filterTransPengirimanHd = {
      expand: 'StatusPengiriman,TransJadwalHd,AgenAsal($expand=Kota($select=Id,Nama,Kode);$select=Id,Nama,KotaId), AgenTujuan($expand=Kota($select=Id,Nama,Kode);$select=Id,Nama,KotaId), TransStockLabels($select=Id,KodeLabel;$expand=Label($select=Warna)), TransPengirimanDetails($select=Id,KategoriBarangId,AsuransiId,Volume,BiayaTambahan,TotalHarga,DiskonId,DiskonValue,Berat,AsuransiValue,Harga;$expand=KategoriBarang($select=Id,Kode,Nama),Asuransi;),TransPengirimanHdBiayaTambahans($expand=MsBiayaTambahan),JenisPembayaran'
    }

    const dataDetail = async () => {
      isLoading.value = true
      const response = await receiveItemUseCase.getOne(idTrans.value, filterTransPengirimanHd)
      // const response = await deliveryPendingUseCase.getDataForm(propertiesProps.idTrans, filterTransPengirimanHd)
      if (response.code === 200) {
        isLoading.value = false
        if (idTrans.value.length === 36) {
          isFromGuid.value = true
          data.value = await response.result[0]
        } else {
          data.value = await response.result
        }
        generateDataAsuransi()
      } else {
        resMessage.value = ''
        isLoading.value = false
      }
      store.dispatch('hideLoading')
    }

    const storeRefund = async () => {
      store.dispatch('showLoading')
      const response = await deliveryPendingUseCase.refundPengiriman(idTrans.value, {
        'Keterangan Refund': dataForm.description
      })
      store.dispatch('hideLoading')
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        hidedialogDetailResi()
        emit('reloadData')
      }
    }
    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      storeRefund()
    }
    const openManifestJadwal = (isFormValid: boolean) => {
      router.push({
        name: 'transaksi-manifest-pengiriman-detail',
        params: {
          id: data.value.TransJadwalHd.Id
        }
      })
    }

    const onProcessTrans = () => {
      const typeDelivery: string = data.value.TipePengiriman
      const id = data.value.Id
      let type = '' as string
      switch (typeDelivery) {
        case 'AD':
          type = 'agent-to-door'
          break
        case 'AA':
          type = 'agent-to-agent'
          break
        case 'DA':
          type = 'door-to-agent'
          break
        case 'DD':
          type = 'door-to-door'
          break
        default:
          console.log('type not found')
      }

      router.push({
        name: 'transaksi-pengiriman-barang-edit',
        params: {
          type,
          id
        }
      })
    }

    onMounted(() => {
      if (idTrans.value) {
        store.dispatch('showLoading')
        dataDetail()
      }
    })

    return {
      hidedialogDetailResi,
      storeRefund,
      onRefund,
      dialogDetailResi,
      data,
      store,
      app,
      submitData,
      dataForm,
      v$,
      moment,
      submitted,
      dataAnsuransi,
      openManifestJadwal,
      isFromGuid,
      submitScanResi,
      isValidData,
      resMessage,
      onProcessTrans,
      isLoading
    }
  }
}
