import {
  reactive,
  computed
} from 'vue'
import ReceiveItemForm from '@/views/transactions/receive-item/detail/ReceiveItemForm.vue'

export default {
  components: {
    ReceiveItemForm
  },
  props: {
    isShowDialog: {
      default: false
    },
    id: {
      default: null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const propertiesProps = reactive(props)
    const dialogForm = computed(() => propertiesProps.isShowDialog) as any
    const id = computed(() => propertiesProps.id) as any

    const hideForm = () => {
      emit('hideDialog')
    }

    const submitSuccess = () => {
      emit('hideDialog')
      emit('reloadData')
    }

    return {
      id,
      dialogForm,
      submitSuccess,
      hideForm
    }
  }
}