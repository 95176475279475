import voucherActions from './voucherActions'
import voucherGetters from './voucherGetters'
import voucherMutations from './voucherMutations'
import voucherState from './voucherState'

export default {
  namespaced: true,
  state: voucherState,
  mutations: voucherMutations,
  actions: voucherActions,
  getters: voucherGetters
}
