import { Remote, remoteEnum } from '@/data/source/remote/remoteAuth'

const baseUrl = 'oauth2'

const sendCallback = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/token`, data, {
  'Content-Type': 'application/x-www-form-urlencoded'
})
const sendLogout = (data: any) => new Remote(remoteEnum.get, `${baseUrl}/sessions/logout?id_token_hint=${data}`)

export {
  sendCallback,
  sendLogout
}
