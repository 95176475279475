/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllLevelDiskon, fetchOneLevelDiskon, addLevelDiskon, editLevelDiskon, deleteLevelDiskon, changeIsActiveLevelDiskon
} from '@/data/source/remote/api'

class DiscountCompanyUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveLevelDiskon(data), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Keterangan),'${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllLevelDiskon(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== '') {
      const response = await new Repository(fetchOneLevelDiskon(id), null).getResult(false)
      return {
        title: 'Edit Diskon Company',
        data: response
      }
    }

    return {
      title: 'Tambah Diskon Company',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id
      data.Keterangan = 'Company'
      return new Repository(editLevelDiskon(id, data), null).getResult(false)
    }

    data = {
      Attribute1: data.Attribute1,
      Diskon: data.Diskon,
      Kelas: data.Kelas,
      MinTransaksi: data.MinTransaksi,
      IsActive: data.IsActive,
      Keterangan: 'Company'
    }
    console.log('datause', data)

    return new Repository(addLevelDiskon(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteLevelDiskon(id), null).getResult(false)
  }
}

const discountCompanyUseCase = new DiscountCompanyUseCase()

export {
  discountCompanyUseCase
}
