import iconDependency from '@/dependencies/iconDependency'
import { checkPermission } from '@/utils/helpers/checkPermission'

const icon = iconDependency()
export default (val = '') => {
  const listRoute = []
  const permission = {
    // read: checkPermission(),
    // write: checkPermission(),
    // update: checkPermission(),
    // delete: checkPermission()
    read: true,
    write: true,
    update: true,
    delete: true
  }
  listRoute.push({
    path: `${val}/pengiriman-pending`,
    name: 'transaksi-pengiriman-pending',
    component: () => import('@/views/transactions/delivery-item/delivery-pending/DeliveryPending.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      permission
    }
  })
  listRoute.push({
    path: `${val}/pengiriman-pending/:id`,
    name: 'transaksi-pengiriman-pending-detail',
    component: () => import('@/views/transactions/delivery-item/delivery-pending/detail/DeliveryPendingDetail.vue'),
    meta: {
      group: 'PENGIRIMAN BARANG',
      breadcrumb: [
        { title: 'Pengiriman Barang', icon: icon.deliveryParcelCarbon, routeName: 'transaksi-pengiriman-pending' },
        { title: 'Detail Pending', icon: null, routeName: 'transaksi-pengiriman-pending-detail' },
      ],
      permission
    }
  })

  return listRoute
}
