import { Remote, remoteEnum } from '@/data/source/remote/remoteAuth'

const baseUrl = 'password'

const changePassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Change`, data)
const forgotPassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Forgot`, data)
const resetPassword = (data: any) => new Remote(remoteEnum.post, `${baseUrl}/Reset`, data)

export {
  changePassword,
  forgotPassword,
  resetPassword
}
