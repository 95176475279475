import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import {
  authUseCase
} from '@/domain/usecase'
import { Plugins } from '@capacitor/core'
import { useRouter } from 'vue-router'
import {
  environment
} from '@/utils'
import { useFavicon } from '@vueuse/core'

const { App, Browser } = Plugins

export default {
  setup() {
    const params = ref('hello here is testing params')
    const store = useStore()
    const router = useRouter()
    const updateDataToken = async () => {
      const now = moment()
      if (store.state.token.refreshToken) {
        const checkTimes = moment.duration(now.diff(store.state.token.dateToken)).asHours()
        if (checkTimes <= 1) {
          console.log('any check times', checkTimes)
          const data = new URLSearchParams() as any
          data.append('client_id', environment.getHydraClientId())
          data.append('client_secret', environment.getHydraClientSecret())
          data.append('redirect_uri', `${window.location.origin}/callback`)
          data.append('grant_type', 'refresh_token')
          data.append('refresh_token', store.state.token.refreshToken)
          const response = await authUseCase.sendingAuth(data)
          console.log('ini response', response)
        }
      }
      // store.dispatch('updateDataToken', store.state.token.refreshToken)
    }

    const checkOpenUrl = async () => {
      let ret = await App.canOpenUrl({ url: 'id.co.hjex.app' }) as any
      console.log('Open url response: ', ret)
      ret = await App.openUrl({
        url: 'id.co.hjex.app://loginV2?login_challenge=029135f5bf8c41e3a1057bbe825e714e',
      })
      console.log('Open url response: ', ret)
      ret = await App.getLaunchUrl()
      if (ret && ret.url) {
        console.log(`App opened with URL: ${ret.url}`)
      }
      console.log('Launch url: ', ret)
      // await Browser.open({ url: 'http://capacitorjs.com/' })
    }

    onMounted(() => {
      store.dispatch('setWidth')
      store.dispatch('initNetwork')
      store.dispatch('initDevice')
      store.dispatch('watchConnection')
      store.dispatch('initResize')
      // checkOpenUrl()
      // updateDataToken()
    })

    useFavicon('favicon.png', {
      baseUrl: '/',
      rel: 'icon',
    })

    onUnmounted(() => {
      store.dispatch('destroyResize')
    })
    return {
      params
    }
  }
}
