/* eslint-disable import/order */
import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import definePlugin from './plugins/plugins'
import moment from 'moment'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'animate.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

import '@/assets/styles/css/tailwind.css'
import '@/assets/styles/scss/main.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-select/dist/vue-select.css'

const app = createApp(App)
moment.locale('id')
app.config.performance = true
app.use(store)
app.use(router)
app.use(PrimeVue, { ripple: true })
definePlugin(app)
app.mount('#app')
