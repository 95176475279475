import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/agents'

const fetchAllAgent = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchSingleAgentByGuid = (guid: string) => new Remote(remoteEnum.post, `${baseUrl}/${guid}`)

export {
  fetchAllAgent,
  fetchSingleAgentByGuid
}
