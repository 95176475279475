import { App } from 'vue'
import moment from 'moment'
import deliveryType from '@/data/source/local/json/deliveryType.json'
import {
  environment
} from '@/utils'

export default {
  install: (app: App<Element>) => {
    const vue = app
    vue.config.globalProperties.$strDate = (date: any) => {
      const now = moment().format('YYYY-MM-DD')
      const dateFormat = moment(date).format('YYYY-MM-DD')
      const diff = moment(dateFormat).diff(moment(now), 'days')
      let result = ''
      if (diff === 2) {
        result = `LUSA, ${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      } else if (diff === 1) {
        result = `BESOK, ${moment(date).format('HH:mm')} WIB`
      } else if (diff === 0) {
        result = `HARI INI, ${moment(date).format('HH:mm')} WIB`
      } else if (diff > 0) {
        result = `${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      } else {
        result = `TERLEWATKAN, ${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      }
      return result
    }
    vue.config.globalProperties.$getDiffDate = (date: any, calc: any) => {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      const dateFormat = moment(date).add(calc, 'hours').format('YYYY-MM-DD HH:mm:ss')
      const diff = moment(now).diff(moment(dateFormat), 'minute')
      return diff / 60
    }
    vue.config.globalProperties.$isDateOnTime = (date: any) => {
      const now = moment().format('YYYY-MM-DD')
      const dateFormat = moment(date).format('YYYY-MM-DD')
      const diff = moment(dateFormat).diff(moment(now), 'days')
      if (diff < 0) {
        return false
      }
      return true
    }
    vue.config.globalProperties.$deliveryTypeByCode = (code: any) => {
      const arr = deliveryType.filter((item: any) => item.Id.toLowerCase() === code.toLowerCase())
      if (arr.length > 0) {
        return arr[0].Nama
      }
      return ''
    }
    vue.config.globalProperties.$detectBackgroundLabel = (label: any) => {
      if (label) {
        if (label.Warna) {
          return label.Warna
        }
      }
      return ''
    }
    vue.config.globalProperties.$checkIsRefund = (arr: any) => {
      // console.log('arr', arr)
      const { length } = arr.filter((fill: any) => fill.KategoriBarang.IsRefund === true)
      return length > 0
    }
    vue.config.globalProperties.$searchFilter = (arr: any, label: any, search: any) => {
      console.log('tested', search, arr)
      return arr.filter((fill: any) => {
        console.log('fill', fill[label])
        return fill[label].toLowerCase().includes(search.toLowerCase())
      })
    }
    vue.config.globalProperties.$dataURLToFile = (dataurl: any, filename: any) => {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
    vue.config.globalProperties.$dataURItoBlob = (dataURI: any) => {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1])
      } else byteString = unescape(dataURI.split(',')[1])

      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    }
    vue.config.globalProperties.$mapAll = (arr: any) => {
      const newArr = []
      for (let i = 0; i < arr.length; i++) {
        newArr.push(...arr[i])
      }

      return newArr
    }
    vue.config.globalProperties.$setAltImg = (event: any, fileName: string) => {
      event.target.src = `/img/icons/${(fileName || 'image-notfound-2.svg')}`
    }
    const localPhoneNumber = (valNumber: any) => {
      let tempValNumber: any
      if (valNumber.charAt(0) === '0') {
        tempValNumber = valNumber.slice(1)
        return `62${tempValNumber}`
      // eslint-disable-next-line no-else-return
      } else if (valNumber.charAt(0) !== '0' && valNumber.substring(0, 2) !== '62') {
        return `62${valNumber}`
      }
      return valNumber
    }
    // eslint-disable-next-line arrow-body-style
    vue.config.globalProperties.$convertPhoneNumber = (valNumber: any) => {
      return localPhoneNumber(valNumber)
    }
    vue.config.globalProperties.$statusPayment = (status: any) => {
      let result
      if (status === 'queued') {
        result = '<p class="color-blue-grey font-bold">- Belum Dibayar -</p>'
      } else if (status === 'processed') {
        result = '<div class="background-primary rounded-md"><p class="font-bold text-white px-3 py-1">Dalam Proses</p></div>'
      } else if (status === 'completed') {
        result = '<div class="background-green rounded-md"><p class="font-bold text-white px-3 py-1">Terbayar</p></div>'
      } else if (status === 'failed' || status === 'rejected') {
        result = '<div class="background-red rounded-md"><p class="font-bold text-white px-3 py-1">Gagal</p></div>'
      } else if (status === 'manual') {
        result = '<div class="background-green rounded-md"><p class="font-bold text-white px-3 py-1">Terbayar Manual</p></div>'
      }
      return result
    }
    vue.config.globalProperties.$goWhatsApps = (handpnone: any) => {
      window.open(`${environment.getLinkWa()}${localPhoneNumber(handpnone)}`)
    }
  }
}
