import claim from './claim'
import insurance from './insurance'
import claimAndPaymentAg from './claimAndPaymentAg'
import claimSubmission from './claimSubmission'

export default () => {
  const getClaim = claim('barang')
  const getInsurance = insurance('barang')
  const getClaimAndInsuranceAg = claimAndPaymentAg('barang')
  const getClaimSubmission = claimSubmission('barang')

  return [
    ...getClaim,
    ...getInsurance,
    ...getClaimAndInsuranceAg,
    ...getClaimSubmission
  ]
}
